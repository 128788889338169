import { IconEye } from '@tabler/icons-react';
import { Button, useDisclosure } from '../../../../design-system/v2';
import { ResourceRequest } from '../../../../generated/api';
import { ResourcePreviewModal } from '../../modals/ResourcePreviewModal';

interface ResourcePreviewButtonProps {
  connectorId: string;
  resourcePath: ResourceRequest[];
  isPreviewDisabled?: boolean;
}

export const ResourcePreviewButton = ({
  connectorId,
  resourcePath,
  isPreviewDisabled = false,
}: ResourcePreviewButtonProps): JSX.Element => {
  const [previewOpened, { open: openPreviewModal, close: closePreviewModal }] = useDisclosure();

  return (
    <>
      <Button
        leftIcon={<IconEye />}
        onClick={() => openPreviewModal()}
        disabled={isPreviewDisabled}
      >
        Preview
      </Button>
      <ResourcePreviewModal
        opened={previewOpened}
        onClose={closePreviewModal}
        connectorId={connectorId}
        resourcePath={resourcePath}
      />
    </>
  );
};
