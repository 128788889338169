import { mergeAttributes } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';
import { sanitizeStyleAttr } from '../text-style/extention';

export interface ParagraphOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    HTMLParagraph: {
      /**
       * Toggle a paragraph
       */
      setParagraph: () => ReturnType;
      splitBlockModified: (options?: { keepMarks?: boolean }) => ReturnType;
    };
  }
}

export const HTMLParagraph = Paragraph.extend<ParagraphOptions>({
  name: 'paragraph',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {
      'data-id': {
        default: null,
      },
      class: {
        default: null,
      },
      style: {
        default: '',
        parseHTML: element => element.getAttribute('style'),
        renderHTML: attributes => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: sanitizeStyleAttr(attributes.style),
          };
        },
      },
    };
  },

  parseHTML() {
    return [{ tag: 'p' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) =>
          commands.setNode(this.name),
    };
  },
});
