import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  ApplyTagsResponse,
  CreateTagRequest,
  CreateTagResponse,
  GetTagsResponse,
  ResourceType,
  TagMetadata,
} from '../generated/api';
import { taggingApi } from '../lib/api';
import { HTTPError } from '../lib/api/api';
import { TAG } from './queryConstants';

export const useGetWorkspaceTagsQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetTagsResponse>, AxiosError, TagMetadata[]>(
    [TAG.LIST_TAGS, workspaceId],
    () => taggingApi.fetchTagsV1(workspaceId),
    {
      select: res => res.data.tags,
    },
  );
};

export const useCreateTagMutation = () => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation<AxiosResponse<CreateTagResponse>, AxiosError<HTTPError>, CreateTagRequest>(
    (newTag: CreateTagRequest) => taggingApi.createTagV1(workspaceId, newTag),
    {
      onSettled: () => {
        // Invalidate tags list query
        queryClient.invalidateQueries([TAG.LIST_TAGS, workspaceId]);
      },
    },
  );
};

interface ApplyDatasetTagsRequest {
  tagIds: string[];
}

export const useUpdateTagsOfResourceMutation = (
  resourceId: string,
  resourceType: ResourceType,
  onSuccess: () => Promise<unknown> | void,
) => {
  const { workspaceId } = useAppMetadata();

  return useMutation<
    AxiosResponse<ApplyTagsResponse>,
    AxiosError<HTTPError>,
    ApplyDatasetTagsRequest
  >(
    ({ tagIds }: ApplyDatasetTagsRequest) =>
      taggingApi.updateTagsV1(workspaceId, {
        resourceType,
        resourceId,
        tagIds,
      }),
    {
      onSuccess,
    },
  );
};
