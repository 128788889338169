import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { PropsWithChildren, useState } from 'react';
import { darkTheme, lightTheme } from './theme';

export interface ThemeProviderProps {
  theme?: ColorScheme;
}

export const ThemeProvider = ({
  children,
  theme = 'light',
}: PropsWithChildren<ThemeProviderProps>) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(theme);
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider withNormalizeCSS theme={colorScheme === 'dark' ? darkTheme : lightTheme}>
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
