import { ControlProps, isObjectControl, RankedTester, rankWith } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { IconAdjustments } from '@tabler/icons-react';
import {
  Box,
  Center,
  Flex,
  Popover,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../../../design-system/v2';

export const ObjectComponent = ({
  label,
  description,
  required,

  enabled,
  visible,

  uischema,
  schema,
  rootSchema,
  renderers,
  cells,

  errors,
  data,
  path,

  config,
}: ControlProps) => {
  const theme = useMarkovTheme();

  if (!visible) {
    return <></>;
  }

  const uiSchemaOptions = uischema.options;
  const isPopover = uiSchemaOptions?.popover;
  const childUiSchema = uiSchemaOptions?.detail;

  const isViewOnlyForm = config.viewOnly;
  const isEditable = enabled && !isViewOnlyForm!;

  const renderContent = () => (
    <Vertical>
      <Box>
        <Flex>
          <Text variant="subTitle03">{label}</Text>
          {required && <Text color="red">&nbsp;*</Text>}
        </Flex>
        <Text span variant="small02" color="gray.7">
          {description}
        </Text>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <JsonFormsDispatch
          renderers={renderers}
          cells={cells}
          visible={visible}
          schema={schema}
          uischema={childUiSchema}
          path={path}
        />
      </Box>
    </Vertical>
  );

  if (isPopover) {
    return (
      <Popover width={436} position="right" withArrow shadow="md">
        <Popover.Target>
          <Center
            w={42}
            h={42}
            bg="gray.1"
            p="xs"
            sx={{ border: `1px solid ${theme.colors.gray[3]}` }}
          >
            <IconAdjustments />
          </Center>
        </Popover.Target>
        <Popover.Dropdown px={24} pt="xl" pb={28}>
          {renderContent()}
        </Popover.Dropdown>
      </Popover>
    );
  }

  return renderContent();
};

export const objectControlTester: RankedTester = rankWith(2, isObjectControl);

export const ObjectControl = withJsonFormsControlProps(ObjectComponent);
