import { IconPlayerPlayFilled, IconPlayerStopFilled } from '@tabler/icons-react';
import { Button, ButtonProps } from '../../../../../../../design-system/v2';
import { useAudio } from '../../../../../../../hooks/useAudio';

interface PreviewAudioButtonProps {
  srcUrl: string;
}

export const PreviewAudioButton = ({ srcUrl }: PreviewAudioButtonProps): JSX.Element => {
  const { isPlaying, play, stop } = useAudio(srcUrl);

  const styleProps = { variant: 'light', size: 'xs', px: 'sm' } as ButtonProps;

  return isPlaying ? (
    <Button {...styleProps} leftIcon={<IconPlayerStopFilled size={14} />} onClick={stop}>
      Stop
    </Button>
  ) : (
    <Button {...styleProps} leftIcon={<IconPlayerPlayFilled size={14} />} onClick={play}>
      Preview
    </Button>
  );
};
