import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { isLocal } from '../lib/env.util';

enum SentryEnvNameMapper {
  local = 'LOCAL',
  development = 'DEV',
  staging = 'STAGING',
  production = 'PROD',
}

export const initializeSentry = () => {
  if (!isLocal()) {
    const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN as string;
    const sentryTraceRate = parseFloat(import.meta.env.VITE_APP_SENTRY_TRACE_RATE as string);
    const sentryEnvName =
      SentryEnvNameMapper[import.meta.env.VITE_APP_ENV as keyof typeof SentryEnvNameMapper];
    Sentry.init({
      dsn: sentryDsn,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: sentryTraceRate,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/.*markovml\.com/],
      environment: sentryEnvName,
      release: import.meta.env.npm_package_version,
    });
  }
};

/**
 * TracedRoutes will be used to monitor performance of webpages based on routes
 */
export const TracedRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

/**
 * This is used to set the user for Sentry events. Helps easily identify the impacted customer
 */
export const setSentryUser = (userId: string, name: string, email: string) => {
  if (!userId) {
    return Sentry.setUser(null);
  }
  Sentry.setUser({ id: userId, username: name, email: email });
};

export type LogEvent = Sentry.Event;
