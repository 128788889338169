import { IconBrandAzure, IconFileText } from '@tabler/icons-react';
import {
  AmazonS3,
  GoogleDrive,
  GoogleSheet,
  IconPostgres,
  SnowflakeLogo,
} from '../../design-system';
import { Image } from '../../design-system/v2';
import { AirbyteStorageType, StorageType } from '../../generated/api';
import { capitalize } from '../../lib/ui';

export const AirbyteStorageIcons: Record<AirbyteStorageType, React.FC<{ width: number }>> = {
  [AirbyteStorageType.Hubspot]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-hubspot_sv9IAQzvOj.svg"
      width={width}
    />
  ),
  [AirbyteStorageType.Airtable]: ({ width }) => (
    <Image
      src="https://ik.imagekit.io/markovml/workflows/operators/icon-operator-airtable_VOjGCXFKDd.svg"
      width={width}
    />
  ),
};

export const isAirbyteStorageType = (connectorType: StorageType): boolean =>
  Object.values(AirbyteStorageType)
    .map(v => v.toString())
    .includes(connectorType);

export const getAllAirbyteStorageTypes = (): AirbyteStorageType[] =>
  Object.values(AirbyteStorageType);

export const getConnectorTypeDisplayName = (connectorType: StorageType): string => {
  switch (connectorType) {
    case StorageType.Postgresql:
      return 'PostgreSQL';
    case StorageType.S3:
      return 'Amazon S3';
    case StorageType.AzureBlob:
      return 'Azure Blob';
    case StorageType.MarkovLibrary:
      return 'Imported File';
    case StorageType.GoogleSheet:
      return 'Google Sheet';
    case StorageType.GoogleDrive:
      return 'Google Drive';
    default:
      return capitalize(connectorType);
  }
};

export const getConnectorTypeIcon = (connectorType: StorageType, width = 24): JSX.Element => {
  switch (connectorType) {
    case StorageType.S3:
      return <AmazonS3 width={width} fill="transparent" />;
    case StorageType.Snowflake:
      return <SnowflakeLogo width={width} />;
    case StorageType.Postgresql:
      return <IconPostgres width={width} />;
    case StorageType.AzureBlob:
      return <IconBrandAzure size={width} />;
    case StorageType.MarkovLibrary:
      return <IconFileText width={width} />;
    case StorageType.GoogleSheet:
      return <GoogleSheet height={width} />;
    case StorageType.GoogleDrive:
      return <GoogleDrive width={width} />;
    default:
      if (isAirbyteStorageType(connectorType)) {
        const AirbyteStorageIcon = (AirbyteStorageIcons as any)[connectorType.toString()];

        if (AirbyteStorageIcon) {
          return <AirbyteStorageIcon width={width} />;
        }
      }
      return <></>;
  }
};
