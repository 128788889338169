import { Box, Button, Horizontal, Skeleton, Text, Vertical } from '../../../design-system/v2';
import { useDataSourceDetailsQuery } from '../../../queries/data-sources';
import { StorageMetadataModel, storageConfigMap } from '../../data-sources/cell-renderers/config';

interface ResourceSummaryProps {
  label: string;
  description: string;
  isDataSink: boolean;
  value: string;
  onChange: (value: string) => void;
  isEditable?: boolean;
}

export const ResourceSummary = ({
  value,
  isDataSink,
  label,
  description,
  onChange,
  isEditable = true,
}: ResourceSummaryProps): JSX.Element => {
  const query = useDataSourceDetailsQuery(value);

  const { isLoading } = query;
  const connectorType = query.data?.connectorType;
  const resourceMetadata = query.data?.resourceMetadata as StorageMetadataModel;

  const name =
    connectorType && resourceMetadata
      ? isDataSink
        ? storageConfigMap[connectorType]?.getResourceLocation(resourceMetadata)
        : storageConfigMap[connectorType]?.getResourceName(resourceMetadata) ?? ''
      : '';

  return (
    <Vertical spacing={0}>
      <Text variant="subTitle02" color="gray.9">
        {label}
      </Text>
      <Text variant="small02" color="gray.7">
        {description}
      </Text>
      {isLoading ? (
        <Skeleton mt="sm" h={32} w="65%" />
      ) : (
        <Vertical spacing="xs" pt="sm">
          <Box w="fit-content" bg="gray.1" p="sm" sx={{ borderRadius: '8px' }}>
            <Text variant="subTitle03" lineClamp={1} title={name} sx={{ wordBreak: 'break-all' }}>
              {name}
            </Text>
          </Box>
          <Horizontal noWrap>
            {isEditable && (
              <Button variant="subtle" onClick={() => onChange('')} px="xs">
                Change
              </Button>
            )}
          </Horizontal>
        </Vertical>
      )}
    </Vertical>
  );
};
