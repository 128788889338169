import isEmpty from 'lodash/isEmpty';
import {
  AppIdType,
  BaseAPIFilter,
  DataCategory,
  DatasetPreviewData,
  DatasetVariableStatsVisualization,
} from '../generated/api';
import { ChatResourceTypes } from './chat-with-data/chat';

export enum INVITE {
  PENDING_USER_INVITE = 'pending-user-invite',
  GET_WORKSPACE_INVITES = 'get-workspace-invites',
}

export enum WORKSPACE {
  LIST_USER_WORKSPACE = 'list-user-workspace',
  LIST_WORKSPACE_MEMBERS = 'list-workspace-members',
  LIST_USER_WORKSPACE_WITH_MEMBER_DETAILS = 'list-user-workspace-with-member-details',
  LIST_USER_WORKSPACE_GROUPED = 'list-user-workspace-grouped',
}

export enum USER {
  GET_USER_AND_ACCOUNT_INFO = 'get-user-account-info',
}

export const notificationKeys = {
  all: ['get-user-notifications'] as const,
  list: (workspaceId: string, userId: string) =>
    [...notificationKeys.all, 'list', workspaceId, userId] as const,
};

export const datasetKeys = {
  all: ['dataset'] as const,
  lists: () => [...datasetKeys.all, 'lists'] as const,
  list: (workspaceId: string, filters = {}) =>
    [
      ...datasetKeys.lists(),
      'default',
      workspaceId,
      ...(!isEmpty(filters) ? [{ filters }] : []),
    ] as const,
  listV2: (
    workspaceId: string,
    start = 0,
    filters: BaseAPIFilter[] = [],
    sortKey = '',
    sortOrder = '',
    currentUploads: string[],
  ) =>
    [
      ...datasetKeys.lists(),
      'default',
      workspaceId,
      start,
      filters,
      sortKey,
      sortOrder,
      currentUploads.join(','),
    ] as const,
  listFilters: (key: string) => [key] as const,
  listPreview: (workspaceId: string, userId = '') =>
    [...datasetKeys.lists(), 'preview', workspaceId, userId] as const,
  detail: (workspaceId: string, datasetId: string) =>
    [...datasetKeys.all, 'detail', workspaceId, datasetId] as const,
  preview: (workspaceId: string, datasetId: string) =>
    [...datasetKeys.all, 'data-preview', workspaceId, datasetId] as const,
  features: (workspaceId: string, datasetId: string) =>
    [...datasetKeys.all, 'features', workspaceId, datasetId] as const,
  topography: (workspaceId: string, datasetId: string) =>
    [...datasetKeys.all, 'topography', workspaceId, datasetId] as const,
  // createWorkflow: (workspaceId: string, workflowId: string) =>
  //   [...datasetKeys.all, 'workflow', workspaceId, workflowId] as const,
  workflow: (workspaceId: string, workflowId: string) =>
    [...datasetKeys.all, 'workflow', workspaceId, workflowId] as const,
  snapshot: (workspaceId: string, workflowId: string, delimiter = '') =>
    [...datasetKeys.all, 'data-snapshot', workspaceId, workflowId, delimiter] as const,
  explainDatasetChartWithAI: (
    workspaceId: string,
    datasetId: string,
    chartItem: DatasetVariableStatsVisualization,
    variableName: string,
  ) => [
    ...datasetKeys.all,
    'dataset-chart-explain-with-ai',
    workspaceId,
    datasetId,
    chartItem,
    variableName,
  ],
};

// TODO: Migrate other tasklet-related query keys
export const dataAnalysisTaskletKeys = {
  all: ['data-analysis-tasklet'] as const,
  listWithStatus: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskletKeys.all, 'list-tasklets-status', workspaceId, datasetId] as const,
  listAvailable: (
    workspaceId: string,
    dataCategory?: DataCategory,
    datasetId?: string,
    isDataUnlabeled?: boolean,
  ) =>
    [
      ...dataAnalysisTaskletKeys.all,
      'list-available-tasklets',
      workspaceId,
      dataCategory,
      datasetId,
      isDataUnlabeled,
    ] as const,
  dataCategory: (workspaceId: string, features: string[], previewData: DatasetPreviewData | null) =>
    [
      ...dataAnalysisTaskletKeys.all,
      'data-category',
      workspaceId,
      {
        features,
        previewData,
      },
    ] as const,
  savedSelections: (workspaceId: string, dataCategory: DataCategory) =>
    [
      ...dataAnalysisTaskletKeys.all,
      'saved-tasklet-selections',
      workspaceId,
      dataCategory,
    ] as const,
};

export const dataAnalysisTaskKeys = {
  all: ['data-analysis-task'] as const,
  lists: () => [...dataAnalysisTaskKeys.all, 'lists'] as const,
  list: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.lists(), 'default', workspaceId, datasetId] as const,
  listTopology: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.lists(), 'topology', workspaceId, datasetId] as const,
  listNew: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.lists(), 'new', workspaceId, datasetId] as const,
  dataQualityStatus: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.all, 'data-quality-status', workspaceId, datasetId] as const,
};

export const dataQualityWidgetTaskKeys = {
  all: ['data-analysis-task'] as const,
  classQualityScore: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.all, 'class-quality-score', workspaceId, datasetId] as const,
  baselineModelStats: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.all, 'baseline-model-stats', workspaceId, datasetId] as const,
  topKFeatures: (workspaceId: string, datasetId: string) =>
    [...dataAnalysisTaskKeys.all, 'top-k-features', workspaceId, datasetId] as const,
  overlappingClassMetrics: (workspaceId: string, datasetId: string, pageNumber?: number) =>
    [
      ...dataAnalysisTaskKeys.all,
      'overlapping-class-metrics',
      workspaceId,
      datasetId,
      pageNumber,
    ] as const,
};

export const fullDataAnalysisTaskKeys = {
  all: ['full-data-analysis-task'] as const,
  listTasks: (workspaceId: string, datasetId: string) =>
    [...fullDataAnalysisTaskKeys.all, 'list-tasks', workspaceId, datasetId] as const,
  listTasklets: (workspaceId: string, datasetId: string) =>
    [...fullDataAnalysisTaskKeys.all, 'list-tasklets', workspaceId, datasetId] as const,
  listAvailableTasklets: (workspaceId: string, datasetId: string) =>
    [...fullDataAnalysisTaskKeys.all, 'list-available-tasklets', workspaceId, datasetId] as const,
  results: (workspaceId: string, datasetId: string, taskletId: string, segmentType: string) =>
    [
      ...fullDataAnalysisTaskKeys.all,
      'results',
      workspaceId,
      datasetId,
      taskletId,
      segmentType,
    ] as const,
};

export enum RUNS {
  WORKSPACE_LIST_RUNS_ALL = 'workspace-list-runs-all',
  WORKSPACE_LIST_RUNS = 'workspace-list-runs',
}

export enum DATASET_CLUSTER {
  GET_CLUSTER_CHART_DATA = 'get-cluster-chart-data',
  GET_CLUSTER_CHART_RAW_DATA = 'get-cluster-chart-raw-data',
}

export enum EVALUATION {
  GET_EVALS_SUMMARY = 'get-evaluations-summary',
  LIST_WORKSPACE_EVALS = 'list-workspace-evaluations',
  LIST_MODEL_CLASS_EVALS = 'list-model-class-evaluations',
  GET_DELETED_EVALS_SUMMARY = 'get-deleted-evaluations-summary',
  LIST_DELETED_MODEL_CLASS_EVALS = 'list-deleted-model-class-evaluations',
  GET_EVAL_DETAILS = 'get-evaluation-details',
  GET_EVAL_METRICS = 'get-evaluation-metrics',
  GET_EVAL_CUSTOM_METRICS = 'get-evaluation-custom-metrics',
  GET_EVAL_CONFUSION_MATRIX = 'get-evaluation-confusion-matrix',
  GET_EVAL_FULL_DETAILS = 'get-evaluation-full-details',
  GET_CAN_COMPARE_EVALS = 'get-can-compare-evaluations',
  LIST_EVAL_COMPARISON_DISGREEMENTS_INFINITE = 'list-evaluation-comparison-disgreements-infinite',
  GET_EVAL_CLASS_METRICS = 'get-evaluation-class-level-metrics',
  GET_EVAL_CLASS_DISTRIBUTIONS = 'get-evaluation-class-distributions',
  GET_EVAL_ROC_CURVE = 'get-evaluation-roc-curve',
  GET_EVAL_PR_CURVE = 'get-eval-pr-curve',
  ARE_EVALUATIONS_AVAILABLE = 'are-evaluations-available',
}

// TODO: Refactor remaining into key factories
export enum MODEL {
  LIST_MODEL_EVALUATIONS = 'list-model-evaluations',
}

export const modelKeys = {
  all: ['model'] as const,
  lists: () => [...modelKeys.all, 'lists'] as const,
  list: (workspaceId: string, userId: string, filters = {}) =>
    [...modelKeys.lists(), 'default', workspaceId, userId, { filters }] as const,
  listProjectModels: (workspaceId: string, userId: string, projectId: string, filters = {}) =>
    [...modelKeys.lists(), 'project-models', workspaceId, userId, projectId, { filters }] as const,
  detail: (workspaceId: string, userId: string, projectId: string, modelId: string) =>
    [...modelKeys.all, 'detail', workspaceId, userId, projectId, modelId] as const,
  driftChart: (workspaceId: string, modelId: string) =>
    [...modelKeys.all, 'drift-chart', workspaceId, modelId] as const,
};

export const modelRegistryKeys = {
  all: ['model-registry'] as const,
  list: (workspaceId: string) => [...modelRegistryKeys.all, 'list', workspaceId] as const,
  listModels: (workspaceId: string, registryId: string) =>
    [...modelRegistryKeys.list(workspaceId), registryId] as const,
  detail: (workspaceId: string, registryId: string) =>
    [...modelRegistryKeys.all, workspaceId, registryId] as const,
  listWithStages: (workspaceId: string, registryId: string) =>
    [...modelRegistryKeys.all, 'list-with-stages', workspaceId, registryId] as const,
  modelTransitionHistory: (workspaceId: string, registryId: string) =>
    [...modelRegistryKeys.all, 'transition-history', workspaceId, registryId] as const,
};

// TODO: Refactor the rest into key factories
export enum PROJECT {
  LIST_PROJECT_EVALUATIONS = 'list-project-evaluations',
}

// Project keys are user-aware (private projects aren't visible to all workspace users)
export const projectKeys = {
  all: ['project'] as const,
  lists: () => [...projectKeys.all, 'lists'] as const,
  list: (workspaceId: string, userId: string, filters = {}) =>
    [
      ...projectKeys.lists(),
      'default',
      workspaceId,
      userId,
      ...(!isEmpty(filters) ? [{ filters }] : []),
    ] as const,
  listPreview: (workspaceId: string, userId = '') =>
    [...projectKeys.lists(), 'preview', workspaceId, userId] as const,
  detail: (workspaceId: string, userId: string, projectId: string) =>
    [...projectKeys.all, 'detail', workspaceId, userId, projectId] as const,
};

export enum TAG {
  LIST_TAGS = 'list-tags',
}

export enum TOKEN {
  GET_TOKEN_FOR_WORKSPACE = 'get-token-for-workspace',
}

export enum SETTINGS {
  GET_WORKSPACE_SETTINGS = 'get-workspace-settings',
  AUTHENTICATE_SLACK = 'authenticate-slack',
  INTEGRATION_STATUS = 'integration-status',
  SLACK_CHANNEL_LIST = 'slack-channel-list',
  SLACK_SELECTED_CHANNEL = 'slack-selected-channel',
}

export enum SNIPPET {
  LIST_SNIPPETS = 'list-snippets',
  CREATE_SNIPPET = 'create-snippet',
  GET_SNIPPET = 'get-snippet',
  SAVE_SNIPPET = 'save-snippet',
  GET_SHARED_USERS = 'get-shared-users',
  ADD_SHARED_USERS = 'add-shared-users',
  REMOVE_SHARED_USERS = 'remove-shared-users',
  PUBLISH_SNIPPET = 'publish-snippet',
  UPLOAD_MEDIA = 'upload-media',
  GET_PUBLIC_SNIPPET = 'GET_PUBLIC_SNIPPET',
  GET_PUBLIC_CHART_DETAILS = 'GET_PUBLIC_CHART_DETAILS',
  UPDATE_STATE = 'state-update',
  GET_APP_METADATA_PUBLIC = 'GET_APP_METADATA_PUBLIC',
}

export enum SNIPPET_COMMENTS {
  LIST_SNIPPETS = 'list-snippet-conversations',
  ADD_NEW_COMMENT = 'add-new-comment',
  DELETE_COMMENT = 'delete-comment',
}

export const commentsKeys = {
  list: (workspaceId: string, snippetId: string) => [
    SNIPPET_COMMENTS.LIST_SNIPPETS,
    workspaceId,
    snippetId,
  ],
  add: (workspaceId: string, snippetId: string) => [
    SNIPPET_COMMENTS.ADD_NEW_COMMENT,
    workspaceId,
    snippetId,
  ],
  delete: (workspaceId: string, snippetId: string, conversationId: string, commentId: string) => [
    SNIPPET_COMMENTS.DELETE_COMMENT,
    workspaceId,
    snippetId,
    conversationId,
    commentId,
  ],
};

export enum NOTIFICATIONS {
  NOTIFICATIONS_COUNT = 'notifications-count',
}

export const mizzenAppKeys = {
  all: ['mizzen-apps'] as const,
  detail: (workspaceId: string, modelAppId = '') =>
    [...mizzenAppKeys.all, 'detail', workspaceId, modelAppId] as const,
  status: (workspaceId: string, modelAppId = '') =>
    [...mizzenAppKeys.all, 'status', workspaceId, modelAppId] as const,
  sampling: (workspaceId: string, sampleId = '') =>
    [...mizzenAppKeys.all, 'sampling', workspaceId, sampleId] as const,
};

export const modelAppsKeys = {
  all: ['model-apps'] as const,
  status: (workspaceId: string, modelId = '') =>
    [...modelKeys.all, 'status', workspaceId, modelId] as const,
  list: (workspaceId: string, filters = {}) =>
    [...modelKeys.all, 'list', workspaceId, { filters }] as const,
  listForDataView: (workspaceId: string, dataViewId: string) =>
    [...modelKeys.all, 'list-data-view', workspaceId, dataViewId] as const,
  listPreview: (workspaceId: string, userId = '') =>
    [...modelKeys.all, 'preview', workspaceId, userId] as const,
  detail: (workspaceId: string, modelId?: string, datasetId?: string) =>
    [...modelKeys.all, 'detail', workspaceId, modelId, datasetId] as const,
  baselineTaskletStatus: (workspaceId: string, datasetId: string) => [
    ...modelKeys.all,
    'baseline-tasklet',
    workspaceId,
    datasetId,
  ],
  fileStatus: (workspaceId: string, modelId: string) =>
    [...modelKeys.all, 'file-status', workspaceId, modelId] as const,
  getFileInferenceResult: (workspaceId: string, modelId: string) =>
    [...modelKeys.all, 'file-result', workspaceId, modelId] as const,
};

export const copyEditKeys = {
  all: ['copy-edit'] as const,
  analytics: (workspaceId: string, appId: AppIdType, days: number) =>
    [...copyEditKeys.all, workspaceId, appId, days] as const,
  getDocumentMetadata: (workspaceId: string, documentId: string) =>
    [...copyEditKeys.all, 'page-metadata', workspaceId, documentId] as const,
  getDocumentPageData: (workspaceId: string, documentId: string, pageIndex?: number) =>
    [
      ...copyEditKeys.all,
      'page-data',
      workspaceId,
      documentId,
      ...(pageIndex !== undefined ? [String(pageIndex)] : []),
    ] as const,
  docSuggestions: (workspaceId: string, documentId: string) =>
    [...copyEditKeys.all, 'page-suggestions', workspaceId, documentId] as const,
  getPageSuggestions: (workspaceId: string, documentId: string, pageIndex?: number) => [
    ...copyEditKeys.docSuggestions(workspaceId, documentId),
    pageIndex !== undefined ? String(pageIndex) : undefined,
  ],
  getPageSuggestionsFast: (workspaceId: string) => [
    ...copyEditKeys.all,
    'page-suggestions-fast',
    workspaceId,
  ],
  getPageAnalyseStatus: (workspaceId: string, documentId: string, pageIndex: number) =>
    [...copyEditKeys.all, 'page-analyse-status', workspaceId, documentId, pageIndex] as const,
  getPageStats: (workspaceId: string, appId: AppIdType, pageText: string) =>
    [...copyEditKeys.all, 'page-stats', appId, workspaceId, pageText] as const,
  getCustomRulesList: (workspaceId: string, ruleSetId: string) =>
    [...copyEditKeys.all, 'custom-rules-list', workspaceId, ruleSetId] as const,
  getRulesSetsList: (workspaceId: string) => [...copyEditKeys.all, 'rules-sets-list', workspaceId],
  getVersionsList: (workspaceId: string, documentId: string, pageIndex?: number) =>
    [
      ...copyEditKeys.all,
      'versions-list',
      workspaceId,
      documentId,
      ...(pageIndex !== undefined ? [String(pageIndex)] : []),
    ] as const,
  getVersionContent: (
    workspaceId: string,
    documentId: string,
    pageIndex?: number,
    versionIndex?: number,
  ) =>
    [
      ...copyEditKeys.all,
      'version-content',
      workspaceId,
      documentId,
      ...(pageIndex !== undefined ? [String(pageIndex)] : []),
      ...(versionIndex !== undefined ? [String(versionIndex)] : []),
    ] as const,
  getContentPlagiarismResult: (workspaceId: string, documentId: string, pageIndex: number) =>
    [...copyEditKeys.all, 'plagiarism-results', workspaceId, documentId, pageIndex] as const,
  getDictionaryList: (workspaceId: string) =>
    [...copyEditKeys.all, 'custom-dictionary', workspaceId] as const,
  getDictionaryWordList: (workspaceId: string, dictionaryId: string) =>
    [...copyEditKeys.all, 'custom-dictionary-words-list', workspaceId, dictionaryId] as const,
  getDocumentCitations: (workspaceId: string, documentId: string, citationIds?: string[]) =>
    [
      ...copyEditKeys.all,
      'citations',
      workspaceId,
      documentId,
      ...(citationIds ? [citationIds] : []),
    ] as const,
};

export const chatWithDataKeys = {
  threads: (workspaceId: string, resourceId: string, resourceType: ChatResourceTypes) => [
    resourceType,
    'threads',
    workspaceId,
    resourceId,
  ],
  threadConversations: (
    workspaceId: string,
    resourceId: string,
    threadId: string,
    resourceType: ChatResourceTypes,
  ) => [resourceType, 'conversations', workspaceId, resourceId, threadId],
  query: (
    workspaceId: string,
    resourceId: string,
    threadId: string,
    resourceType: ChatResourceTypes,
  ) => [resourceType, 'query', workspaceId, resourceId, threadId],
};

export const appStoreKeys = {
  all: ['app-store'] as const,
  document: (workspaceId: string, documentId: string) =>
    [...appStoreKeys.all, 'document', workspaceId, documentId] as const,
  list: (workspaceId: string, appId: string) =>
    [...appStoreKeys.all, 'list', workspaceId, appId] as const,
  status: (workspaceId: string, documentId: string) =>
    [...appStoreKeys.all, 'status', workspaceId, documentId] as const,
  details: (workspaceId: string, appId: string, documentId: string) =>
    [...appStoreKeys.all, 'details', workspaceId, appId, documentId] as const,
};

export const editorCommentsKeys = {
  all: ['comments'] as const,
  conversations: (workspaceId: string, resourceId: string) => [workspaceId, resourceId],
  conversationComments: (workspaceId: string, resourceId: string, conversationId: string) => [
    workspaceId,
    resourceId,
    conversationId,
  ],
};
