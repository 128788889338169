import { Horizontal, Text } from '../../../design-system/v2';
import { ICellRendererParams } from '../../../design-system/v2/core/data-display/table/ag-grid';
import { ResourceModel, StorageType } from '../../../generated/api';
import { storageConfigMap } from './config';

export const TypeRenderer = ({
  value: connectorType,
  data,
}: ICellRendererParams<ResourceModel, StorageType>): JSX.Element => {
  const config =
    connectorType !== undefined && connectorType in storageConfigMap
      ? storageConfigMap[connectorType]
      : undefined;

  const connectorName = data?.connectionName ?? '--';
  const icon = config?.connectorIcon ?? null;

  return (
    <Horizontal spacing="xs" h="100%">
      {icon}
      <Text variant="bodyShort03">{connectorName}</Text>
    </Horizontal>
  );
};
