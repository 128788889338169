import sortBy from 'lodash/sortBy';
import { Box, Flex, LinkAnchor, Text, Vertical } from '../../../../../../../../design-system/v2';

import { CitationStyleGuideType, CopyEditCitation } from '../../../../../../../../generated/api';
import { getCitationStyleDetails } from '../../../../../commons/citations/util';

interface ReferenceSectionProps {
  references: CopyEditCitation[];
  referenceStyle: CitationStyleGuideType;
}

export interface ListReferenceProps {
  referenceObj: CopyEditCitation;
  referenceStyle: CitationStyleGuideType;
}

export const ReferenceComponent = ({
  referenceObj,
  referenceStyle,
}: ListReferenceProps): JSX.Element => {
  const citation = referenceObj.citation;
  const { getListCitationText } = getCitationStyleDetails(referenceStyle);

  const refSummary = getListCitationText(referenceObj.citation);
  const { identifier } = citation;
  const referenceUrl = identifier.doi || identifier.url || '';

  if (refSummary) {
    return (
      <span style={{ padding: '2px 0' }}>
        <span style={{ fontFamily: 'unset', fontSize: 'unset' }}>{refSummary}</span>
        <LinkAnchor
          external
          target="_blank"
          href={referenceUrl}
          sx={{ fontFamily: 'unset', fontSize: 'unset', color: 'blue' }}
        >
          {referenceUrl}
        </LinkAnchor>
      </span>
    );
  }

  return <></>;
};

export const ReferenceSection = ({
  references,
  referenceStyle,
}: ReferenceSectionProps): JSX.Element => {
  const { getInlineCitationText } = getCitationStyleDetails(referenceStyle);
  // Note: List citation text helper now returns a JSX Element instead of a string, so using the
  // inline citation helper to sort references (beginning text should be identical).
  const sortedReferences = sortBy(references, reference =>
    getInlineCitationText(reference.citation).toLowerCase(),
  );

  return (
    <Box w={800}>
      <Flex align="center" px={40} bg="gray.0" h="60px">
        <Text variant="heading04">References</Text>
      </Flex>
      <Vertical spacing="sm" p={40} sx={{ fontSize: '14px' }}>
        {sortedReferences.map((reference: CopyEditCitation, index: number) => (
          <Box key={index}>
            <ReferenceComponent referenceObj={reference} referenceStyle={referenceStyle} />
          </Box>
        ))}
      </Vertical>
    </Box>
  );
};
