import { AnalyzingSpinner, IconNotSupported, Sparkles } from '../../../../design-system';
import { Button, Center, Loader, notifications } from '../../../../design-system/v2';
import { AnalysisStateNames } from '../../../../generated/api';
import { logger } from '../../../../initializers/logging';
import { getIsTaskPending } from '../../../../lib/analysis';
import { AnalyzerTaskletEnum, UNLABELED_DATA_TARGET } from '../../../../queries/constants';
import { useRerunAnalysisMutation } from '../../../../queries/datasets/analysis/tasks';
import {
  useBaselineModelMetricsQuery,
  useTriggerBaselineModelMutation,
} from '../../../../queries/datasets/statistics/data-quality';
import { EmptyState } from '../../../common/EmptyState';
import { ErrorState } from '../../../common/ErrorState';
import { BaselineModelStats } from './BaselineModelStats';

interface BaselineModelStatsContainerProps {
  datasetId: string;
  datasetTarget?: string;
}

export const BaselineModelStatsContainer = ({
  datasetId,
  datasetTarget,
}: BaselineModelStatsContainerProps): JSX.Element => {
  const { isLoading, isError, data } = useBaselineModelMetricsQuery(datasetId);
  const { mutate: triggerBaselineModelAnalysis, isLoading: isTriggering } =
    useTriggerBaselineModelMutation(datasetId);
  const { mutate: retriggerBaselineModelAnalysis, isLoading: isRetriggering } =
    useRerunAnalysisMutation(datasetId);

  const handleRetrigger = () => {
    retriggerBaselineModelAnalysis(AnalyzerTaskletEnum.BASELINE_MODEL, {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: 'Baseline model triggered successfully',
        });
      },
      onError: err => {
        logger.error(err);
        notifications.show({
          variant: 'error',
          message: 'Error encountered while triggering baseline model',
        });
      },
    });
  };

  const handleClick = () => {
    triggerBaselineModelAnalysis(undefined, {
      onSuccess: () => {
        notifications.show({
          variant: 'success',
          message: 'Baseline model triggered successfully',
        });
      },
      onError: err => {
        logger.error(err);
        notifications.show({
          variant: 'error',
          message: 'Error encountered while triggering baseline model',
        });
      },
    });
  };

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" size="lg" text="Loading baseline model metrics" />
      </Center>
    );
  }

  if (isError || !data) {
    const errorMsg = 'There was an error retrieving baseline model metrics';
    return (
      <ErrorState
        compact
        title=""
        subtitle={errorMsg}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  const analysisState = data.analysisState;
  const isUnlabeledDataset = datasetTarget === UNLABELED_DATA_TARGET;
  if (isUnlabeledDataset || analysisState === AnalysisStateNames.NotSupported) {
    return (
      <EmptyState
        compact
        title=""
        subtitle="Baseline model metrics analyser is not supported for this dataset"
        icon={<IconNotSupported />}
        type="notSupported"
      />
    );
  }

  if (analysisState === AnalysisStateNames.NotStarted) {
    return (
      <EmptyState
        compact
        title=""
        subtitle="Baseline model analyser has to be run to view the metrics"
        icon={<Sparkles />}
        button={
          <Button variant="outline" onClick={handleClick} loading={isTriggering}>
            Generate baseline model
          </Button>
        }
      />
    );
  }

  // Handle case when analyzer results aren't available yet
  if (getIsTaskPending(analysisState)) {
    return (
      <EmptyState
        compact
        title="Baseline model metrics analysis in progress"
        subtitle="Results will become available once the analysis is complete"
        icon={<AnalyzingSpinner />}
      />
    );
  }

  if (analysisState === AnalysisStateNames.Error || !data.response) {
    return (
      <ErrorState
        compact
        title=""
        subtitle="There was an error while computing baseline model metric results"
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  const baselineModelMetrics = data.response;

  return <BaselineModelStats baselineModelMetrics={baselineModelMetrics} />;
};
