import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import mapKeys from 'lodash/mapKeys';
import merge from 'lodash/merge';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  EvalEditableFields,
  EvaluationModelWithTags,
  GetEvaluationMetadataWithTagsResponse,
  GetProjectEvaluationsListWithTagResponse,
} from '../../generated/api';
import { logger } from '../../initializers/logging';
import { evaluationsApi, taggedResourcesApi } from '../../lib/api';
import { API_PAGE_SIZE } from '../constants';
import { EVALUATION, MODEL, PROJECT } from '../queryConstants';
import { getNextPageParamHandler } from '../util';

interface ProjectEvaluationsListQueryFilters {
  name?: undefined;
  modelId?: string;
  userIds?: string[];
  excludeUserIds?: string[];
  recordIds?: string[];
  tagIds?: string[];
}

const evaluationQueryKeys = {
  evaluationsAvailability: (workspaceId: string, evaluationIds: string[]) => [
    EVALUATION.ARE_EVALUATIONS_AVAILABLE,
    workspaceId,
    ...evaluationIds,
  ],
};

export const useProjectEvaluationsInfiniteQuery = (
  projectId: string,
  filters: ProjectEvaluationsListQueryFilters,
) => {
  const { workspaceId, userId } = useAppMetadata();

  return useInfiniteQuery<
    AxiosResponse<GetProjectEvaluationsListWithTagResponse>,
    AxiosError,
    EvaluationModelWithTags[]
  >(
    [PROJECT.LIST_PROJECT_EVALUATIONS, workspaceId, userId, projectId, { filters }],
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return taggedResourcesApi.getProjectEvaluationsListWithTagsV1(
        workspaceId,
        projectId,
        start,
        end,
        lastTimestamp,
        filters.name,
        filters.modelId,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
      );
    },
    {
      enabled: Boolean(workspaceId && projectId),
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );
};

export const useModelEvaluationsQuery = (
  projectId: string,
  filters: ProjectEvaluationsListQueryFilters,
) => {
  const { workspaceId, userId } = useAppMetadata();

  return useInfiniteQuery<
    AxiosResponse<GetProjectEvaluationsListWithTagResponse>,
    AxiosError,
    EvaluationModelWithTags[]
  >(
    [MODEL.LIST_MODEL_EVALUATIONS, workspaceId, userId, projectId, { filters }],
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return taggedResourcesApi.getProjectEvaluationsListWithTagsV1(
        workspaceId,
        projectId,
        start,
        end,
        lastTimestamp,
        filters.name,
        filters.modelId,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
      );
    },
    {
      enabled: Boolean(workspaceId && projectId),
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
      refetchInterval: 30 * 1000,
    },
  );
};

export const useEditEvaluationMutation = (
  evaluationId: string,
  projectId: string,
  modelClass: string,
) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  const evalDetailsQueryKey = [EVALUATION.GET_EVAL_DETAILS, workspaceId, evaluationId];
  const workspaceEvalsQueryKey = [EVALUATION.LIST_WORKSPACE_EVALS, workspaceId, userId];
  const modelClassEvalsQueryKey = [
    EVALUATION.LIST_MODEL_CLASS_EVALS,
    workspaceId,
    userId,
    modelClass,
  ];
  const projectEvalsQueryKey = [PROJECT.LIST_PROJECT_EVALUATIONS, workspaceId, userId, projectId];
  const modelEvalsQueryKey = [MODEL.LIST_MODEL_EVALUATIONS, workspaceId, userId, projectId];

  return useMutation(
    (updates: EvalEditableFields) =>
      evaluationsApi.updateEvaluationEditableFieldsV1(workspaceId, projectId, evaluationId, {
        updates,
      }),
    {
      onMutate: async updates => {
        // optimistically update evaluation details query data
        await queryClient.cancelQueries(evalDetailsQueryKey);

        const previousData =
          queryClient.getQueryData<AxiosResponse<GetEvaluationMetadataWithTagsResponse>>(
            evalDetailsQueryKey,
          );

        if (previousData) {
          // TODO: Remove this workaround for difference between BE schema and API contract
          const appliedUpdates = mapKeys(updates, (_v, k) => (k === 'description' ? 'notes' : k));
          const updatedEval = merge({}, previousData, {
            data: { response: { ...appliedUpdates } },
          });
          queryClient.setQueryData<AxiosResponse<GetEvaluationMetadataWithTagsResponse>>(
            evalDetailsQueryKey,
            updatedEval,
          );
        }

        return { previousData };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceEvalsQueryKey);
        queryClient.invalidateQueries(modelClassEvalsQueryKey);
        queryClient.invalidateQueries(projectEvalsQueryKey);
        queryClient.invalidateQueries(modelEvalsQueryKey);
      },
      onError: (
        err: AxiosError,
        _data,
        context:
          | { previousData?: AxiosResponse<GetEvaluationMetadataWithTagsResponse> }
          | undefined,
      ) => {
        logger.error(err);

        if (context?.previousData) {
          queryClient.setQueryData(evalDetailsQueryKey, context.previousData);
        }
      },
    },
  );
};

export const useEvaluationMetricsAvailability = (evaluationIds: string[]) => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    evaluationQueryKeys.evaluationsAvailability(workspaceId, evaluationIds),
    () => evaluationsApi.areEvaluationsROCAndPRCurveAvailableV1(workspaceId, evaluationIds),
    {
      select: data => ({
        // TODO: Have to change this logic so that `isRocAndPrCurveAvailable: true` when any response is available
        // (`some` instead of `every`) . Once BE change is done.
        isRocAndPrCurveAvailable: data.data.response.every(
          ({ isRocAndPrCurveAvailable }) => !!isRocAndPrCurveAvailable,
        ),
      }),
    },
  );
};
