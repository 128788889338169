import { IconInfoCircle } from '@tabler/icons-react';
import {
  ActionIcon,
  Card,
  Grid,
  Horizontal,
  Text,
  TextInput,
  Tooltip,
} from '../../../design-system/v2';
import { WorkspaceSettings } from '../../../generated/api';
import { CopyToClipboardTooltip } from '../../common/CopyToClipboardTooltip';
import { SettingsSection } from '../SettingsSection';
import { checkHybridDeployment } from '../settings.util';
import { AccountManagers } from './AccountManagers';
import { SubscriptionDetailsContainer } from './subscription/SubscriptionDetails.container';

interface AboutProps {
  workspaceSettings: WorkspaceSettings;
}

export const About = ({ workspaceSettings }: AboutProps): JSX.Element => {
  const { workspace, admins, deploymentInfo, account } = workspaceSettings;

  const isHybridDeployment = checkHybridDeployment(deploymentInfo);

  return (
    <SettingsSection title="About">
      <Grid mt={24} columns={12}>
        <Grid.Col xs={6}>
          <TextInput
            disabled
            label="Workspace name"
            ariaLabel="Workspace name"
            value={workspace.workspaceName}
            readOnly
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <TextInput
            disabled
            label="Workspace ID"
            ariaLabel="Workspace ID"
            value={workspace.workspaceId ?? ''}
            readOnly
            rightSection={<CopyToClipboardTooltip valueToCopy={workspace.workspaceId ?? ''} />}
          />
        </Grid.Col>
        {isHybridDeployment && (
          <Grid.Col xs={6}>
            <TextInput
              label={
                <Horizontal spacing="xs">
                  <Text variant="subTitle04">Workspace base URL</Text>
                  <Tooltip
                    multiline
                    maw={280}
                    arrowSize={6}
                    events={{ hover: true, focus: true, touch: true }}
                    position="right"
                    label="This URL is used by MarkovML to access the stack. MarkovML stack has been deployed in your cloud provider using 'Hybrid Deployment'."
                  >
                    <ActionIcon>
                      <IconInfoCircle size={16} />
                    </ActionIcon>
                  </Tooltip>
                </Horizontal>
              }
              ariaLabel="Workspace base url"
              value={workspace.baseUrl}
              readOnly
              disabled
              rightSection={<CopyToClipboardTooltip valueToCopy={workspace.baseUrl} />}
            />
          </Grid.Col>
        )}
      </Grid>
      <Grid mt={24}>
        <Grid.Col sm={10}>
          <Text variant="subTitle03" mb="lg">
            Account managers
          </Text>
          <Card withBorder shadow="xs">
            <AccountManagers
              accountDetails={account}
              workspaceMembers={admins?.workspaceMembers ?? []}
            />
          </Card>
        </Grid.Col>
      </Grid>
      <Text variant="subTitle03" mt={24} mb="lg" tt="capitalize">
        Manage Subscription
      </Text>

      <SubscriptionDetailsContainer />
    </SettingsSection>
  );
};
