import {
  ControlProps,
  JsonSchema,
  OwnPropsOfEnum,
  RankedTester,
  and,
  hasType,
  rankWith,
  resolveSchema,
  schemaMatches,
  schemaSubPathMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsMultiEnumProps } from '@jsonforms/react';
import { Checkbox, Text, Vertical } from '../../../../../design-system/v2';

const CheckboxGroupControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  schema,
  options = [],
  config,
  enabled,
  errors,
}: ControlProps & OwnPropsOfEnum) => {
  const schemaValues = options.map(obj => obj.value);

  const isViewOnlyForm = config.viewOnly;

  const onChange = (values: string[]) => {
    if (isViewOnlyForm) {
      return;
    }

    handleChange(path, values);
  };

  return (
    <Checkbox.Group
      label={
        <Text span variant="subTitle02">
          {label}
        </Text>
      }
      description={
        <Text span variant="small02" color="gray.7">
          {description}
        </Text>
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      placeholder={uischema.options?.placeholder}
      withAsterisk={required}
      value={data}
      onChange={onChange}
      error={config.isFormDirty ? errors : undefined}
    >
      <Vertical spacing="lg" pt="md">
        {options.map((enumItem, idx) => (
          <Checkbox
            key={idx}
            value={enumItem.value}
            label={enumItem.label}
            // Mantine does not forward readonly prop to Checkbox, so have to use disabled state here.
            // One improvement can be to style the checkbox here itself
            disabled={!enabled}
          />
        ))}
      </Vertical>
    </Checkbox.Group>
  );
};

const hasOneOfItems = (schema: JsonSchema): boolean =>
  schema.oneOf !== undefined &&
  schema.oneOf.length > 0 &&
  (schema.oneOf as JsonSchema[]).every((entry: JsonSchema) => entry.const !== undefined);

const hasEnumItems = (schema: JsonSchema): boolean =>
  schema.type === 'string' && schema.enum !== undefined;

export const checkboxGroupControlTester: RankedTester = rankWith(
  5,
  and(
    uiTypeIs('Control'),
    and(
      schemaMatches(
        schema =>
          hasType(schema, 'array') && !Array.isArray(schema.items) && schema.uniqueItems === true,
      ),
      schemaSubPathMatches('items', (schema, rootSchema) => {
        const resolvedSchema = schema.$ref
          ? resolveSchema(rootSchema, schema.$ref, rootSchema)
          : schema;
        return hasOneOfItems(resolvedSchema) || hasEnumItems(resolvedSchema);
      }),
    ),
  ),
);

export const CheckboxGroupControl = withJsonFormsMultiEnumProps(CheckboxGroupControlComponent);
