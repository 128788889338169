import { useQuery } from '@tanstack/react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { connectorsApi } from '../../lib/api';

export const useGetS3PresignedUrlForFileQuery = (workflowId: string, filePath: string) => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    ['get-presigned-url', workspaceId, workflowId, filePath],
    () =>
      connectorsApi.getResourceFilePreviewV1(workspaceId, workflowId, filePath, {
        responseType: 'blob',
      }),
    {
      select: data => data.data,
    },
  );
};
