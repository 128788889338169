import { MarkovReferenceItem, ReferenceText, Text } from '../../../../../design-system/v2';
import {
  AuthorInformationModel,
  Citation,
  CitationStyleGuideType,
  CitationType,
  CopyEditCitation,
  JournalInformationModel,
} from '../../../../../generated/api';
import { interleaveComponent } from '../../../../../lib/util-react';
import { getCitationTitle, getJournalName } from './cmos';
import {
  MAX_DISPLAYED_AUTHORS_APA_INLINE,
  MAX_DISPLAYED_AUTHORS_APA_LIST,
  MISSING_DATE_TEXT,
} from './constants';
import {
  getAccessedDate,
  getFormattedPublishedDate,
  getMultipleInlineAuthors,
  getMultipleListAuthorsText,
  getResourceName,
  getSingleInlineAuthor,
  getSingleListAuthorText,
  getWebsiteName,
} from './util';

export const getInlineCitationComponentAPA = (
  citations: CopyEditCitation[],
  highlightType: number,
): JSX.Element => {
  const delimiter = '; ';

  const citationJSX = citations.map(citation => (
    <MarkovReferenceItem
      key={citation.citationId}
      citation={citation}
      citationStyles={CitationStyleGuideType.Apa}
      highlightType={highlightType}
    />
  ));

  return (
    <span>
      {citations &&
        interleaveComponent(citationJSX, () => <ReferenceText value={delimiter ?? ' '} />)}
    </span>
  );
};

// If authors.length === 1: INTEXT: Author1.lastName | LIST: Author1.firstName Author1.lastName
// If authors.length === 2: INTEXT: Author1.lastName and Author2.lastName | LIST: Author1.firstName Author1.lastName and Author2.firstName Author2.lastName
// If authors.length === 3: INTEXT: Author1.lastName, Author2.lastName and Author3.lastName | LIST: Author1.firstName Author1.lastName, Author2.firstName Author2.lastName and Author3.firstName Author3.lastName
// If authors.length > 3 : INTEXT: Author1.lastName et al. | LIST: Author1.firstName Author1.lastName et al.

function getInlineAuthors(authors: AuthorInformationModel[]): string {
  if (authors.length === 0) {
    return '';
  }
  if (authors.length === 1) {
    return getSingleInlineAuthor(authors[0]);
  }
  if (authors.length > 1 && authors.length < MAX_DISPLAYED_AUTHORS_APA_INLINE) {
    return getMultipleInlineAuthors(authors);
  }
  if (authors.length >= MAX_DISPLAYED_AUTHORS_APA_INLINE) {
    return `${getSingleInlineAuthor(authors[0])} et al.`;
  }
  return '';
}

function getJournalInlineAPA(citation: Citation): string {
  const authors = citation.authors;
  const authorsText = getInlineAuthors(authors);
  const publishedDateText = citation.publishedDate
    ? `${citation.publishedDate.year}`
    : `${MISSING_DATE_TEXT}.`;
  const formattedAuthorsOrTitle = authorsText ? `${authorsText}, ` : citation.citationTitle;
  return `(${formattedAuthorsOrTitle}${publishedDateText})`;
}

function getWebsiteInlineAPA(citation: Citation): string {
  const authors = citation.authors;
  const refText = authors.length ? getInlineAuthors(authors) : citation.citationTitle || '';
  const publishedDateText = citation.publishedDate
    ? `${citation.publishedDate.year}`
    : `${MISSING_DATE_TEXT}.`;
  return `(${refText}, ${publishedDateText})`;
}

function getOthersInlineAPA(citation: Citation): string {
  const authors = citation.authors;
  const refText = authors.length ? getInlineAuthors(authors) : citation.citationTitle || '';
  const publishedDateText = citation.publishedDate
    ? `${citation.publishedDate.year}`
    : `${MISSING_DATE_TEXT}.`;
  return `(${refText}, ${publishedDateText})`;
}

// Main function to get the INLINE citation
export const getInlineCitationTextAPA = (citation: Citation): string => {
  if (citation.citationType === CitationType.JournalArticle) {
    return getJournalInlineAPA(citation);
  } else if (citation.citationType === CitationType.Website) {
    return getWebsiteInlineAPA(citation);
  } else {
    return getOthersInlineAPA(citation);
  }
};

export function getPublishedYear(citationObject: Citation): string {
  return citationObject.publishedDate?.year || MISSING_DATE_TEXT;
}

export function getJournalIssueAndVolume(
  citationInfo: JournalInformationModel | undefined,
  publishedMonth: string,
): string {
  if (!citationInfo) return '';

  if (citationInfo.journalVolume) {
    if (citationInfo.journalIssue) {
      return ` ${citationInfo.journalVolume} (${citationInfo.journalIssue})`;
    }
    return ` ${citationInfo.journalVolume}`;
  }

  if (citationInfo.journalIssue) {
    return publishedMonth !== MISSING_DATE_TEXT
      ? `, no. ${citationInfo.journalIssue} (${publishedMonth})`
      : `, no. ${citationInfo.journalIssue}`;
  }

  return '';
}

export function getPageInfo(
  citationInfo: JournalInformationModel | undefined,
  issueAndVolume: string,
): string {
  if (!citationInfo) return '';

  if (citationInfo.pages) {
    return `, ${citationInfo.pages}. `;
  }

  return '.';
}

export const formatJournalCitationAPA = (
  citationObject: Citation,
  authorsText: string,
  issueAndVolume: string,
  pagesInfo: string,
): JSX.Element => {
  const publishedYear = getPublishedYear(citationObject);
  const citationTitle = getCitationTitle(citationObject);
  const journalName = getJournalName(citationObject, true);

  if (!authorsText && !citationObject.authors.length) {
    return (
      <span>
        {citationTitle}
        <Text span>{` (${publishedYear}).`}</Text>
        {journalName}
        <Text span>{`${issueAndVolume}${pagesInfo}`}</Text>
      </span>
    );
  }

  return (
    <span>
      <Text span>{`${authorsText} (${publishedYear}). `}</Text>
      {citationTitle}
      {journalName}
      <Text span>{`${issueAndVolume}${pagesInfo}`}</Text>
    </span>
  );
};

export function getListAuthorsTextAPA(authors: AuthorInformationModel[]): string {
  if (authors.length === 0) return '';
  if (authors.length === 1) return getSingleListAuthorText(authors[0], ', ');
  if (authors.length > 1 && authors.length <= MAX_DISPLAYED_AUTHORS_APA_LIST)
    return getMultipleListAuthorsText(authors, '&', true, true);
  if (authors.length >= MAX_DISPLAYED_AUTHORS_APA_LIST)
    return `${getMultipleListAuthorsText(authors.slice(0, 19), ', ', true, true)} ...`;
  return '';
}

export const getJournalListTextAPA = (citationObject: Citation): JSX.Element => {
  const authorsText = getListAuthorsTextAPA(citationObject.authors).trim();
  const publishedMonth = citationObject.publishedDate?.month || MISSING_DATE_TEXT;
  const citationInfo = citationObject.journalCitation;

  const issueAndVolume = getJournalIssueAndVolume(citationInfo, publishedMonth);
  const pagesInfo = getPageInfo(citationInfo, issueAndVolume);

  return formatJournalCitationAPA(citationObject, authorsText, issueAndVolume, pagesInfo);
};

export const formatWebsiteCitationAPA = (
  citationObject: Citation,
  authorsText: string,
): JSX.Element => {
  const websiteName = getWebsiteName(citationObject);
  const publishedYear = getPublishedYear(citationObject);
  const citationTitle = getCitationTitle(citationObject, true);
  const formattedPublishedDate = getFormattedPublishedDate(citationObject);
  const accessedDate = getAccessedDate(citationObject);

  if (!authorsText && !citationObject.authors.length) {
    return (
      <span>
        {citationTitle}
        <Text span>
          {` (${publishedYear}). ${websiteName}${formattedPublishedDate}${accessedDate} `}
        </Text>
      </span>
    );
  }

  return (
    <span>
      <Text span>{`${authorsText} (${publishedYear}). `}</Text>
      {citationTitle}
      <Text span>{`${websiteName}${formattedPublishedDate}${accessedDate} `}</Text>
    </span>
  );
};

export const getWebsiteTextListAPA = (citationObject: Citation): JSX.Element => {
  const authorsText = getListAuthorsTextAPA(citationObject.authors).trim();
  return formatWebsiteCitationAPA(citationObject, authorsText);
};

export const formatOthersCitationAPA = (
  citationObject: Citation,
  authorsText: string,
): JSX.Element => {
  const resourceName = getResourceName(citationObject);
  const publishedYear = getPublishedYear(citationObject);
  const citationTitle = getCitationTitle(citationObject, true);

  if (!authorsText && !citationObject.authors.length) {
    return (
      <span>
        {citationTitle}
        <Text span>{` (${publishedYear}). ${resourceName} `}</Text>
      </span>
    );
  }

  return (
    <span>
      <Text span>{`${authorsText} (${publishedYear}). `}</Text>
      {citationTitle}
      <Text span>{`${resourceName} `}</Text>
    </span>
  );
};

export const getOthersListTextAPA = (citationObject: Citation): JSX.Element => {
  const authorsText = getListAuthorsTextAPA(citationObject.authors).trim();
  return formatOthersCitationAPA(citationObject, authorsText);
};

export const getListCitationTextAPA = (citation: Citation): JSX.Element => {
  if (citation.citationType === CitationType.JournalArticle) {
    return getJournalListTextAPA(citation);
  } else if (citation.citationType === CitationType.Website) {
    return getWebsiteTextListAPA(citation);
  } else {
    return getOthersListTextAPA(citation);
  }
};
