import ListItem from '@tiptap/extension-list-item';
import { NodeType } from '@tiptap/pm/model';
import { liftListItem, sinkListItem } from './util';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    CustomListItem: {
      /**
       * Sink the list item down into an inner list.
       * @param typeOrName The type or name of the node.
       * @example editor.commands.sinkListItem('listItem')
       */
      sinkListItemModified: (typeOrName: string | NodeType) => ReturnType;

      /**
       * Create a command to lift the list item around the selection up into a wrapping list.
       * @param typeOrName The type or name of the node.
       * @example editor.commands.liftListItem('listItem')
       */
      liftListItemModified: (typeOrName: string | NodeType) => ReturnType;
    };
  }
}

/**
 * This custom list item extension for Tiptap is designed to align the behavior of list items
 * with the functionality of list items in DOCX documents.
 *
 * Key features:
 * 1. Modifies the behavior of the Tab and Shift+Tab keys to match DOCX list indentation.
 * 2. Restricts each list item to contain only a single paragraph, ensuring consistency with DOCX list item behavior.
 *
 * This extension ensures that Tiptap list items behave similarly to DOCX for a seamless editing experience.
 */
export const CustomListItem = ListItem.extend({
  // Ensures each list item contains a single paragraph, matching DOCX behavior.
  content: 'paragraph',

  addCommands() {
    return {
      sinkListItemModified: sinkListItem,
      liftListItemModified: liftListItem,
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItemModified(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItemModified(this.name),
    };
  },
});
