import dayjs from 'dayjs';
import { PaymentSubscriptionStatusType, PaymentSubscriptionType } from '../../generated/api';

const TRIAL_EXPIRY_THRESHOLD_DAYS = 7;

export const CREDITS_COIN_ICON_URL =
  'https://ik.imagekit.io/markovml/payment-and-subscription/credit-coins_9PvXdjx-3.svg?updatedAt=1730913839922';

export const CREDITS_COIN_ICON_WIDTH = 20;
export const CREDITS_HORIZONTAL_SPACING = 4;

export const PLAN_ICON_WIDTH = 16;
export const PLAN_HORIZONTAL_SPACING = 8;

export const getPlanStatus = (subscriptionStatusType: PaymentSubscriptionStatusType) => {
  switch (subscriptionStatusType) {
    case PaymentSubscriptionStatusType.Trialing:
      return 'Free trial';
    case PaymentSubscriptionStatusType.Active:
      return 'Active';
    case PaymentSubscriptionStatusType.Canceled:
      return 'Canceled';
    default:
      return 'Unknown';
  }
};

/**
 * Calculates the number of days remaining in a trial period.
 * @param trialEnd Unix timestamp representing the trial end date
 * @returns Number of days remaining in trial
 */
export const calculateTrialDaysRemaining = (trialEnd: number): number => {
  const trialEndDate = dayjs.unix(trialEnd);
  const today = dayjs();

  return Math.max(0, trialEndDate.diff(today, 'day'));
};

type SubscriptionPlanDetailsMapType = {
  [key in PaymentSubscriptionType]: {
    iconUrl: string;
    planName: string;
  };
};

export const subscriptionPlanDetails: SubscriptionPlanDetailsMapType = {
  [PaymentSubscriptionType.EnterprisePlan]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/crown_HCqXJDGjV.svg?updatedAt=1731063205547',
    planName: 'Enterprise',
  },
  [PaymentSubscriptionType.TeamMonthly599]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/diamond_A52RhbaWA.svg?updatedAt=1730913732966',
    planName: 'Team',
  },
  [PaymentSubscriptionType.SoloMonthly99]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/stars_2BNIa_kmQ.svg?updatedAt=1731063349638',
    planName: 'Solo',
  },
  [PaymentSubscriptionType.FreemiumMonthly]: {
    iconUrl:
      'https://ik.imagekit.io/markovml/payment-and-subscription/star_fNxmDuBd0N.svg?updatedAt=1731063496255',
    planName: 'Free',
  },
};

export const isTrialDaysLessThanThreshold = (trialDaysRemaining: number): boolean =>
  trialDaysRemaining < TRIAL_EXPIRY_THRESHOLD_DAYS;
