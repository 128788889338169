import {
  CopyButton as MantineCopyButton,
  CopyButtonProps as MantineCopyButtonProps,
} from '@mantine/core';
import { CopyMarkdownButton } from './CopyMarkdownButton';

export interface CopyButtonProps extends MantineCopyButtonProps {
  isMarkdown?: boolean;
}

export const CopyButton = ({ isMarkdown = false, ...rest }: CopyButtonProps) => {
  if (isMarkdown) return <CopyMarkdownButton {...rest} />;
  return <MantineCopyButton {...rest} />;
};
