import { IconArrowLeft } from '@tabler/icons-react';
import { useState } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';
import { ulid } from 'ulid';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { useDataUpload } from '../../../../contexts/data-upload/DataUpload';
import {
  Alert,
  Button,
  closeAllModals,
  Collapse,
  Flex,
  Horizontal,
  notifications,
  Text,
  TextInput,
  useDisclosure,
  useFocusTrap,
  Vertical,
} from '../../../../design-system/v2';
import { connectorsApi } from '../../../../lib/api';
import {
  useGetIsUniqueFolderNameMutation,
  useGetPresignedUrlForBulkUploadMutation,
  useInvalidateDataSourcesList,
} from '../../../../queries/data-sources';
import { CompleteParams } from '../../../../queries/data-upload';
import { FileList } from './FileList';
import { FolderExistsPrompt } from './FolderExistsPrompt';
import { Folder, FolderUploadInput } from './FolderUploadInput';

export type Step = 'upload' | 'preview';

interface FolderUploaderProps {
  onFolderDrop?: (files: FileWithPath[]) => void;
  onUploadQueued: (uploadId?: string) => void;
  onUploadComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  additionalData?: Record<string, unknown>;
}

export const FolderUploader = ({
  onFolderDrop,
  onUploadComplete,
  onUploadQueued,
  additionalData,
}: FolderUploaderProps) => {
  const { workspaceId } = useAppMetadata();
  const [folder, setFolder] = useState<Folder | undefined>(undefined);
  const [fileRejections, setFileRejections] = useState<FileRejection[] | undefined>(undefined);
  const trapFocusRef = useFocusTrap();
  const [showUnsupportedFiles, { toggle: toggleShowUnsupportedFiles }] = useDisclosure(false);
  const { mutateAsync: getPresignedUrls, isLoading: loadingPresignedUrl } =
    useGetPresignedUrlForBulkUploadMutation();
  const { mutateAsync: checkIfFolderNameIsUnique, isLoading: loadingIsUniqueFolder } =
    useGetIsUniqueFolderNameMutation();
  const [folderNameError, setFolderNameError] = useState('');
  const { addUpload } = useDataUpload();
  const invalidateDataSourcesList = useInvalidateDataSourcesList();
  const [useSameFolderName, setUseSameFolderName] = useState(false);
  const [folderNameUniqueCheckTriggered, setFolderNameUniqueCheckTriggered] = useState(false);
  const handleFolderDrop = (
    folderName: string,
    files: FileWithPath[],
    fileRejections?: FileRejection[],
  ) => {
    setFolder({ name: folderName, files });

    if (fileRejections && fileRejections.length > 0) {
      setFileRejections(fileRejections);
      return;
    }

    setFileRejections(undefined);
    onFolderDrop?.(files);
  };

  const handleFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderNameError('');
    setUseSameFolderName(false);
    setFolder({ ...folder, name: e.target.value });
  };

  const handleGoBack = () => {
    setFolder(undefined);
  };

  const handleUpload = async () => {
    if (!folder || !folder.files) {
      return;
    }

    if (!useSameFolderName) {
      const isFolderNameUnique = await checkIfFolderNameIsUnique(folder.name);
      if (!isFolderNameUnique) {
        setFolderNameUniqueCheckTriggered(true);
        setFolderNameError(`A folder with this name already exists`);
        return;
      }
    }

    const presignedUrls = await getPresignedUrls({
      files: folder.files,
      folderName: folder.name,
    });
    const uploadId = ulid();
    addUpload(
      uploadId,
      folder.files.map((file, index) => ({
        file: file,
        uploadParams: presignedUrls.response[index],
      })),
      {
        useUploadWindow: true,
        onSuccess: () => {
          notifications.success('Uploaded successfully');
        },
        onError: () => {
          notifications.error('Error uploading');
        },
        onFileUploadComplete: async (completeParams: CompleteParams) => {
          try {
            await connectorsApi.completeUploadResourceV2(workspaceId, {
              folderName: folder.name,
              request: {
                uploadId: completeParams.uploadId,
                key: completeParams.key,
                fileName: completeParams.fileName,
                parts: completeParams.parts,
              },
            });
            onUploadComplete('success', completeParams);
          } catch (e) {
            notifications.error('Error uploading');
            onUploadComplete('error', completeParams);
            return Promise.reject(e);
          }

          invalidateDataSourcesList();
          notifications.success('Uploaded file successfully');
        },
        additionalData,
      },
    );

    onUploadQueued(uploadId);
    closeAllModals();
  };
  const handleRenameFolder = () => setFolderNameError('');

  const handleUseSameFolderName = () => {
    setFolderNameError('');
    setUseSameFolderName(true);
    handleUpload();
  };

  if (folder?.files && folder.files.length > 0) {
    return (
      <Vertical>
        {folderNameError && folderNameUniqueCheckTriggered && !useSameFolderName ? (
          <FolderExistsPrompt
            folderName={folder?.name || ''}
            onRename={handleRenameFolder}
            onProceed={handleUseSameFolderName}
          />
        ) : (
          <>
            <Horizontal onClick={handleGoBack} spacing="sm" sx={{ cursor: 'pointer' }}>
              <IconArrowLeft />
              <Text>Back</Text>
            </Horizontal>
            <Vertical spacing="md">
              <TextInput
                ref={trapFocusRef}
                label="Folder name"
                value={folder.name}
                ariaLabel="Folder name"
                onChange={handleFolderNameChange}
                error={folderNameError}
              />
              <FileList files={folder.files} />
              {fileRejections && fileRejections.length > 0 && (
                <Alert color="red">
                  <Text>
                    Note: Some of the files added in folder are not supported. Only the supported
                    files will be uploaded.
                  </Text>
                  <Button onClick={toggleShowUnsupportedFiles}>Show unsupported files</Button>
                  <Collapse in={showUnsupportedFiles}>
                    <FileList
                      files={fileRejections.map(rejection => rejection.file)}
                      areUnsupported
                    />
                  </Collapse>
                </Alert>
              )}
              <Flex mt="md" justify="end">
                <Button variant="primary" onClick={handleUpload} loading={loadingPresignedUrl}>
                  Upload
                </Button>
              </Flex>
            </Vertical>
          </>
        )}
      </Vertical>
    );
  }

  const allFilesUnsupported =
    folder &&
    (!folder.files || folder.files.length === 0) &&
    fileRejections &&
    fileRejections.length > 0;

  return (
    <Vertical spacing="md">
      <FolderUploadInput onFolderDrop={handleFolderDrop} />
      {allFilesUnsupported && (
        <Alert color="red">
          Selected folder does not have supported files. Please select folder with one of the
          supported files
        </Alert>
      )}
      <Text variant="bodyShort03" color="gray.7">
        Supported file formats: .csv/.tsv/.txt/.pdf/.jpeg/jpg/.png. File should be UTF-8 encoded.
        Maximum file size: 5GB
      </Text>
      <Alert>
        One folder will be created and all nested files from subfolders will be added to same
      </Alert>
    </Vertical>
  );
};
