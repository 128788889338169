import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  AddResourceRequest,
  AirbyteJobStatus,
  APIMessage,
  BaseAPIFilter,
  ConnectorModel,
  CreateConnectorRequest,
  CreateConnectorResponse,
  GetConnectorResponse,
  GetResourcePreviewDataResponse,
  ListConnectorResponse,
  ListResourceResponse,
  Operator,
  ResourcePreviewData,
  ResourceRequest,
  ResourceResponse,
  StorageType,
} from '../generated/api';
import { connectorsApi } from '../lib/api';
import { dataSourcesKeys } from './data-sources';

interface ConnectorsListQueryFilters {
  connectorType: StorageType;
}

const connectorsKeys = {
  all: ['connectors'] as const,
  listAll: (workspaceId: string) => [...connectorsKeys.all, 'list', workspaceId] as const,
  list: (workspaceId: string, filters?: ConnectorsListQueryFilters) =>
    [...connectorsKeys.listAll(workspaceId), { filters }] as const,
  listV2: (workspaceId: string, filters?: BaseAPIFilter[], start?: number, limit?: number) =>
    [...connectorsKeys.listAll(workspaceId), { filters, start, limit }] as const,
  detail: (workspaceId: string, connectorId: string) =>
    [...connectorsKeys.all, 'detail', workspaceId, connectorId] as const,
  listResourcesAll: (workspaceId: string, connectorId: string) =>
    [...connectorsKeys.all, 'list-resources', workspaceId, connectorId] as const,
  listResources: (workspaceId: string, connectorId: string, resourcePath: ResourceRequest[]) =>
    [...connectorsKeys.listResourcesAll(workspaceId, connectorId), { resourcePath }] as const,
  resourcePreview: (workspaceId: string, connectorId: string, resourcePath: ResourceRequest[]) =>
    [
      ...connectorsKeys.all,
      'resource-preview',
      workspaceId,
      connectorId,
      { resourcePath },
    ] as const,
  airbyteConnectorMetadata: (workspaceId: string, connectorType: string) =>
    [...connectorsKeys.all, 'airbyte-connector-json', workspaceId, connectorType] as const,
  airbyteJobDetails: (workspaceId: string, resourceId: string) =>
    [...connectorsKeys.all, workspaceId, resourceId] as const,
};

export const useGetConnectorsListQuery = (filters?: ConnectorsListQueryFilters) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  // TODO: Convert to paginated infinite query
  return useQuery<AxiosResponse<ListConnectorResponse>, AxiosError, ConnectorModel[]>(
    connectorsKeys.list(workspaceId, filters),
    () => connectorsApi.listConnectorV1(workspaceId, 0, 100, filters?.connectorType),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data.response,
      onSuccess: connectors => {
        // Seed query cache for connector details using list results
        for (const connector of connectors) {
          queryClient.setQueryData(connectorsKeys.detail(workspaceId, connector.connectorId), {
            data: {
              response: connector,
            },
          });
        }
      },
    },
  );
};

export const useGetConnectorsListV2Query = (
  connectorTypes?: StorageType[],
  page = 1,
  limit = 100,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const filters = connectorTypes
    ? [
        {
          field: 'connectorType',
          operator: Operator.In,
          value: connectorTypes,
        },
      ]
    : [];
  const start = (page - 1) * limit;

  return useQuery<AxiosResponse<ListConnectorResponse>, AxiosError, ListConnectorResponse>(
    connectorsKeys.listV2(workspaceId, filters, start, limit),
    () => connectorsApi.listConnectorsV2(workspaceId, { filters, start, limit }),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
      onSuccess: res => {
        const connectors = res.response;
        // Seed query cache for connector details using list results
        for (const connector of connectors) {
          queryClient.setQueryData(connectorsKeys.detail(workspaceId, connector.connectorId), {
            data: {
              response: connector,
            },
          });
        }
      },
    },
  );
};

export const useInvalidateConnectorsList = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(connectorsKeys.list(workspaceId));
  };
};

export const useGetConnectorDetailsQuery = (connectorId: string) => {
  const { workspaceId } = useAppMetadata();

  // TODO: Have BE update the res schema so that response is not undefined
  return useQuery<AxiosResponse<GetConnectorResponse>, AxiosError, ConnectorModel | undefined>(
    connectorsKeys.detail(workspaceId, connectorId),
    () => connectorsApi.getConnectorV1(workspaceId, connectorId),
    {
      enabled: Boolean(workspaceId && connectorId),
      select: res => res.data.response,
      staleTime: 0,
      cacheTime: 0,
    },
  );
};

export const useCreateConnectorMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<CreateConnectorResponse, any>,
    AxiosError<APIMessage>,
    CreateConnectorRequest,
    unknown
  >((request: CreateConnectorRequest) => connectorsApi.createConnectorV1(workspaceId, request), {
    onSuccess: () => {
      queryClient.invalidateQueries(connectorsKeys.listAll(workspaceId));
    },
  });
};

export const useGetConnectorResourcesQuery = (
  connectorId: string,
  resourceList: ResourceRequest[] = [],
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<ListResourceResponse>, AxiosError, ResourceResponse[]>(
    connectorsKeys.listResources(workspaceId, connectorId, resourceList),
    () =>
      connectorsApi.listResourcesV1(workspaceId, connectorId, {
        resourceList,
      }),
    {
      enabled: Boolean(workspaceId && connectorId),
      select: res => res.data.resourceList,
    },
  );
};

export const useRefetchConnectorResourcesList = (
  connectorId: string,
  resourceList: ResourceRequest[] = [],
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    queryClient.refetchQueries(
      connectorsKeys.listResources(workspaceId, connectorId, resourceList),
    );
  };
};

export const useGetConnectorResourcePreviewQuery = (
  connectorId: string,
  resourceList: ResourceRequest[] = [],
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetResourcePreviewDataResponse>, AxiosError, ResourcePreviewData>(
    connectorsKeys.resourcePreview(workspaceId, connectorId, resourceList),
    () => connectorsApi.listResourcesPreviewV1(workspaceId, connectorId, { resourceList }),
    {
      enabled: Boolean(workspaceId && connectorId && resourceList.length > 0),
      select: res => res.data.response,
    },
  );
};

export const useInvalidateListAllResourcesQuery = (connectorId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(connectorsKeys.listResourcesAll(workspaceId, connectorId));
  };
};

export const useCreateDataSourceFromResourceMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    ({ connectorId, ...request }: AddResourceRequest & { connectorId: string }) =>
      connectorsApi.addResourcesV1(workspaceId, connectorId, request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(dataSourcesKeys.lists(workspaceId));
      },
    },
  );
};

export const useCreateDataSinkFromResourceMutation = (connectorId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (request: AddResourceRequest) =>
      connectorsApi.addResourcesV1(workspaceId, connectorId, { ...request, isDataSink: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(dataSourcesKeys.lists(workspaceId));
        queryClient.invalidateQueries(connectorsKeys.listResourcesAll(workspaceId, connectorId));
      },
    },
  );
};

export const useGetAccessTokenForResource = () => {
  const { workspaceId } = useAppMetadata();
  return useMutation((connectorId: string) =>
    connectorsApi.getConnectorTokenV1(workspaceId, connectorId),
  );
};

export const useGetAirbyteConnectorJsonQuery = (connectorType: StorageType) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    connectorsKeys.airbyteConnectorMetadata(workspaceId, connectorType),
    () => connectorsApi.getConnectorFormV1(workspaceId, connectorType),
    {
      enabled: Boolean(workspaceId && connectorType),
      select: res => res.data.connectorForm,
    },
  );
};

export const useGetAirbyteJobStatusQuery = (resourceId: string, p0: { enabled: boolean }) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useQuery(
    connectorsKeys.airbyteJobDetails(workspaceId, resourceId),
    () => connectorsApi.getAirbyteJobDetailsV1(workspaceId, resourceId),
    {
      enabled: Boolean(workspaceId && resourceId),
      select: res => res.data,
      refetchInterval: 15000,
      onSuccess: data => {
        if (data.jobStatus === AirbyteJobStatus.Succeeded) {
          queryClient.invalidateQueries(connectorsKeys.listAll(workspaceId));
          queryClient.invalidateQueries(connectorsKeys.airbyteJobDetails(workspaceId, resourceId));
        }
      },
    },
  );
};
