import { CopyButtonProps } from '@mantine/core';
import { useCopyMarkdown } from './useCopyMarkdown';

export const CopyMarkdownButton = ({ value, timeout = 2000, children }: CopyButtonProps) => {
  const { copied, copy } = useCopyMarkdown({ timeout });

  const handleCopy = () => {
    copy(value);
  };

  return <>{children({ copied, copy: handleCopy })}</>;
};
