import { CitationStyleGuideType } from '../../../../../generated/api';

export const REFERENCE_PAGE_PARAM = 'refs';

export const MISSING_DATE_TEXT = 'n.d';

export const MAX_DISPLAYED_AUTHORS_APA_INLINE = 2;
export const MAX_DISPLAYED_AUTHORS_APA_LIST = 20;

export const MAX_DISPLAYED_AUTHORS_CMOS_INLINE = 4;
export const MAX_DISPLAYED_AUTHORS_CMOS_LIST = 11;

export enum DocumentCitationPosition {
  INTEXT = 'INTEXT',
  LIST = 'LIST',
}

export const DEFAULT_DATE_MODEL = {
  year: MISSING_DATE_TEXT,
  month: MISSING_DATE_TEXT,
  day: MISSING_DATE_TEXT,
};

export const DEFAULT_CITATION_STYLE = CitationStyleGuideType.Cmos;
