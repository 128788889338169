import AppDocViewer, { IDocument, PDFRenderer } from '@cyntler/react-doc-viewer';
import { FileWithPath } from '../../../../design-system/v2';

interface PDFPreviewProps {
  file: FileWithPath;
}

const pluginRenderers = [PDFRenderer];
const docTheme = { disableThemeScrollbar: true };
const docConfig = {
  header: {
    disableFileName: true,
    disableHeader: true,
  },
};

export const PDFPreview = ({ file }: PDFPreviewProps) => {
  const docs: IDocument[] = [
    {
      uri: window.URL.createObjectURL(file),
      fileType: file.type,
      fileName: file.name,
    },
  ];

  return (
    <AppDocViewer
      documents={docs}
      pluginRenderers={pluginRenderers}
      theme={docTheme}
      config={docConfig}
    />
  );
};
