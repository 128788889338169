import { MarkovReferenceItem, ReferenceText, Text } from '../../../../../design-system/v2';
import {
  AuthorInformationModel,
  Citation,
  CitationStyleGuideType,
  CitationType,
  CopyEditCitation,
  JournalInformationModel,
} from '../../../../../generated/api';
import { interleaveComponent } from '../../../../../lib/util-react';
import { MISSING_DATE_TEXT } from './constants';
import {
  getAccessedDate,
  getFormattedPublishedDate,
  getMultipleInlineAuthors,
  getMultipleListAuthorsText,
  getResourceName,
  getSingleInlineAuthor,
  getSingleListAuthorText,
  getWebsiteName,
} from './util';

const MAX_DISPLAYED_AUTHORS_CMOS_INLINE = 4;
const MAX_DISPLAYED_AUTHORS_CMOS_LIST = 11;

export const dateFromCustomDateString = (dateString: string): Date => {
  try {
    if (dateString === '') {
      return new Date();
    }
    if (dateString.split('-').length === 1) {
      return new Date(dateString);
    }
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  } catch (e) {
    return new Date();
  }
};

export const getInlineCitationComponentCMOS = (
  citations: CopyEditCitation[],
  highlightType: number,
): JSX.Element => {
  const delimiter = '; ';

  const citationJSX = citations.map(citation => (
    <MarkovReferenceItem
      key={citation.citationId}
      citation={citation}
      citationStyles={CitationStyleGuideType.Cmos}
      highlightType={highlightType}
    />
  ));

  return (
    <span>
      {citations &&
        interleaveComponent(citationJSX, () => <ReferenceText value={delimiter ?? ' '} />)}
    </span>
  );
};

// If authors.length === 1: INTEXT: Author1.lastName | LIST: Author1.firstName Author1.lastName
// If authors.length === 2: INTEXT: Author1.lastName and Author2.lastName | LIST: Author1.firstName Author1.lastName and Author2.firstName Author2.lastName
// If authors.length === 3: INTEXT: Author1.lastName, Author2.lastName and Author3.lastName | LIST: Author1.firstName Author1.lastName, Author2.firstName Author2.lastName and Author3.firstName Author3.lastName
// If authors.length > 3 : INTEXT: Author1.lastName et al. | LIST: Author1.firstName Author1.lastName et al.

function getInlineAuthors(authors: AuthorInformationModel[]): string {
  if (authors.length === 0) {
    return '';
  }
  if (authors.length === 1) {
    return getSingleInlineAuthor(authors[0]);
  }
  if (authors.length > 1 && authors.length < MAX_DISPLAYED_AUTHORS_CMOS_INLINE) {
    return getMultipleInlineAuthors(authors);
  }
  if (authors.length >= MAX_DISPLAYED_AUTHORS_CMOS_INLINE) {
    return `${getSingleInlineAuthor(authors[0])} et al.`;
  }
  return '';
}

function getJournalInlineCMOS(citation: Citation): string {
  const authors = citation.authors;
  const authorsText = getInlineAuthors(authors);
  const publishedDateText = citation.publishedDate
    ? `${citation.publishedDate.year}`
    : `${MISSING_DATE_TEXT}.`;
  const formattedAuthorsOrTitle = authorsText ? `${authorsText} ` : `${citation.citationTitle}`;
  return `(${formattedAuthorsOrTitle}${publishedDateText})`;
}

function getWebsiteInlineCMOS(citation: Citation): string {
  const authors = citation.authors;
  const refText = authors.length ? getInlineAuthors(authors) : citation.citationTitle || '';
  const publishedDateText = citation.publishedDate
    ? `${citation.publishedDate.year}`
    : `${MISSING_DATE_TEXT}.`;
  return `(${refText} ${publishedDateText})`;
}

function getOthersInlineCMOS(citation: Citation): string {
  const authors = citation.authors;
  const refText = authors.length ? getInlineAuthors(authors) : citation.citationTitle || '';
  const publishedDateText = citation.publishedDate
    ? `${citation.publishedDate.year}`
    : `${MISSING_DATE_TEXT}.`;
  return `(${refText} ${publishedDateText})`;
}

// Main function to get the INLINE citation
export const getInlineCitationTextCMOS = (citation: Citation): string => {
  if (citation.citationType === CitationType.JournalArticle) {
    return getJournalInlineCMOS(citation);
  } else if (citation.citationType === CitationType.Website) {
    return getWebsiteInlineCMOS(citation);
  } else {
    return getOthersInlineCMOS(citation);
  }
};

export function getAuthorsListTextCMOS(authors: AuthorInformationModel[]): string {
  if (authors.length === 0) return '';
  if (authors.length === 1) return getSingleListAuthorText(authors[0], ', ');
  if (authors.length > 1 && authors.length <= MAX_DISPLAYED_AUTHORS_CMOS_LIST)
    return getMultipleListAuthorsText(authors, 'and', false, false);
  // If authors.length > 11, return the first 7 authors + et al.
  if (authors.length >= MAX_DISPLAYED_AUTHORS_CMOS_LIST)
    return `${getMultipleListAuthorsText(authors.slice(0, 7), 'and', false, false)} et al.`;
  return '';
}

export function getPublishedYear(citationObject: Citation): string {
  return citationObject.publishedDate?.year || MISSING_DATE_TEXT;
}

export const getCitationTitle = (citationObject: Citation, isItalic = false): JSX.Element => (
  <Text span fs={isItalic ? 'italic' : 'normal'}>
    {citationObject.citationTitle ? `${citationObject.citationTitle}. ` : ''}
  </Text>
);

export const getJournalName = (citationObject: Citation, isItalic = false): JSX.Element => (
  <Text span fs={isItalic ? 'italic' : 'normal'}>
    {citationObject.journalCitation?.journalName
      ? `${citationObject.journalCitation?.journalName} `
      : ''}
  </Text>
);

export function getJournalIssueAndVolume(
  citationInfo: JournalInformationModel | undefined,
  publishedMonth: string,
): string {
  if (!citationInfo) return '';

  if (citationInfo.journalVolume) {
    if (citationInfo.journalIssue) {
      return ` ${citationInfo.journalVolume} (${citationInfo.journalIssue})`;
    }
    return ` ${citationInfo.journalVolume}`;
  }

  if (citationInfo.journalIssue) {
    return publishedMonth !== MISSING_DATE_TEXT
      ? `, no. ${citationInfo.journalIssue} (${publishedMonth})`
      : `, no. ${citationInfo.journalIssue}`;
  }

  return '';
}

export function getPageInfo(
  citationInfo: JournalInformationModel | undefined,
  issueAndVolume: string,
): string {
  if (!citationInfo) return '';

  if (!issueAndVolume && citationInfo.pages) {
    return `, ${citationInfo.pages}.`;
  }

  if (citationInfo.pages) {
    return `: ${citationInfo.pages}.`;
  }

  return '.';
}

export const formatJournalCitationCMOS = (
  citationObject: Citation,
  authorsText: string,
  issueAndVolume: string,
  pagesInfo: string,
): JSX.Element => {
  const publishedYear = getPublishedYear(citationObject);
  const citationTitle = getCitationTitle(citationObject);
  const journalName = getJournalName(citationObject);

  if (!authorsText && !citationObject.authors.length) {
    return (
      <span>
        {citationTitle}
        <Text span>{` (${publishedYear}). `}</Text>
        {journalName}
        <Text span>{`${issueAndVolume}${pagesInfo} `}</Text>
      </span>
    );
  }

  const formattedAuthorsText =
    authorsText.charAt(authorsText.length - 1) === '.' ? `${authorsText}` : `${authorsText}.`;

  return (
    <span>
      <Text span>{`${formattedAuthorsText} ${publishedYear}. `}</Text>
      {citationTitle}
      {journalName}
      <Text span>{`${issueAndVolume}${pagesInfo} `}</Text>
    </span>
  );
};

export const getJournalListTextCMOS = (citationObject: Citation): JSX.Element => {
  const authorsText = getAuthorsListTextCMOS(citationObject.authors).trim();
  const publishedMonth = citationObject.publishedDate?.month || MISSING_DATE_TEXT;
  const citationInfo = citationObject.journalCitation;

  const issueAndVolume = getJournalIssueAndVolume(citationInfo, publishedMonth);
  const pagesInfo = getPageInfo(citationInfo, issueAndVolume);

  return formatJournalCitationCMOS(citationObject, authorsText, issueAndVolume, pagesInfo);
};

export const formatWebsiteCitationCMOS = (
  citationObject: Citation,
  authorsText: string,
): JSX.Element => {
  const websiteName = getWebsiteName(citationObject);
  const publishedYear = getPublishedYear(citationObject);
  const citationTitle = getCitationTitle(citationObject);
  const formattedPublishedDate = getFormattedPublishedDate(citationObject);
  const accessedDate = getAccessedDate(citationObject);

  if (!authorsText && !citationObject.authors.length) {
    return (
      <span>
        {citationTitle}
        <Text span>
          {` ${publishedYear}. ${websiteName}${formattedPublishedDate}${accessedDate} `}
        </Text>
      </span>
    );
  }
  const formattedAuthorsText =
    authorsText.charAt(authorsText.length - 1) === '.' ? `${authorsText}` : `${authorsText}.`;

  return (
    <span>
      <Text span>{`${formattedAuthorsText} ${publishedYear}. `}</Text>
      {citationTitle}
      <Text span>{` ${websiteName}${formattedPublishedDate}${accessedDate} `}</Text>
    </span>
  );
};

export const getWebsiteTextListCMOS = (citationObject: Citation): JSX.Element => {
  const authorsText = getAuthorsListTextCMOS(citationObject.authors).trim();
  return formatWebsiteCitationCMOS(citationObject, authorsText);
};

export const formatOthersCitationCMOS = (
  citationObject: Citation,
  authorsText: string,
): JSX.Element => {
  const resourceName = getResourceName(citationObject);
  const publishedYear = getPublishedYear(citationObject);
  const citationTitle = getCitationTitle(citationObject);

  if (!authorsText && !citationObject.authors.length) {
    return (
      <span>
        {citationTitle}
        <Text span>{` ${publishedYear}. ${resourceName} `}</Text>
      </span>
    );
  }

  const formattedAuthorsText =
    authorsText.charAt(authorsText.length - 1) === '.' ? `${authorsText}` : `${authorsText}.`;

  return (
    <span>
      <Text span>{`${formattedAuthorsText} ${publishedYear}. `}</Text>
      {citationTitle}
      <Text span>{` ${resourceName} `}</Text>
    </span>
  );
};

const getOthersListTextCMOS = (citationObject: Citation): JSX.Element => {
  const authorsText = getAuthorsListTextCMOS(citationObject.authors).trim();
  return formatOthersCitationCMOS(citationObject, authorsText);
};

export const getListCitationTextCMOS = (citation: Citation): JSX.Element => {
  if (citation.citationType === CitationType.JournalArticle) {
    return getJournalListTextCMOS(citation);
  } else if (citation.citationType === CitationType.Website) {
    return getWebsiteTextListCMOS(citation);
  } else {
    return getOthersListTextCMOS(citation);
  }
};
