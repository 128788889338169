import { Select } from '../../../design-system/v2';
import { AsyncDropDownResources } from '../../../generated/api';
import { ModelSelection } from './resource-selection/ModelSelection';

export const getSelectorBasedOnResource = (resourceType: AsyncDropDownResources) => {
  if (resourceType === AsyncDropDownResources.WorkflowModels) {
    return ModelSelection;
  }

  return Select;
};
