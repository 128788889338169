import { QueryClientConfig } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // Wait 10 mins (delibrately increasing time as we don't have data updating issues) to invalidate cache
      staleTime: 10 * 60 * 1000,
      // Don't refetch in local.
      refetchOnWindowFocus: import.meta.env.VITE_APP_ENV !== 'local',
      // retry max 3 times
      retry: (failureCount, error) => {
        if (import.meta.env.VITE_APP_ENV === 'local') {
          return false;
        }
        if (failureCount > MAX_RETRIES) {
          return false;
        }
        if (HTTP_STATUS_TO_NOT_RETRY.includes((error as AxiosError).response?.status ?? 0)) {
          return false;
        }

        return true;
      },
    },
  },
};
