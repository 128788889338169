import { Box, Divider, useMarkovTheme, Vertical } from '../../../../design-system/v2';
import { GetWorkspaceCurrentSubscriptionDetailResponseModel } from '../../../../generated/api';
import { CreditsSection } from './CreditsSection';
import { PlanSection } from './PlanSection';

interface SubscriptionDetailsProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
}

export const SubscriptionDetails = ({ subscriptionDetail }: SubscriptionDetailsProps) => {
  const theme = useMarkovTheme();

  return (
    <Box>
      <Vertical
        p="lg"
        sx={{ border: `1.5px solid ${theme.colors.gray[2]}`, borderRadius: theme.radius.sm }}
        spacing="lg"
      >
        <PlanSection subscriptionDetail={subscriptionDetail} />
        <Divider sx={{ border: `1px solid ${theme.colors.gray[2]}` }} />
        <CreditsSection subscriptionDetail={subscriptionDetail} />
      </Vertical>
    </Box>
  );
};
