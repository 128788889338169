import { closeAllModals, openModal } from '../../../design-system/v2';
import { AsyncDropDownResources } from '../../../generated/api';
import { useInvalidateDataSourcesList } from '../../../queries/data-sources';
import { CompleteParams } from '../../../queries/data-upload';
import { useInvalidateResourceLookup } from '../../../queries/resource-lookup';
import { FolderUploader } from '../../common/uploader/folder-uploader/FolderUploader';

export type Step = 'upload' | 'preview';

interface DataSourcesFileModalProps {
  onFileUploadComplete?: (state: 'success' | 'error', completeParams: CompleteParams) => void;
}

export const DataSourcesFolderModal = ({ onFileUploadComplete }: DataSourcesFileModalProps) => {
  const invalidateDataSources = useInvalidateDataSourcesList();
  const invalidateResources = useInvalidateResourceLookup(AsyncDropDownResources.DataResources);

  const onUploadQueued = () => {
    closeAllModals();
  };

  const onUploadComplete = (state: 'success' | 'error', completeParams: CompleteParams) => {
    invalidateDataSources();
    invalidateResources();
    onFileUploadComplete?.(state, completeParams);
  };
  return <FolderUploader onUploadQueued={onUploadQueued} onUploadComplete={onUploadComplete} />;
};

interface UseDataSourcesFolderUploadModalProps {
  onFileUploadComplete?: (state: 'success' | 'error', completeParams: CompleteParams) => void;
}

export const useDataSourcesFolderUploadModal =
  (options?: UseDataSourcesFolderUploadModalProps) => () =>
    openModal({
      title: 'Upload a folder',
      size: 'lg',
      children: <DataSourcesFolderModal />,
    });
