import HighchartsReact from 'highcharts-react-official';
import { useRef, useState } from 'react';
import { ChartTypes } from '../../../charts';
import { ConfusionMatrixTooltip } from '../../../charts/components/tooltip/heatmap/confusion-matrix';
import { ChartSeries } from '../../../charts/config/build';
import { ChartsProvider } from '../../../charts/context/Context';
import { Expand, Loader } from '../../../design-system';
import {
  ActionIcon,
  Alert,
  Box,
  Horizontal,
  Modal,
  Text,
  useMarkovTheme,
} from '../../../design-system/v2';
import { useConfusionMatrixQuery } from '../../../queries/confusionMatrix';
import { VisualizationComponent } from '../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../analyser/visualization/actions/DownloadVisualization';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';

const MAX_LABELS_ALLOWED = 20;
const DEFAULT_CHART_COLOR = '#753489';

interface ConfusionMatrixContainerProps {
  evaluationId: string;
  chartColor?: string;
  expandable?: boolean;
}

export const ConfusionMatrixContainer = ({
  evaluationId,
  chartColor = DEFAULT_CHART_COLOR,
  expandable = true,
}: ConfusionMatrixContainerProps) => {
  const theme = useMarkovTheme();
  const chartRef = useRef<HighchartsReact.RefObject | null>(null);
  const [expanded, showExpanded] = useState(false);
  const { isLoading, isError, data: matrixData } = useConfusionMatrixQuery(evaluationId);

  const handleCarouselOpen = () => {
    showExpanded(true);
  };

  const handleCarouselClose = () => {
    showExpanded(false);
  };

  if (isError) {
    return (
      <Box py={64}>
        <ErrorState
          compact
          title="Error retrieving confusion matrix data"
          subtitle="Please retry after sometime by refreshing the page. If the issue persists, contact support@markovml.com"
        />
      </Box>
    );
  }

  if (!matrixData?.data) {
    return <Alert>No data found for confusion matrix</Alert>;
  }

  const { actualTotals, allLabels } = matrixData.metadata;

  const exceedsMaxSize = allLabels.length > MAX_LABELS_ALLOWED;
  if (exceedsMaxSize) {
    return (
      <Box py={64}>
        <EmptyState
          compact
          iconName="evaluation"
          title="Max labels exceeded"
          subtitle={`We currently only visualize up to ${MAX_LABELS_ALLOWED} classes in a confusion matrix`}
        />
      </Box>
    );
  }

  const enhancedSeries: ChartSeries[] =
    matrixData.data.series?.map(s => ({
      ...s,
      data:
        s.data?.map(datum => ({
          ...datum,
          total: actualTotals[datum.y],
        })) ?? [],
      dataLabels: {
        enabled: true,
        format: '{point.value:.1f}%',
        style: {
          fontSize: '13px',
        },
      },
    })) ?? [];

  const data = {
    ...matrixData.data,
    series: enhancedSeries,
  };

  const header = (
    <VisualizationComponent.Header
      actions={
        !expanded && (
          <Horizontal>
            <DownloadVisualization fileName={'confusion_matrix'} isDownloadDisabled={isLoading} />
            {expandable && (
              <ActionIcon color="gray.6" onClick={handleCarouselOpen} disabled={isLoading}>
                <Expand />
              </ActionIcon>
            )}
          </Horizontal>
        )
      }
    />
  );

  let content = null;
  if (isLoading) {
    content = <Loader text="Loading confusion matrix data" />;
  } else if (data) {
    content = (
      <Box h="100%" pb="xxl">
        <VisualizationComponent.Chart
          colors={[theme.fn.themeColor('white.0'), chartColor]}
          data={data}
          colorByPoint={false}
          type={ChartTypes.HEATMAP}
          xAxisOptions={{ title: 'Predictions', opposite: true }}
          yAxisOptions={{ title: 'Actuals', reversed: true }}
          legendOptions={{ y: 30 }}
          customTooltip={ConfusionMatrixTooltip}
          enableLegends
        />
      </Box>
    );
  }

  const vizContent = (
    <VisualizationComponent
      cardProps={{ shadow: '', p: '16px', withBorder: !expanded, sx: { height: 'inherit' } }}
    >
      {header}
      {content}
    </VisualizationComponent>
  );

  return (
    <>
      <ChartsProvider hcRef={chartRef}>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleCarouselClose}
        size="80vw"
        centered
        title={<Text variant="subTitle02">Confusion Matrix</Text>}
        dataTestId="confusion-matrix-carousel"
      >
        {vizContent}
      </Modal>
    </>
  );
};
