import { AppState, Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { audience, clientId, domain, scope } from '../../lib/auth';
import { cleanErrorsInSearchParams } from '../../lib/router';

const SIGN_UP_PATH = '/signup';

export const useAuth = useAuth0;

/**
 * Exposing a hook for login
 */
export const useAuthLogin = () => {
  const { loginWithRedirect } = useAuth0();

  const searchParams = cleanErrorsInSearchParams(window.location.search);

  const appState = {
    // In case of signup, user should get redirected to BASE_ROUTE and not to signup page again
    returnTo: window.location.pathname.includes(SIGN_UP_PATH) ? '/' : window.location.pathname,
    queryParams: searchParams || '',
  };

  const handleLoginRedirect = () => loginWithRedirect({ appState });

  const handleSignupRedirect = () =>
    /** Support Sign-up when using Universal Login screens from Auth0 */
    // https://auth0.com/docs/authenticate/login/auth0-universal-login/new-experience#signup
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        prompt: 'login',
      },
      appState,
    });

  return { handleLoginRedirect, handleSignupRedirect };
};

export const AuthProvider = ({
  children,
  ...rest
}: PropsWithChildren<Partial<Auth0ProviderOptions>>) => {
  const navigate = useNavigate();

  const searchParams = cleanErrorsInSearchParams(window.location.search);

  const onRedirectCallback = (appState?: AppState) => {
    navigate(
      appState?.returnTo
        ? `${appState?.returnTo}${appState?.queryParams || ''}`
        : `${window.location.pathname}${searchParams || ''}`,
    );
  };

  const providerProps: Auth0ProviderOptions = {
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: `${
        window.location.origin.includes(SIGN_UP_PATH)
          ? window.location.origin.replace(SIGN_UP_PATH, '')
          : window.location.origin
      }${searchParams}`,
      audience,
      scope,
    },
    onRedirectCallback,
    useRefreshTokens: true,
    // This allows token to be saved in localStorage and speeds up load time when page is refreshed
    // cacheLocation: 'localstorage',
    ...rest,
  };

  return <Auth0Provider {...providerProps}>{children}</Auth0Provider>;
};
