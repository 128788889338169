import { IconFile } from '@tabler/icons-react';
import { FileWithPath } from 'react-dropzone';
import { Box, Flex, Text } from '../../../../design-system/v2';
import { useMantineTheme } from '../../../../design-system/v2/styles';

export const FileItem = ({
  file,
  isUnsupported,
}: {
  file: FileWithPath;
  isUnsupported?: boolean;
}) => {
  const theme = useMantineTheme();

  return (
    <Flex gap="sm" align="center">
      <IconFile size={20} color={isUnsupported ? theme.colors.gray[6] : theme.colors.gray[7]} />
      <Text
        variant="subTitle03"
        color={isUnsupported ? 'gray.6' : 'gray.8'}
        strikethrough={isUnsupported}
        underline={!isUnsupported}
        sx={{ flex: 1 }}
      >
        {file.name}
      </Text>
    </Flex>
  );
};

interface FileListProps {
  files: FileWithPath[];
  areUnsupported?: boolean;
}

export const FileList = ({ files, areUnsupported }: FileListProps) => (
  <Box>
    {files.map(file => (
      <FileItem key={file.name} file={file} isUnsupported={areUnsupported} />
    ))}
  </Box>
);
