import { MODAL_LEVEL_2_Z_INDEX, Modal, Text } from '../../../design-system/v2';
import { StorageType } from '../../../generated/api';
import { getConnectorTypeDisplayName } from '../util';
import { AzureBlobConnectorModal } from './AzureBlobConnectorModal';
import { CreateConnectorModal } from './CreateConnectorModal';
import { GoogleSheetModal } from './GoogleSheetConnector';
import { PostgresConnectorModal } from './PostgresConnectorModal';
import { S3ConnectorModal } from './S3ConnectorModal';
import { SnowflakeConnectorModal } from './SnowflakeConnectorModal';

/**
 * This is polymorphic modal. When a connectorType prop is specified (e.g. Snowflake), the
 * corresponding form for that connector type is shown. Otherwise when the modal opens, a
 * list of available connector type options is shown, which can be customized using the
 * availableConnectorTypes prop.
 */

interface CreateConnectorWithTypeModalProps {
  opened: boolean;
  onCreate: (connectorId: string) => void;
  onClose: () => void;
  connectorType?: StorageType;
  availableConnectorTypes?: StorageType[];
}

export const CreateConnectorWithTypeModal = ({
  opened,
  connectorType,
  availableConnectorTypes,
  onCreate,
  onClose,
}: CreateConnectorWithTypeModalProps): JSX.Element => {
  const modalDetails: { connectorType: StorageType; children: JSX.Element }[] = [
    {
      connectorType: StorageType.Snowflake,
      children: <SnowflakeConnectorModal onClose={onClose} onCreate={onCreate} />,
    },
    {
      connectorType: StorageType.S3,
      children: <S3ConnectorModal onClose={onClose} onCreate={onCreate} />,
    },
    {
      connectorType: StorageType.Postgresql,
      children: <PostgresConnectorModal onClose={onClose} onCreate={onCreate} />,
    },
    {
      connectorType: StorageType.AzureBlob,
      children: <AzureBlobConnectorModal onClose={onClose} onCreate={onCreate} />,
    },
    {
      connectorType: StorageType.GoogleSheet,
      children: <GoogleSheetModal onClose={onClose} onCreate={onCreate} />,
    },
  ];

  const config = modalDetails.find(item => item.connectorType === connectorType);
  const modalContent =
    connectorType && config ? (
      config.children
    ) : (
      <CreateConnectorModal
        availableConnectorTypes={availableConnectorTypes}
        onClose={onClose}
        onCreate={onCreate}
      />
    );
  const modalTitle = connectorType
    ? `New ${getConnectorTypeDisplayName(connectorType)} connector`
    : 'New connector';

  return (
    <Modal
      opened={opened}
      centered
      size="lg"
      title={<Text variant="subTitle01">{modalTitle}</Text>}
      zIndex={MODAL_LEVEL_2_Z_INDEX}
      onClose={onClose}
    >
      {modalContent}
    </Modal>
  );
};
