import { IconSparkles } from '@tabler/icons-react';
import {
  Accordion,
  Alert,
  Box,
  Button,
  Card,
  Horizontal,
  MarkdownPreview,
  Prism,
  Skeleton,
  Text,
  Vertical,
} from '../../../../../design-system/v2';
import { purples } from '../../../../../design-system/v2/theme/colors/colorList';
import { DataSourceConversationModel } from '../../../../../generated/api';
import { useDataAnalyticsGetAISuggestedFixMutation } from '../../../../../queries/data-analytics';

interface AnswerErrorSQLContainerProps {
  resourceId: string;
  responseItem: DataSourceConversationModel;
}

export const AnswerErrorSQLContainer = ({
  resourceId,
  responseItem,
}: AnswerErrorSQLContainerProps) => {
  const {
    isLoading,
    isError,
    data: { data: aiSuggestedFix } = {},
    mutate: getAISuggestedFix,
  } = useDataAnalyticsGetAISuggestedFixMutation(resourceId);

  const getAISuggestedFixHandler = () => {
    getAISuggestedFix(responseItem.conversationId);
  };

  const sql_query: string = responseItem.answer['sql_query'];
  const sql_error: string = responseItem.answer['sql_error'];

  return (
    <Box>
      <Accordion chevronPosition="right">
        <Accordion.Item value="sql_error" bg="red.0" sx={{ borderRadius: '8px' }}>
          <Accordion.Control>
            <Horizontal position="apart" h={20} noWrap>
              <Text variant="bodyShort02" ml="md">
                Could not generate a valid response. Try changing your query.
              </Text>
              <Button
                variant="subtle"
                leftIcon={<IconSparkles color={purples.purple6} />}
                onClick={getAISuggestedFixHandler}
              >
                <Text color="purple.6">Explain with AI</Text>
              </Button>
            </Horizontal>
          </Accordion.Control>
          <Accordion.Panel>
            <Text variant="subTitle01" mb="md" ml="sm">
              Here is what we attempted:
            </Text>
            <Prism language="sql" my="md">
              {sql_query}
            </Prism>
            <Text variant="subTitle01" mb="md" ml="sm">
              Unfortunately, it did not work:
            </Text>
            <Prism language="python">{sql_error}</Prism>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Box mt="lg">
        {isError && <Alert color="red">There was an error loading AI-based explanations.</Alert>}

        {isLoading && (
          <Vertical h="100%" w="100%" spacing={12}>
            <Skeleton height={8} w="90%" radius="sm" />
            <Skeleton height={8} w="90%" radius="sm" />
            <Skeleton height={8} w="60%" radius="sm" />
          </Vertical>
        )}

        {aiSuggestedFix && (
          <Card bg="gray.1">
            <Text variant="subTitle01" mb="xs">
              Problem Description:
            </Text>
            <MarkdownPreview>{aiSuggestedFix.problemDescription}</MarkdownPreview>
            <Text variant="subTitle01" mb="xs">
              AI Proposed Solution:
            </Text>
            <MarkdownPreview>{aiSuggestedFix.proposedSolution}</MarkdownPreview>
          </Card>
        )}
      </Box>
    </Box>
  );
};
