import HighchartsReact from 'highcharts-react-official';
import { useMemo, useRef } from 'react';
import { ChartTypes } from '../../../charts';
import { ChartProps } from '../../../charts/Chart';
import { ChartData } from '../../../charts/config/build';
import { ChartsProvider } from '../../../charts/context/Context';
import { Expand } from '../../../design-system';
import { ActionIcon, Box, Horizontal } from '../../../design-system/v2';
import { Analysis, DataSegment } from '../../../generated/api';
import { formatNumber } from '../../../lib/ui';
import { getSegmentText } from '../../analyser/analyser.util';
import { VisualizationComponent } from '../../analyser/visualization/Visualization';
import { Visualization } from '../../analyser/visualization/Visualization.util';
import { DownloadVisualization } from '../../analyser/visualization/actions/DownloadVisualization';
import { Circle } from '../../common/Circle.style';
import { segmentToColors, transformSeriesName } from '../../dataset-details/util';

interface ClassDistributionProps extends Omit<ChartProps, 'data' | 'type'> {
  analysis: Analysis;
  segment?: DataSegment;
  numRecords?: number;
  showTitle?: boolean;
  showActions?: boolean;
  showBorders?: boolean;
  expandable?: boolean;
  onClickExpand?: (visualization: Visualization) => void;
}
export const ClassDistribution = ({
  analysis,
  segment,
  numRecords = 1,
  showTitle = true,
  showActions = true,
  showBorders = true,
  expandable = true,
  onClickExpand = () => undefined,
  ...restProps
}: ClassDistributionProps): JSX.Element => {
  const { metadata, data } = analysis;
  const chartRef = useRef<HighchartsReact.RefObject | null>(null);

  // TODO: Move this transformation to queries
  const chartData: ChartData = useMemo(
    () => ({
      categories: data.categories as string[],
      series: [
        {
          name: metadata.segmentType,
          data: data.series.map(serie => ({
            name: transformSeriesName(serie.name),
            y: serie.data[0].y || 0,
          })),
        },
      ],
    }),
    [data, metadata],
  );

  const displayedPercentage = formatNumber(((segment?.numRecords || 0) * 100) / numRecords, 2);

  const header = (
    <VisualizationComponent.Header
      actions={
        showActions ? (
          <Horizontal p="0 8px">
            <DownloadVisualization
              fileName={`${getSegmentText(metadata.segmentType)}_(${displayedPercentage}%)`}
            />
            {expandable && (
              <ActionIcon
                color="gray.6"
                onClick={() =>
                  onClickExpand({
                    id: metadata.analysisId,
                    type: ChartTypes.PIE,
                  })
                }
              >
                <Expand />
              </ActionIcon>
            )}
          </Horizontal>
        ) : null
      }
    >
      {showTitle && (
        <Horizontal noWrap>
          <Circle
            flexShrink={0}
            radius={12}
            background={segmentToColors[metadata.segmentType][0] as string}
          />
          <VisualizationComponent.Header.Title
            title={`${getSegmentText(metadata.segmentType)} (${displayedPercentage}%)`}
          />
        </Horizontal>
      )}
    </VisualizationComponent.Header>
  );

  const content = useMemo(
    () => (
      <Box h="100%">
        <VisualizationComponent.Chart
          data={chartData}
          type={ChartTypes.PIE}
          enableLegends
          colorByPoint={false}
          {...restProps}
        />
      </Box>
    ),
    [chartData],
  );

  return (
    <ChartsProvider hcRef={chartRef}>
      <VisualizationComponent
        dataTestId={`viz_class_distribution-${segment?.segmentType ?? ''}`}
        cardProps={{
          p: '24px',
          pb: '48px',
          shadow: '',
          withBorder: showBorders && showTitle,
          sx: { height: 'inherit' },
        }}
      >
        {header}
        {content}
      </VisualizationComponent>
    </ChartsProvider>
  );
};
