import { FileWithPath } from '../../../../design-system/v2';
import { FileSelection } from './FileSelection';
import { FileUploadInput, FileUploadInputProps } from './FileUploadInput';

interface FileInputSelectProps extends Omit<FileUploadInputProps, 'onFileDrop'> {
  // This prop makes this component controlled.
  // Can rethink state management if more code is being pushed to parent components
  file?: FileWithPath;
  onFileDelete: () => void;
  onFileSelect: (file: FileWithPath) => void;
}

export const FileInputSelector = ({
  file,
  onFileDelete,
  onFileSelect,
  ...props
}: FileInputSelectProps) => {
  if (file) {
    return <FileSelection file={file} onFileDelete={onFileDelete} />;
  }

  return <FileUploadInput {...props} onFileDrop={onFileSelect} />;
};
