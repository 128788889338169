import { IconOrangeWarning } from '../../../../../../../design-system';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Horizontal,
  Loader,
  Skeleton,
  Text,
  Vertical,
  useElementSize,
} from '../../../../../../../design-system/v2';
import { Table, TableThemes } from '../../../../../../../design-system/v2/core/data-display/table';
import {
  WorkflowRunOperatorStatus,
  WorkflowRunStatus,
  WorkflowRunStatusDetailsResponse,
} from '../../../../../../../generated/api';
import { DagNodeSchemaError } from './DagNodeSchemaError';

interface NoRowsMessageProps {
  dagId?: string;
  wfRunStatus?: WorkflowRunStatus;
  nodeRunStatus?: WorkflowRunOperatorStatus;
  onRunDebug: () => void;
}

const NodeNoRowsMessage = ({
  dagId,
  wfRunStatus,
  nodeRunStatus,
  onRunDebug,
}: NoRowsMessageProps) => {
  if (!wfRunStatus || wfRunStatus === WorkflowRunStatus.NotFound) {
    return <DagNodeSchemaError workflowId={''} />;
  }

  if (wfRunStatus === WorkflowRunStatus.Running) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Loader variant="dots" size="xl" />
        <Text variant="subTitle02" color="gray.7">
          Debugging in progress
        </Text>
        <Text variant="small01" color="gray.6">
          You will be able to preview the data once debugging is complete.
        </Text>
      </Vertical>
    );
  }

  if (
    wfRunStatus === WorkflowRunStatus.ExecutionFailed &&
    (nodeRunStatus === WorkflowRunOperatorStatus.Failed ||
      nodeRunStatus === WorkflowRunOperatorStatus.NotStarted)
  ) {
    return (
      <Box w="100%" h="100%" pos="relative">
        <Center w="100%" h="100%">
          <Vertical spacing="sx" align="center">
            <IconOrangeWarning width={32} height={32} />
            <Text variant="subTitle02" color="gray.7">
              Unable to retrieve data
            </Text>
            <Text variant="small01" color="gray.6">
              Seems like operator run failed. Try running the debug again to see data preview.
            </Text>
            {onRunDebug && (
              <Button variant="outline" onClick={onRunDebug} size="xs" mt="md">
                Debug
              </Button>
            )}
          </Vertical>
        </Center>
      </Box>
    );
  }

  return <></>;
};

interface NodeSchemaPreviewProps {
  workflowId: string;
  runStatus?: WorkflowRunStatusDetailsResponse;
  nodeId: string;
  handleDebugClick: () => void;
  schema: {
    name: string;
    type: string;
  }[];
  top: string;
  dagId?: string;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
}

interface CustomHeaderProps {
  column: {
    name: string;
    type: string;
  };
  toggleColumnSelection: (colId: string) => void;
  isSelected: boolean;
}

export const HeaderWithCheckbox = ({
  column,
  toggleColumnSelection,
  isSelected,
}: CustomHeaderProps) => {
  const handleCheckboxChange = () => {
    toggleColumnSelection(column.name);
  };

  return (
    <Horizontal noWrap>
      <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
      <Text variant="subTitle04">{column.name}</Text>
    </Horizontal>
  );
};

export const NodeSchemaPreview = ({
  schema,
  nodeId,
  handleDebugClick,
  runStatus,
  workflowId,
  top,
  dagId,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
}: NodeSchemaPreviewProps) => {
  const { ref, height, width } = useElementSize<HTMLDivElement>();

  const validSelectedColumns =
    selectedColumns?.filter(col => schema.some(schemaItem => schemaItem.name === col)) ?? [];

  const toggleColumnSelection = (colId: string) => {
    if (setSelectedColumns && selectedColumns) {
      const index = validSelectedColumns.indexOf(colId);
      if (index > -1) {
        setSelectedColumns(validSelectedColumns.filter((id: string) => id !== colId));
      } else {
        return setSelectedColumns([...validSelectedColumns, colId]);
      }
    }
  };

  const colDefs = schema.map(col => ({
    colId: col.name,
    headerName: col.name,
    headerComponent: allowColumnSelection
      ? () => (
          <HeaderWithCheckbox
            column={col}
            toggleColumnSelection={toggleColumnSelection}
            isSelected={(selectedColumns ?? []).includes(col.name)}
          />
        )
      : undefined,
    cellRenderer: () => (
      <Center w="100%" h="100%">
        <Skeleton h="24px" animate={false} />
      </Center>
    ),
  }));

  const workflowRunStatus = runStatus?.wflRunStatus;
  const nodeRunStatus = runStatus?.operatorsStatus?.find(
    operatorStatus => operatorStatus.nodeId === nodeId,
  )?.statusDetails.status;

  if (colDefs.length === 0) {
    return (
      <Box w="100%" h="100%" pos="relative">
        <Center w="100%" h="100%">
          <Vertical align="center" spacing={0}>
            <IconOrangeWarning width={32} height={32} />
            <Text variant="subTitle02" color="gray.7">
              Input data missing
            </Text>
            <Text variant="small01" color="gray.6">
              Connect this operation node to another node with valid input to access the data.
            </Text>
          </Vertical>
        </Center>
      </Box>
    );
  }

  return (
    <Box w="100%" h="100%" ref={ref}>
      <Box pos="relative" w={width} h={height}>
        <Table
          theme={TableThemes.THEME_V2}
          columns={colDefs}
          rowData={[]}
          noRowsOverlayComponent={() => <></>}
        />
      </Box>
      <Box top={top} left="50%" pos="absolute">
        <NodeNoRowsMessage
          wfRunStatus={workflowRunStatus}
          nodeRunStatus={nodeRunStatus}
          dagId={dagId}
          onRunDebug={handleDebugClick}
        />
      </Box>
    </Box>
  );
};
