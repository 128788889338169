import { Mark, mergeAttributes } from '@tiptap/core';
import { v4 as uuid } from 'uuid';

export interface TextStyleOptions {
  HTMLAttributes: Record<string, any>;
}

export function sanitizeStyleAttr(styleAttr: string) {
  // Define a regular expression to match potentially harmful CSS code
  const regex = /expression|javascript|behaviour|vbscript|data:/gi;

  // Remove any potentially harmful code from the style attribute
  const sanitizedStyleAttr = styleAttr.replace(regex, '');

  // Return the sanitized style attribute
  return sanitizedStyleAttr;
}

export const TextStyle = Mark.create<TextStyleOptions>({
  name: 'textStyle',

  // We want to keep the formatting preserved even if the mark is split
  keepOnSplit: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      'data-id': {
        default: uuid(),
      },
      class: {
        default: null,
      },
      style: {
        default: '',
        parseHTML: element => element.getAttribute('style'),
        renderHTML: attributes => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: sanitizeStyleAttr(attributes.style),
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
