import {
  UseQueryOptions as BaseUseQueryOptions,
  QueryFunction,
  QueryKey,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { usePublicSnippetDetail } from '../components/snippets/detail/context/PublicSnippetContext';
import { publicApis } from '../lib/api/vienna';

interface UseQueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey>
  extends BaseUseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  publicChartKey?: TQueryKey;
  // TODO: Fix this
  publicChartFn?: (data: any) => any;
}

export function useChartQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  baseQueryKey: TQueryKey,
  baseQueryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  const { chartId, snippetId } = usePublicSnippetDetail();

  const queryKey = (
    chartId ? [...(options?.publicChartKey ?? []), ...baseQueryKey, chartId] : baseQueryKey
  ) as typeof baseQueryKey;

  const queryFn = (
    chartId
      ? async () => {
          let response;
          if (chartId.startsWith('EXPERIMENT') || chartId.startsWith('EVALUATION')) {
            response = await publicApis.getChartPublicSnippetV1(snippetId, chartId);
          } else {
            response = await publicApis.getChartPublicSnippetV11(snippetId, chartId);
          }
          const data = response.data;

          if (options?.publicChartFn) {
            return options.publicChartFn(data);
          }
          return data;
        }
      : baseQueryFn
  ) as typeof baseQueryFn;

  const enabled = !!chartId || options?.enabled;

  const query = useQuery(queryKey, queryFn, { ...options, enabled });

  return query;
}
