import { IconCheck, IconX } from '@tabler/icons-react';
import noop from 'lodash/noop';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  Affix,
  Box,
  Card,
  Flex,
  Horizontal,
  Loader,
  Text,
} from '../../design-system/v2';
import { rem } from '../../design-system/v2/styles';
import { PostUrl, Urls } from '../../generated/api';
import { CompleteParams, useUploadToS3 } from '../../queries/data-upload';

interface FileData {
  file: File;
  uploadParams: Urls | PostUrl;
}

export enum UploadState {
  Uploading = 'Uploading',
  Errored = 'Errored',
  Success = 'Success',
}

export interface DataUploadDetails {
  id: string;
  fileData: FileData[];
  state: UploadState;
  useUploadWindow?: boolean;
  additionalData?: Record<string, unknown>;
}

interface UploadOptions {
  // TODO:: remove once datasets is deprecated
  useUploadWindow?: boolean;
  additionalData?: Record<string, unknown>;
  onFileUploadComplete: (completeUploadParams: CompleteParams) => Promise<void>;
  onSuccess: () => void;
  onError: (err: string) => void;
}

interface DataUpload {
  uploads: DataUploadDetails[];
  addUpload: (id: string, fileData: FileData[], uploadOptions: UploadOptions) => void;
  abortUpload: (id: string) => void;
  removeUpload: (id: string) => void;
}

const initialContext: DataUpload = {
  uploads: [],
  addUpload: noop,
  abortUpload: noop,
  removeUpload: noop,
};

const DataUploadContext = createContext<DataUpload>(initialContext);

export const useDataUpload = () => useContext(DataUploadContext);

export const DataUploadProvider = ({ children }: PropsWithChildren<Record<never, unknown>>) => {
  const [uploads, setUploads] = useState<DataUploadDetails[]>([]);
  const uploadTos3 = useUploadToS3();

  const handleBrowserTabClose = useCallback(
    async (evt: BeforeUnloadEvent) => {
      if (uploads.length > 0) {
        evt.preventDefault();

        return (evt.returnValue = 'File is being uploaded');
      }
    },
    [uploads],
  );

  const updateUploadState = (id: string, state: UploadState) => {
    setUploads(uploads =>
      uploads.map(upload => {
        if (upload.id === id) {
          return {
            ...upload,
            state,
          };
        }
        return upload;
      }),
    );
  };

  const handleRemoveUpload = (id: string) => {
    setUploads(uploads => uploads.filter(upload => upload.id !== id));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBrowserTabClose);

    return () => window.removeEventListener('beforeunload', handleBrowserTabClose);
  }, [uploads]);

  const handleAddUpload = (id: string, fileData: FileData[], uploadOptions: UploadOptions) => {
    setUploads(
      uploads.concat({
        id,
        fileData,
        state: UploadState.Uploading,
        useUploadWindow: uploadOptions.useUploadWindow,
        additionalData: uploadOptions.additionalData,
      }),
    );

    Promise.all(
      fileData.map(({ file, uploadParams }) =>
        uploadTos3(
          {
            file,
            urlResponse: uploadParams,
          },
          uploadOptions.onFileUploadComplete,
        ),
      ),
    )
      .then(() => {
        updateUploadState(id, UploadState.Success);
        handleRemoveUpload(id);
        uploadOptions.onSuccess();
      })
      .catch(err => {
        updateUploadState(id, UploadState.Errored);
        handleRemoveUpload(id);
        uploadOptions.onError(err);
      });
  };

  const value = {
    uploads,
    addUpload: handleAddUpload,
    abortUpload: noop,
    removeUpload: handleRemoveUpload,
  };

  const windowUploads = uploads.filter(upload => upload.useUploadWindow);
  return (
    <>
      <DataUploadContext.Provider value={value}>{children}</DataUploadContext.Provider>
      {windowUploads.length > 0 ? (
        <Affix position={{ bottom: rem(20), right: rem(90) }}>
          <Card p={0} radius="sm" withBorder shadow="sm">
            <Accordion defaultValue="uploads" w={240}>
              <Accordion.Item value="uploads">
                <Accordion.Control
                  bg="blue"
                  pt="md"
                  sx={theme => ({ borderRadius: theme.shadows.xs })}
                >
                  <Horizontal>
                    <Text variant="subTitle05">Uploads</Text>
                  </Horizontal>
                </Accordion.Control>
                <Accordion.Panel>
                  {windowUploads.map(upload => (
                    <Box key={upload.id}>
                      {upload.fileData.map(file => (
                        <Flex key={`${upload.id}-${file.file.name}`} gap="sm">
                          {upload.state === UploadState.Uploading ? <Loader size="xs" /> : null}
                          {upload.state === UploadState.Errored ? (
                            <IconX color="red" size={24} />
                          ) : null}
                          {upload.state === UploadState.Success ? (
                            <IconCheck color="green" size={24} />
                          ) : null}
                          <Text key={file.file.name} variant="bodyShort02">
                            {file.file.name}
                          </Text>
                        </Flex>
                      ))}
                    </Box>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Card>
        </Affix>
      ) : null}
    </>
  );
};
