import { Horizontal } from '../../../../../design-system/v2';
import { CopyToClipboardTooltip } from '../../../CopyToClipboardTooltip';

export interface AnswerViewFooterProps {
  answerToCopy: string;
}

export const AnswerViewFooter = ({ answerToCopy }: AnswerViewFooterProps) => {
  if (!answerToCopy) {
    return null;
  }

  return (
    <Horizontal>
      <CopyToClipboardTooltip valueToCopy={answerToCopy} isMarkdown={true} />
    </Horizontal>
  );
};
