import { CopyToClipboard } from '../../design-system';
import { ActionIcon, CopyButton, Tooltip, TooltipProps } from '../../design-system/v2';

const HINT_TEXT = 'Copy';
const CONFIRM_TEXT = 'Copied!';

interface CopyToClipboardTooltipProps {
  valueToCopy: string;
  isMarkdown?: boolean;
  tooltipProps?: TooltipProps;
}

export const CopyToClipboardTooltip = ({
  valueToCopy,
  tooltipProps,
  isMarkdown = false,
}: CopyToClipboardTooltipProps): JSX.Element => (
  <CopyButton value={valueToCopy} timeout={2000} isMarkdown={isMarkdown}>
    {({ copied, copy }) => (
      <Tooltip withArrow label={copied ? CONFIRM_TEXT : HINT_TEXT} position="top" {...tooltipProps}>
        <ActionIcon size={16} onClick={copy}>
          <CopyToClipboard />
        </ActionIcon>
      </Tooltip>
    )}
  </CopyButton>
);
