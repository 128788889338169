import { ScrollArea as MantineScrollArea, ScrollAreaProps } from '@mantine/core';
import { ForwardRefWithStaticComponents } from '@mantine/utils';

export const ScrollArea = MantineScrollArea as ForwardRefWithStaticComponents<
  ScrollAreaProps,
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Autosize: MantineScrollArea.Autosize;
  }
>;
