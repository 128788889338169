import {
  ChatterRoutesApiFactory,
  ContentAddonsRoutesApiFactory,
  DataAnalyticsRoutesApiFactory,
  DatasetAnalyticsRoutesApiFactory,
} from '../../generated/api';
import { apiConfiguration } from './api';

export const chatterApi = ChatterRoutesApiFactory(apiConfiguration);

export const contentAddOnApi = ContentAddonsRoutesApiFactory(apiConfiguration);

export const dataAnalyticsApi = DataAnalyticsRoutesApiFactory(apiConfiguration);

export const datasetAnalyticsApi = DatasetAnalyticsRoutesApiFactory(apiConfiguration);
