import { IconOrangeWarning } from '../../../../../../../design-system';
import {
  Box,
  Center,
  Checkbox,
  EllipsisText,
  Horizontal,
  Skeleton,
  Text,
  Vertical,
} from '../../../../../../../design-system/v2';
import { Table, TableThemes } from '../../../../../../../design-system/v2/core/data-display/table';
import { ICellRendererParams } from '../../../../../../../design-system/v2/core/data-display/table/ag-grid';
import { AUDIO_URL_REGEX } from '../../../../utils';
import { PreviewAudioButton } from './PreviewAudioButton';

interface WorkflowDagNodeSchemaProps {
  schema: {
    name: string;
    type: string;
    placeholder?: boolean;
  }[];
  data: object[];
  type: 'Input' | 'Output';
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  modifiedColumns?: string[];
  shadingColor?: string;
}

interface CustomHeaderProps {
  column: {
    name: string;
    type: string;
    placeholder?: boolean;
  };
  toggleColumnSelection: (colId: string) => void;
  isSelected: boolean;
}

export const HeaderWithCheckbox = ({
  column,
  toggleColumnSelection,
  isSelected,
}: CustomHeaderProps) => {
  const handleCheckboxChange = () => {
    toggleColumnSelection(column.name);
  };

  return (
    <Horizontal noWrap>
      <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
      <Text variant="subTitle04" color="gray.9">
        {column.name}
      </Text>
    </Horizontal>
  );
};

export const WorkflowDagNodeSchema = ({
  schema,
  data,
  type,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  modifiedColumns,
  shadingColor,
}: WorkflowDagNodeSchemaProps) => {
  const validSelectedColumns =
    selectedColumns?.filter(col => schema.some(schemaItem => schemaItem.name === col)) ?? [];

  const toggleColumnSelection = (colId: string) => {
    if (setSelectedColumns && selectedColumns) {
      const index = validSelectedColumns.indexOf(colId);
      if (index > -1) {
        setSelectedColumns(validSelectedColumns.filter((id: string) => id !== colId));
      } else {
        return setSelectedColumns([...validSelectedColumns, colId]);
      }
    }
  };

  const colDefs = schema.map(col => ({
    colId: col.name,
    headerName: col.name,
    minWidth: 100,
    flex: 1,
    cellStyle: modifiedColumns?.includes(col.name)
      ? { backgroundColor: shadingColor ?? 'none' }
      : { backgroundColor: 'none' },
    headerComponent: allowColumnSelection
      ? () => (
          <HeaderWithCheckbox
            column={col}
            toggleColumnSelection={toggleColumnSelection}
            isSelected={(selectedColumns ?? []).includes(col.name)}
          />
        )
      : () => (
          <Text variant="subTitle04" color="gray.9">
            {col.name}
          </Text>
        ),
    cellRenderer: ({ value, data, colDef }: ICellRendererParams) => {
      if (col.placeholder) {
        return (
          <Center h="100%">
            <Skeleton h="20px" w="100%" animate={false} />
          </Center>
        );
      }

      const cellValue = (value ?? data[colDef?.colId ?? ''] ?? '') as string;
      const isAudioFileUrl = AUDIO_URL_REGEX.test(cellValue);

      return (
        <Horizontal noWrap align="center" spacing={0} h="100%">
          <EllipsisText variant="bodyLong03" color="gray.9" closeDelay={100} pt={0}>
            {cellValue}
          </EllipsisText>
          {isAudioFileUrl && (
            <Box>
              <PreviewAudioButton srcUrl={cellValue} />
            </Box>
          )}
        </Horizontal>
      );
    },
  }));

  const isObjectEmpty = (obj: object) =>
    Object.values(obj).every(value => value === null || value === undefined || value === '');

  if (colDefs.length === 0 || (data.every(isObjectEmpty) && type === 'Input')) {
    return (
      <Center w="100%" h="100%">
        <Vertical align="center" spacing={0}>
          <IconOrangeWarning width={32} height={32} />
          <Text variant="subTitle02" color="gray.7">
            Input data missing
          </Text>
          <Text variant="small01" color="gray.6" align="center">
            This operation hasn&apos;t received valid input. Please check that the previously
            connected node has the correct data!
          </Text>
        </Vertical>
      </Center>
    );
  }

  if (data.every(isObjectEmpty)) {
    return (
      <Center w="100%" h="100%">
        <Vertical align="center" spacing={0}>
          <Text variant="subTitle02" color="gray.7">
            Data preview
          </Text>
          <Text variant="small01" color="gray.6" align="center">
            Give the input and apply changes to view it in a structured format.
          </Text>
        </Vertical>
      </Center>
    );
  }

  return (
    <Table
      theme={TableThemes.THEME_V2}
      columns={colDefs}
      rowData={data}
      rowHeight={40}
      noRowsOverlayComponent={() => <></>}
    />
  );
};
