import AppDocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import { useMemo } from 'react';
import { Alert, Center, Loader } from '../../../design-system/v2';
import { useGetDataSourceUrlQuery } from '../../../queries/data-sources';

const docTheme = { disableThemeScrollbar: true };
const docConfig = {
  header: {
    disableFileName: true,
    disableHeader: true,
  },
};

interface ResourceUrlPreviewProps {
  resourceId: string;
  fileKey?: string;
}
export const getFileTypeFromPresignedUrl = (url: string) => {
  const parts = url.split('.');
  if (parts.length > 1) {
    const extension = parts[parts.length - 1];
    const queryIndex = extension.indexOf('?');
    if (queryIndex > -1) return extension.substring(0, queryIndex);
    return extension;
  }
  return null;
};

export const ResourceUrlPreview = ({ resourceId, fileKey }: ResourceUrlPreviewProps) => {
  const { isLoading, isError, data: url } = useGetDataSourceUrlQuery(resourceId, fileKey);
  const docs: IDocument[] = useMemo((): IDocument[] => {
    if (!url) {
      return [];
    }
    const fileType = getFileTypeFromPresignedUrl(url);

    return [
      {
        uri: url,
        fileType: fileType ?? 'unknown',
      },
    ];
  }, [url]);

  if (isError) {
    return (
      <Center h="100%" w="100%">
        <Alert color="red" title="Error">
          Error loading document preview
        </Alert>
      </Center>
    );
  }

  if (isLoading || !url) {
    return (
      <Center h="100%" w="100%">
        <Loader text="Loading document to render..." size="md" />
      </Center>
    );
  }

  return (
    <AppDocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      theme={docTheme}
      config={docConfig}
    />
  );
};
