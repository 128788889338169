import { useState } from 'react';
import { Box, FileWithPath, Horizontal, Select, TextInput } from '../../../../design-system/v2';
import { FilePreview } from './TabularFilePreview';

// TODO: We need to refactor the API endpoint that serves the list of supported delimiters.
// Currently it is coupled with the registration workflow, but it need not be. Hardcoding for now.
const DELIMITER_OPTIONS = [
  {
    value: ',',
    label: 'Comma',
  },
  {
    value: ';',
    label: 'Semicolon',
  },
  {
    value: '`',
    label: 'Backtick',
  },
  {
    value: '^',
    label: 'Caret',
  },
  {
    value: '\t',
    label: 'Tab',
  },
  {
    value: ' ',
    label: 'Space',
  },
];

interface TabularFilePreviewWrapperProps {
  file: FileWithPath;
  fileName: string;
  onFileNameChange: (e: React.ChangeEvent<any>) => void;
  onBackClick: () => void;
}

export const TabularFilePreviewWrapper = ({
  file,
  fileName,
  onFileNameChange,
  onBackClick,
}: TabularFilePreviewWrapperProps): JSX.Element => {
  const [delimiter, setDelimiter] = useState(',');

  const handleDelimiterChange = (value: string) => {
    setDelimiter(value);
  };

  return (
    <Box w="100%" h="100%">
      <Horizontal mb="md">
        <TextInput
          ariaLabel="Data source name"
          label="Data source name"
          value={fileName || file.name}
          placeholder="Enter data source name"
          onChange={onFileNameChange}
          w={250}
        />
        <Select
          ariaLabel="Select delimiter"
          value={delimiter}
          onChange={handleDelimiterChange}
          options={DELIMITER_OPTIONS}
        />
      </Horizontal>
      <Box w="100%" h="calc(100% - 116px)">
        <FilePreview file={file} delimiter={delimiter} />
      </Box>
    </Box>
  );
};
