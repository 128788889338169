import { ScrollArea } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import get from 'lodash/get';
import { forwardRef } from 'react';
import { Box, Divider, Horizontal, HoverCard, Text, Vertical } from '../../../../design-system/v2';

interface ModelSchemaPreviewProps {
  schema: {
    feature_name: string;
    feature_type: string;
  }[];
}

const getLabelForFeatureType = (type: string) => {
  const lowercasedType = type.toLowerCase();
  if (lowercasedType.startsWith('fp')) {
    return 'Float';
  }

  if (lowercasedType.startsWith('int') || lowercasedType.startsWith('uint')) {
    return 'Integer';
  }

  if (lowercasedType === 'bytes') {
    return 'String';
  }

  if (lowercasedType === 'bool') {
    return 'Boolean';
  }

  return '';
};

export const ModelSchemaPreview = ({ schema }: ModelSchemaPreviewProps) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <ScrollArea.Autosize mah={300}>
    <Text variant="subTitle04" color="gray.5">
      Columns used in model training
    </Text>
    <Divider color="gray.3" mb="md" />
    <Vertical>
      {schema.map(col => (
        <Vertical key={col.feature_name} spacing="xs">
          <Text variant="subTitle04" color="gray.8">
            {col.feature_name}
          </Text>
          <Text variant="small03" color="gray.6">
            {getLabelForFeatureType(col.feature_type)}
          </Text>
        </Vertical>
      ))}
    </Vertical>
  </ScrollArea.Autosize>
);

interface ModelSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  detail: Record<string, unknown>;
  'data-selected'?: boolean;
}

export const ModelSelectItem = forwardRef<HTMLDivElement, ModelSelectItemProps>(
  ({ label, detail, ...others }: ModelSelectItemProps, ref) => {
    const isSelected = others['data-selected'];

    const itemSchema = get(detail, 'artifact_metadata.schema', []) as any[];

    const artifactName = get(detail, 'source_details.workflow_name', '') as string;

    return (
      <Vertical ref={ref} {...others}>
        <Horizontal noWrap align="start">
          <Box sx={{ flexGrow: 1 }}>
            <Text variant="subTitle04" color={isSelected ? 'white.0' : 'gray.8'}>
              {label}
            </Text>
            {artifactName ? (
              <Text variant="small03" color={isSelected ? 'white.0' : 'gray.6'}>
                From {artifactName}
              </Text>
            ) : null}
          </Box>
          <HoverCard position="left" withinPortal withArrow>
            <HoverCard.Target>
              <IconEye />
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <ModelSchemaPreview schema={itemSchema} />
            </HoverCard.Dropdown>
          </HoverCard>
        </Horizontal>
      </Vertical>
    );
  },
);

ModelSelectItem.displayName = 'ModelSelectItem';
