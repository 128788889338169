import { ReactNode } from 'react';
import { Box, Button, Horizontal, Text, useDisclosure } from '../../../../../../design-system/v2';
import {
  AsyncDropDownResources,
  DataResourceType,
  StorageType,
} from '../../../../../../generated/api';
import { AddResourceModal } from '../../../../../common/resource-lookup/AddResourceModal';
import { ResourceActionsParams } from '../../../../../connectors/connector-details/actions/ResourceActions';
import { ConnectorSourceTableContainer } from './ConnectorSourceTable.container';

interface ConnectorSourceOperatorTableViewProps {
  connectorType: StorageType;
  onSelectResource: (resourceId: string) => void;
  isDataSink?: boolean;
  allowAddResource?: boolean;
  getResourceActions?: (params: ResourceActionsParams) => ReactNode;
  leafSelectable?: boolean;
  dataResourceType?: DataResourceType;
}

export const ConnectorSourceOperatorTableView = ({
  connectorType,
  onSelectResource,
  isDataSink,
  allowAddResource = true,
  getResourceActions,
  leafSelectable,
  dataResourceType,
}: ConnectorSourceOperatorTableViewProps): JSX.Element => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();

  return (
    <Box h="100%">
      <AddResourceModal
        opened={modalOpened}
        onClose={closeModal}
        resourceType={AsyncDropDownResources.DataResources}
        connectorType={connectorType}
        onSelectResource={onSelectResource}
        getResourceActions={getResourceActions}
        leafSelectable={leafSelectable}
      />
      <Horizontal position="apart" mb="xs">
        <Text variant="subTitle03" color="gray.8">
          Select a data source
        </Text>
        {allowAddResource && (
          <Button variant="primary" onClick={openModal}>
            Add new
          </Button>
        )}
      </Horizontal>
      <Box h="calc(100% - 40px)">
        <ConnectorSourceTableContainer
          connectorType={connectorType}
          onSelectResource={onSelectResource}
          isDataSink={isDataSink}
          dataResourceType={dataResourceType}
        />
      </Box>
    </Box>
  );
};
