import { Plugin } from '@tiptap/pm/state';
import { mergeAttributes, Node, ReactNodeViewRenderer } from '@tiptap/react';
import { DIFF_TYPE } from '../../../../../components/common/constants';
import { MarkovReferenceComponentContainer } from './MarkovReference.container';
import { CITATION_FORMAT } from './constants';

export const MarkovReference = Node.create({
  name: 'mkvReference',

  group: 'inline',

  inline: true, // Inline node
  atom: true, // Leaf node, should not contain other nodes
  draggable: true,

  addAttributes() {
    return {
      format: {
        default: CITATION_FORMAT.INLINE,
      },
      documentId: {
        default: '',
      },
      referenceIds: {
        default: '[]',
      },
      highlightType: {
        default: DIFF_TYPE.EQUAL,
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state, dispatch }) => {
          let isReference = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === MarkovReference.name) {
              isReference = true;

              if (dispatch) {
                // Ensure the transaction is valid before applying
                tr.delete(pos, pos + node.nodeSize);
                dispatch(tr);
              }
              return false;
            }
          });

          return isReference;
        }),
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mkvReference',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['mkvReference', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MarkovReferenceComponentContainer);
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {},
      }),
    ];
  },
});
