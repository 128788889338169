import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  ArtifactFilterState,
  AsyncDropDownResources,
  BaseAPIFilter,
  Operator,
  SortOrder,
} from '../generated/api';
import { operatorsApi } from '../lib/api';

const commonKey = 'resource-lookup';

export const queryKeys = {
  list: (workspaceId: string, resourceKey?: AsyncDropDownResources, filters?: object[]) => [
    commonKey,
    workspaceId,
    resourceKey,
    filters,
  ],
  item: (workspaceId: string, resourceKey: AsyncDropDownResources, filters?: BaseAPIFilter[]) => [
    commonKey,
    workspaceId,
    resourceKey,
    filters,
  ],
};

export const useResourceLookupQuery = (
  resourceKey: AsyncDropDownResources,
  filters: BaseAPIFilter[],
  useV2?: boolean,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    queryKeys.list(workspaceId, resourceKey, filters),
    () =>
      operatorsApi.operatorResourceListV1(
        workspaceId,
        resourceKey,
        {
          filters,
          sortKey: 'createDate',
          sortOrder: SortOrder.Desc,
          start: 0,
          limit: 20,
        },
        useV2,
      ),
    {
      enabled: !!workspaceId,
      select: data => data.data.resources,
      // Ensure latest resources are displayed in the UI
      staleTime: 0,
    },
  );
};

export const useInvalidateResourceLookup = (resourceKey: AsyncDropDownResources) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const filters = [
    {
      field: 'artifactState',
      operator: Operator.In,
      value: ArtifactFilterState.Active,
    },
  ];

  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKeys.list(workspaceId, resourceKey, filters),
    });
};

export const useResourceLookupItemQuery = (
  resourceKey: AsyncDropDownResources,
  queryFilters: BaseAPIFilter[],
  useV2?: boolean,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    queryKeys.item(workspaceId, resourceKey, queryFilters),
    () =>
      operatorsApi.operatorResourceListV1(
        workspaceId,
        resourceKey,
        {
          filters: queryFilters,
          sortKey: 'createDate',
          sortOrder: SortOrder.Desc,
          start: 0,
          limit: 20,
        },
        useV2,
      ),
    {
      enabled: !!workspaceId,
      cacheTime: 0,
      staleTime: 0,
      select: data => data.data.resources,
    },
  );
};
