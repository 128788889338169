import { useState } from 'react';
import { ulid } from 'ulid';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { useDataUpload } from '../../../../contexts/data-upload/DataUpload';
import {
  Box,
  Center,
  FileWithPath,
  Loader,
  MODAL_LEVEL_2_Z_INDEX,
  Modal,
  Vertical,
  notifications,
  useDisclosure,
  useInputState,
} from '../../../../design-system/v2';
import { connectorsApi } from '../../../../lib/api';
import { useGetPresignedUrlMutation } from '../../../../queries/data-sources';
import { CompleteParams } from '../../../../queries/data-upload';
import { FilePreview } from './FilePreview';
import { FileUploadInput } from './FileUploadInput';

export type Step = 'upload' | 'preview';

interface FileUploaderProps {
  onUploadQueued: (uploadId?: string) => void;
  onUploadComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  showPreviewInModal?: boolean;
  supportedFileTypes?: string[];
  additionalData?: Record<string, unknown>;
}

export const FileUploader = ({
  onUploadQueued,
  onUploadComplete,
  showPreviewInModal,
  supportedFileTypes,
  additionalData,
}: FileUploaderProps) => {
  const { workspaceId } = useAppMetadata();
  const [file, setFile] = useState<FileWithPath>();
  const [openPreview, { open, close }] = useDisclosure(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useInputState('');
  const { addUpload } = useDataUpload();
  const { mutateAsync: getPresignedUrl } = useGetPresignedUrlMutation();

  const handleBackClick = () => {
    setFile(undefined);
  };

  const handleUploadSuccess = () => {
    notifications.success('File upload successful', {
      autoClose: true,
    });
  };

  const handleUploadError = (err: string) => {
    notifications.error(`Uploading ${file?.name} failed`, {
      autoClose: true,
    });
  };

  const getUrlAndUpload = async () => {
    if (file) {
      let newFile = file;

      if (fileName) {
        newFile = new File([file], fileName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      }

      let fileParams;

      try {
        fileParams = await getPresignedUrl({ file: newFile });
      } catch (e) {
        notifications.error('Error uploading a file. Pls contact support');
        setIsUploading(false);
        return;
      }

      const uploadId = ulid();
      addUpload(
        uploadId,
        [
          {
            file: newFile,
            uploadParams: fileParams.data.response,
          },
        ],
        {
          useUploadWindow: true,
          onSuccess: handleUploadSuccess,
          onError: handleUploadError,
          onFileUploadComplete: async (completeParams: CompleteParams) => {
            try {
              await connectorsApi.completeUploadResourceV1(workspaceId, completeParams);
              onUploadComplete('success', completeParams);
            } catch (e) {
              onUploadComplete('error', completeParams);
            }
          },
          additionalData,
        },
      );

      close();
      onUploadQueued(uploadId);
    }
  };

  const handleUpload = () => {
    setIsUploading(true);
    getUrlAndUpload();
  };

  const handleFileDrop = (file: FileWithPath) => {
    open();
    setFile(file);
  };

  if (file && !showPreviewInModal) {
    return (
      <FilePreview
        file={file}
        handleBackClick={handleBackClick}
        setFileName={setFileName}
        isUploading={isUploading}
        handleUpload={handleUpload}
      />
    );
  }

  return (
    <>
      <Vertical spacing="xs">
        <FileUploadInput onFileDrop={handleFileDrop} supportedFilesTypes={supportedFileTypes} />
      </Vertical>
      <Modal
        opened={Boolean(showPreviewInModal && openPreview && file)}
        onClose={close}
        size="auto"
        zIndex={MODAL_LEVEL_2_Z_INDEX}
      >
        <Box h="80vh" w="75vw" sx={{ overflow: 'auto' }}>
          {file ? (
            <FilePreview
              file={file}
              handleBackClick={handleBackClick}
              setFileName={setFileName}
              isUploading={isUploading}
              handleUpload={handleUpload}
            />
          ) : (
            <Center w="100%" h="100%">
              <Loader text="Loading file..." />
            </Center>
          )}
        </Box>
      </Modal>
    </>
  );
};
