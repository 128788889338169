import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { logger } from '../initializers/logging';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

/**
 * Returns human-readable formatted string for any time unit
 * @param duration
 * @param current_unit : current time unit for given duration
 * @returns {string}
 */
export const humaniseTimeDuration = (
  duration: number,
  currentUnit: DurationUnitType = 'milliseconds',
): string => {
  if (!duration || duration <= 0) {
    logger.error(`Duration is incorrect duration: ${duration}  currentUUnit: ${currentUnit}}`);
    return 'NA';
  }
  return dayjs.duration(duration, currentUnit).humanize();
};

/**
 * Returns time delta between two time stamps in seconds
 * @param startTime
 * @param endTime
 * @returns {number}: delta in seconds
 */
export const calculateTimeStampsInterval = (startTime: string, endTime: string): number => {
  if (!dayjs(startTime).isValid() || !dayjs(endTime).isValid()) {
    return -1;
  }
  const endtime = new Date(endTime);
  const starttime = new Date(startTime);
  const actualRuntime = endtime.getTime() - starttime.getTime();
  return actualRuntime / 1000;
};

export const getHumanizedTimeFromNow = (time: string, withoutSuffix = true): string =>
  dayjs(time).fromNow(withoutSuffix);

export const getDateFromTimeStamp = (timeStamp: number | string, format?: string): string => {
  if (!dayjs(timeStamp).isValid()) {
    return 'NA';
  }
  return dayjs(timeStamp).format(format ?? 'YYYY-MM-DD');
};

export const getCurrentTimestamp = (): string => dayjs().format();

/**
 * Returns if two dates having types new Date() and string are same
 * @param date1
 * @param date2
 * @returns {boolean}: true if same else false
 */
export const isSameDay = (date1: Date, date2: Date): boolean => {
  const isSameDay = dayjs(date1).isSame(dayjs(date2), 'day');
  return isSameDay;
};

/**
 * Returns if two dates having types new Date() and string have the correlation of yesterday
 * @param date1
 * @param date2
 * @returns {boolean}: true if same else false
 */
export const isYesterday = (date1: Date, date2: Date): boolean => {
  const isYesterday = dayjs(date1).isSame(dayjs(date2).subtract(1, 'day'), 'day');
  return isYesterday;
};

/**
 * Converts a given duration and duration unit into seconds
 * @param duration The duration value
 * @param durationUnit The duration unit (e.g., 'minutes', 'hours', 'days')
 * @returns The duration in seconds
 */
export const convertAnyDurationToSeconds = (
  duration: number,
  durationUnit: DurationUnitType,
): number => {
  const durationInSeconds = dayjs.duration(duration, durationUnit).asSeconds();

  return durationInSeconds;
};

/**
 * Converts a given time string to UTC hours and minutes.
 *
 * @param time The time string in 'HH:mm' format.
 * @returns An object containing 'hh' (UTC hours) and 'mm' (UTC minutes).
 */
export const convertToUTC = (time: string) => {
  const [hh, mm] = time.split(':').map(num => parseInt(num, 10));
  const localDateTime = dayjs().set('hour', hh).set('minute', mm).set('second', 0);
  const utcDateTime = localDateTime.utc();
  return { hh: utcDateTime.hour(), mm: utcDateTime.minute() };
};

/**
 * Converts UTC hours and minutes to a local time string in 'HH:mm' format.
 *
 * @param utcHours The UTC hours.
 * @param utcMinutes The UTC minutes.
 * @returns A string representing the local time in 'HH:mm' format.
 */
export const convertFromUTC = (utcHours: number, utcMinutes: number) => {
  const utcDateTime = dayjs.utc().set('hour', utcHours).set('minute', utcMinutes).set('second', 0);
  const localDateTime = utcDateTime.local();
  const localHours = localDateTime.format('HH');
  const localMinutes = localDateTime.format('mm');
  return `${localHours}:${localMinutes}`;
};

/**
 * Converts a time string from 24-hour format ('HH:mm') to 12-hour format with AM/PM.
 *
 * @param time24 - A string representing the time in 24-hour format ('HH:mm').
 * @returns A string representing the time in 12-hour format with AM/PM ('hh:mm AM/PM').
 *
 * @example
 * // Returns "1:03 PM"
 * convertTo12HourFormat("13:03");
 *
 * @example
 * // Returns "12:15 AM"
 * convertTo12HourFormat("00:15");
 */
export const convertTo12HourFormat = (time24: string): string => {
  const [hh, mm] = time24.split(':').map(num => parseInt(num, 10));
  const minutes = mm;
  const period = hh >= 12 ? 'PM' : 'AM';
  const hours = hh % 12 || 12; // Convert `0` to `12` for midnight
  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

/**
 * Converts a time duration string into a short format.
 *
 * This function replaces occurrences of "hour", "minute", and "second"
 * (and their plural forms) with their shorthand equivalents: "h", "m", and "s".
 * It also trims any extra whitespace from the resulting string.
 *
 * @param duration - The time duration string
 *
 * @returns The converted duration string
 *
 * @example
 * ```typescript
 * const result = convertDurationToShortFormat("1 hour 20 minutes 15 seconds");
 * console.log(result); // Outputs: "1h 20m 15s"
 * ```
 */
export const convertDurationToShortFormat = (duration: string) =>
  duration
    .replace(/\s?hours?/g, 'h')
    .replace(/\s?minutes?/g, 'm')
    .replace(/\s?seconds?/g, 's')
    .trim();
