import { Text } from '../../../typography';

interface HeaderComponentProps {
  displayName: string;
}

// AGGrid has not defined types for the props.
export const HeaderComponent = ({ displayName }: HeaderComponentProps) => (
  <Text variant="subTitle03" color="gray.7">
    {displayName}
  </Text>
);
