import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import {
  TopographyData,
  transformTopographyData,
} from '../../components/dataset-details/topography/topography.util';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useDataUpload } from '../../contexts/data-upload/DataUpload';
import {
  AnalysisStateNames,
  AnalysisStatus,
  ArtifactFilterState,
  BaseAPIFilter,
  DataCategory,
  DatasetEditableFields,
  DatasetRegistrationWorkflowModel,
  DatasetTopologyResponse,
  DatasetVariableStatsVisualization,
  DatasetWithTags,
  ListDatasetFeaturesResponse,
  ListDatasetsWithTagsResponse,
  Operator,
  RegistrationFlowStatus,
  SegmentType,
  TagMetadata,
  TasksStateSummary,
  UpdateDatasetArtifactState,
  WorkflowMode,
} from '../../generated/api';
import { logger } from '../../initializers/logging';
import { getTRPC } from '../../initializers/trpc';
import { datasetApi, datasetV2Api, taggedResourcesApi, userManagementApi } from '../../lib/api';
import { HTTPError } from '../../lib/api/api';
import { datasetAnalyticsApi } from '../../lib/api/oslo';
import { formatNumber } from '../../lib/ui';
import { useChartQuery } from '../charts-query-wrapper';
import { API_PAGE_SIZE, UNLABELED_DATA_TARGET } from '../constants';
import { datasetKeys } from '../queryConstants';
import { getNextPageParamHandler } from '../util';

export interface AnalysisStatusData {
  analysis: AnalysisStatus;
  isUploading?: boolean;
}

export type Dataset = {
  datasetId: string;
  name: string;
  description: string;
  createDate: string;
  dataFamilyId: string;
  dataFamilyName: string;
  dataFamily?: {
    dataFamilyId: string;
    dataFamilyName: string;
  };
  userId: string;
  userName?: string;
  numRecords?: string | number;
  analysis?: AnalysisStatusData;
  dataType?: DataCategory;
  tags?: TagMetadata[];
  segments?: SegmentType[];
  demoArtifact?: boolean;
  parentDatasetId?: string;
  childrenDatasetIds?: string[];
  dataQualityScore?: number;
  noOfColumns?: number;
  target?: string;
};

export const selectDatasetList = (data: DatasetWithTags[]): Dataset[] =>
  data.map(
    ({
      datasetId,
      name,
      description,
      createDate,
      dataType,
      dataFamily,
      analysis,
      userId,
      numRecords,
      tags,
      segments,
      demoArtifact,
      parentDatasetId,
      childrenDatasetIds,
      dataQualityScore,
      xColNames,
      yColName,
    }) => ({
      datasetId,
      name,
      description,
      createDate,
      dataFamilyId: dataFamily.dataFamilyId,
      dataFamilyName: dataFamily.name,
      userId: userId ?? '',
      numRecords,
      analysis: { analysis: analysis as AnalysisStatus },
      dataType,
      tags,
      segments: segments?.map(({ segmentType }) => segmentType),
      demoArtifact,
      parentDatasetId,
      childrenDatasetIds,
      dataQualityScore: formatNumber(100 * dataQualityScore, 2),
      noOfColumns: xColNames?.length ?? 0,
      target: yColName === UNLABELED_DATA_TARGET ? 'Unlabeled' : 'Labelled',
    }),
  );

interface QueryParams {
  pageSize?: number;
}
interface QueryFilters {
  dataFamilyId?: undefined;
  dataFamilyName?: undefined;
  dataType?: undefined;
  name?: undefined;
  userIds?: string[];
  excludeUserIds?: string[];
  recordIds?: string[];
  tagIds?: string[];
  state?: ArtifactFilterState;
}

export const useGetDatasetsInfiniteQuery = (filters: QueryFilters, params?: QueryParams) => {
  const pageSize = params?.pageSize ?? API_PAGE_SIZE;
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery<AxiosResponse<ListDatasetsWithTagsResponse>, AxiosError, Dataset[]>(
    datasetKeys.list(workspaceId, filters),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * pageSize,
        end = start + pageSize;
      return taggedResourcesApi.getDatasetsListWithTagsV1(
        workspaceId,
        start,
        end,
        lastTimestamp,
        filters.dataFamilyId,
        filters.dataFamilyName,
        filters.dataType,
        filters.name,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
        false,
        filters.state,
      );
    },
    {
      enabled: !!workspaceId,
      refetchInterval: 30 * 1000,
      getNextPageParam: getNextPageParamHandler(pageSize),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => selectDatasetList(page.data.response as DatasetWithTags[])),
      }),
    },
  );
};

export const useGetDatasetsListPreviewQuery = (userId?: string) => {
  const { workspaceId } = useAppMetadata();

  const PAGINATION_START_INDEX = 0;
  const PAGINATION_END_INDEX = 5;

  const ts = undefined;
  const filters = {
    dataFamilyId: undefined,
    dataFamilyName: undefined,
    dataType: undefined,
    name: undefined,
    userIds: userId ? [userId] : undefined,
    excludeUserIds: undefined,
    recordIds: undefined,
    tagIds: undefined,
  };

  return useQuery<AxiosResponse<ListDatasetsWithTagsResponse>, AxiosError, Dataset[]>(
    datasetKeys.listPreview(workspaceId, userId),
    () =>
      taggedResourcesApi.getDatasetsListWithTagsV1(
        workspaceId,
        PAGINATION_START_INDEX,
        PAGINATION_END_INDEX,
        ts,
        filters.dataFamilyId,
        filters.dataFamilyName,
        filters.dataType,
        filters.name,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
      ),
    {
      enabled: !!workspaceId,
      refetchInterval: 30 * 1000,
      select: response => selectDatasetList(response.data.response),
    },
  );
};

export const getWorkflowsListKey = (workspaceId: string) => ['workflows-list', workspaceId];

export const useGetUploadingWorkflowsQuery = () => {
  const { workspaceId } = useAppMetadata();
  const { uploads } = useDataUpload();

  return useQuery(
    getWorkflowsListKey(workspaceId),
    () =>
      datasetApi.workspaceGetWorkflowListV1(
        workspaceId,
        undefined,
        undefined,
        undefined,
        WorkflowMode.LocalFileUpload,
        RegistrationFlowStatus.DatasetUploading,
      ),
    {
      enabled: !!workspaceId,
      select: data => {
        const workflows = data.data.response;
        return uploads
          .map(upload => workflows.find(workflow => workflow.workflowId === upload.id))
          .filter(w => !!w);
      },
    },
  );
};

export const useInvalidateWorkflowsCache = () => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();
  return () => queryClient.invalidateQueries(getWorkflowsListKey(workspaceId));
};

const transformWorkflowsToDatasets = (workflows: DatasetRegistrationWorkflowModel[]): Dataset[] =>
  workflows.map(workflow => ({
    datasetId: workflow.workflowId,
    name: workflow.name ?? '',
    description: workflow.description ?? '',
    createDate: '',
    dataFamilyId: workflow.dataFamilyId ?? '',
    dataFamilyName: '',
    userId: '',
    analysis: {
      analysis: {
        status: AnalysisStateNames.InProgress,
        tasksStateSummary: {} as TasksStateSummary,
        totalCount: 0,
      },
      isUploading: true,
    },
  }));

// TODO: Remove this query and use useGetDatasetsInfiniteQuery hook directly
export const useGetDatasetsAndUploadingWorkflows = (filters: QueryFilters) => {
  const getDatasetsInfiniteQuery = useGetDatasetsInfiniteQuery(filters);
  const {
    data: workflows = [],
    isLoading: workflowsLoading,
    isError: workflowsError,
  } = useGetUploadingWorkflowsQuery();

  const {
    data: datasetsInfData,
    isError: datasetsError,
    isLoading: datasetLoading,
  } = getDatasetsInfiniteQuery;

  const datasetsPages = datasetsInfData?.pages.map((pageData, i) =>
    i === 0
      ? [
          ...transformWorkflowsToDatasets(workflows as DatasetRegistrationWorkflowModel[]),
          ...pageData,
        ]
      : pageData,
  ) ?? [[]];

  return {
    ...getDatasetsInfiniteQuery,
    isLoading: workflowsLoading || datasetLoading,
    isError: workflowsError || datasetsError,
    data: { ...datasetsInfData, pages: datasetsPages },
  };
};

export interface DatasetDetails extends DatasetWithTags {
  userInfo: {
    email: string;
    name: string;
    id: string;
  };
}

export const useDatasetDetailsQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<DatasetDetails | undefined, AxiosError>(
    datasetKeys.detail(workspaceId, datasetId),
    // TODO: We should move this logic to Mumbai. With current logic, there are unnecessary
    // requests for user details and the cached response may be undefined.
    async () => {
      const {
        data: { response: datasetDetailsResponse },
      } = await taggedResourcesApi.getDatasetInfoWithTagsV1(workspaceId, datasetId);

      if (!datasetDetailsResponse) {
        return;
      }

      const requestedUserId = datasetDetailsResponse?.userId || '';
      const { data: memberInfoResponse } = await userManagementApi.getUserV1(requestedUserId);

      const memberInfo = { ...memberInfoResponse };
      const datasetDetails: DatasetDetails = {
        ...datasetDetailsResponse,
        userInfo: {
          email: memberInfo.email ?? '',
          id: memberInfo.userId ?? '',
          name: memberInfo.name ?? '',
        },
      };
      return datasetDetails;
    },
    {
      enabled: Boolean(workspaceId && datasetId),
      publicChartFn: res => res.data.datasetDetail.response,
    },
  );
};

export const useDatasetFeaturesInfiniteQuery = (
  datasetId: string,
  params?: Partial<{ pageSize: number }>,
) => {
  const { workspaceId } = useAppMetadata();
  const pageSize = params?.pageSize ?? API_PAGE_SIZE;

  return useInfiniteQuery<AxiosResponse<ListDatasetFeaturesResponse>, AxiosError, string[]>(
    datasetKeys.features(workspaceId, datasetId),
    ({ pageParam = {} }) => {
      const { pageNumber = 0 } = pageParam;
      const start = pageNumber * pageSize;
      return datasetV2Api.getDatasetFeaturesV1(workspaceId, datasetId, start, pageSize);
    },
    {
      enabled: Boolean(workspaceId && datasetId),
      getNextPageParam: (lastPage, allPages) => {
        const { numRecords } = lastPage.data;
        const totalRecords = allPages.length * pageSize;
        return numRecords > totalRecords
          ? {
              pageNumber: allPages.length,
            }
          : undefined;
      },
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );
};

export const useEditDatasetMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const datasetDetailsQueryKey = datasetKeys.detail(workspaceId, datasetId);
  const datasetListsQueryKey = datasetKeys.lists();

  return useMutation(
    (updates: DatasetEditableFields) =>
      datasetApi.workspaceUpdateDatasetEditableFieldsV1(workspaceId, datasetId, {
        updates,
      }),
    {
      onMutate: async updates => {
        // optimistically update dataset details query data
        await queryClient.cancelQueries(datasetDetailsQueryKey);
        const previousData = queryClient.getQueryData<DatasetDetails>(datasetDetailsQueryKey);

        if (previousData) {
          const updatedDataset = { ...previousData, ...updates };
          queryClient.setQueryData<DatasetDetails>(datasetDetailsQueryKey, updatedDataset);
        }
        return { previousData };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(datasetListsQueryKey);
      },
      onError: (err: AxiosError, _data, context: { previousData?: DatasetDetails } | undefined) => {
        logger.error(err);
        if (context?.previousData) {
          queryClient.setQueryData(datasetDetailsQueryKey, context.previousData);
        }
      },
    },
  );
};

export const useRegisteredDatasetPreviewQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    datasetKeys.preview(workspaceId, datasetId),
    () => datasetApi.workspaceGetRegisteredDatasetPreviewV1(workspaceId, datasetId),
    {
      enabled: !!workspaceId,
      select: data => data.data.response,
    },
  );
};

export const useDatasetStateMutation = (
  datasetArtifactState: UpdateDatasetArtifactState,
  resetDatasetSelection: () => void,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation(
    () => datasetApi.workspaceDatasetStateChangeV1(workspaceId, datasetArtifactState),
    {
      onSuccess: () => {
        resetDatasetSelection();
        queryClient.invalidateQueries(datasetKeys.list(workspaceId));
      },
    },
  );
};

export const useGetDatasetFilters = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(datasetKeys.listFilters('listFilters'), () =>
    getTRPC().dataset.getListFilters.query(workspaceId),
  );
};

export const useGetDatasetsV2Query = (
  start = 0,
  filters: BaseAPIFilter[] = [],
  sortKey = 'createDate',
  sortOrder = 'desc',
  state: ArtifactFilterState,
) => {
  const { workspaceId } = useAppMetadata();
  const { uploads } = useDataUpload();

  const currentUploadIds = uploads.map(upload => upload.id);

  filters.push({
    field: 'artifactState',
    operator: Operator.In,
    value: state,
  });

  return useQuery<{ list: any[]; numRecords?: number }, AxiosError>(
    datasetKeys.listV2(workspaceId, start, filters as any[], sortKey, sortOrder, currentUploadIds),
    () =>
      // this will get fixed with better integration of Milan/Mumbai
      getTRPC().dataset.getDatasetList.query({
        workspaceId,
        filters,
        start,
        limit: 20,
        sortKey,
        sortOrder,
        currentUploads: currentUploadIds,
      }),
    {
      enabled: !!workspaceId,
      refetchInterval: 30 * 1000,
    },
  );
};

export const useDatasetTopographyQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<DatasetTopologyResponse>, AxiosError<HTTPError>, TopographyData>(
    datasetKeys.topography(workspaceId, datasetId),
    () => datasetApi.workspaceGetDatasetTopologyV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      // Using a callback as explained here: https://tkdodo.eu/blog/react-query-data-transformations#3-using-the-select-option
      select: useCallback(
        (data: AxiosResponse<DatasetTopologyResponse>) =>
          transformTopographyData(datasetId, data.data.response ?? []),
        [datasetId],
      ),
    },
  );
};

export const useDatasetChartExplainWithAIQuery = (
  datasetId: string,
  chartSvg: string,
  chartItem: DatasetVariableStatsVisualization,
  variableName: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    datasetKeys.explainDatasetChartWithAI(workspaceId, datasetId, chartItem, variableName),
    () =>
      datasetAnalyticsApi.generateDatasetChartAIExplanation(workspaceId, datasetId, {
        svgImage: chartSvg,
      }),
    {
      enabled: Boolean(workspaceId && datasetId && chartItem),
      select: data => data.data,
    },
  );
};
