import { getNodeType } from '@tiptap/react';
import { Fragment, NodeType, Slice } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';
import { canJoin } from 'prosemirror-transform';

/**
 * The modifiedSinkListItem function is an enhanced version of the original TipTap's sinkListItem.
 * It adds the ability to change list styles dynamically when sinking list items.
 *
 * Specifically, it performs two actions depending on the previous sibling node:
 * 1. If the previous sibling is a bullet list or ordered list,
 *    - It moves the current list item into the previous list, effectively nesting it.
 * 2. If the previous sibling is not a list,
 *    - It wraps the current list item into a new list and changes the list style dynamically.
 *
 * Compared to the original sinkListItem, this function provides greater flexibility in manipulating list items,
 * especially when dealing with nested lists and dynamic list style changes.
 * This modification is done to match the behavior of DOCX.
 *
 * Previous/og Behavior:
 * ```
 * ul
 *   - li
 *   - li
 *       - ul
 *           - li
 * ```
 * If you press Tab on the second list item (- li), it creates a new nested list under the item.
 *
 * Modified Behavior:
 * ```
 * ul
 *   - li
 *   - ul
 *       - li
 * ```
 * With the modification, pressing Tab on a list item moves it into a new nested list, which aligns with how .docx handles list indentation.
 * Pressing Shift+Tab reverses this behavior, moving list items back to their previous level.
 */
const modifiedSinkListItem =
  (itemType: NodeType) =>
  (state: EditorState, dispatch?: (tr: Transaction) => void): boolean => {
    const { $from, $to } = state.selection;
    const range = $from.blockRange(
      $to,
      node => node.childCount > 0 && node.firstChild?.type === itemType,
    );
    if (!range || !dispatch) return false;

    const parent = range.parent;
    const startIndex = range.startIndex;

    if (startIndex === 0) return false; // No previous sibling

    const prevSiblingIndex = startIndex - 1;
    const nodeBefore = parent.child(prevSiblingIndex);

    const { bulletList, orderedList } = state.schema.nodes;

    if (nodeBefore.type === bulletList || nodeBefore.type === orderedList) {
      // Previous sibling is a ul/ol, move current list item into it
      const nestedList = nodeBefore.copy(
        nodeBefore.content.append(Fragment.from(parent.child(startIndex))),
      );

      const tr = state.tr.replaceRangeWith(
        range.start - nodeBefore.nodeSize,
        range.end,
        nestedList,
      );
      dispatch(tr.scrollIntoView());
    } else {
      // Previous sibling is not a list, wrap current list item in a new list
      const {
        type: listType,
        attrs: { style: parentStyle = '' },
      } = parent;

      // Helper function to extract the list-style-type value
      const getListStyleType = (style: string): string => {
        const match = style.match(/list-style-type\s*:\s*([^;]+)/);
        return match ? match[1].trim() : 'decimal'; // Default to 'decimal'
      };

      const listStyleType = getListStyleType(parentStyle ?? '');

      // Define the mapping for the next list style type
      const nextStyleTypeMap: Record<string, string> = {
        decimal: 'lower-alpha',
        'lower-alpha': 'lower-roman',
        'lower-roman': 'decimal',
      };

      const nextListType = nextStyleTypeMap[listStyleType] ?? 'decimal';
      const currentListStyle = listType === orderedList ? `list-style-type: ${nextListType}` : '';

      const newList = listType.create(
        { style: currentListStyle },
        Fragment.from(parent.child(startIndex)),
      );

      const tr = state.tr.replaceRangeWith(range.start, range.end, newList);
      dispatch(tr.scrollIntoView());
    }

    return true;
  };

/**
 * The modifiedLiftListItem function is an enhanced version of the original TipTap's liftListItem.
 * It provides additional capabilities for handling different scenarios when lifting a list item:
 *
 * 1. If the parent list is nested inside another list,
 *    - The function lifts the current list item into the grandparent list and joins it with the next list if possible.
 * 2. If the parent list is not nested:
 *    - If the parent list contains more than one item,
 *        - It moves the current item out of the list while maintaining proper nesting structure.
 *    - If the parent list has only one item,
 *        - It removes the wrapping list and lifts the list item directly to the parent level.
 *
 * Compared to the original liftListItem, this modified version provides more advanced handling of nested lists,
 * allowing more flexible unwrapping and restructuring of list items.
 * This modification is done to match the behavior of DOCX.
 */
const modifiedLiftListItem =
  (itemType: NodeType) =>
  (state: EditorState, dispatch?: (tr: Transaction) => void): boolean => {
    const { $from, $to } = state.selection;
    const range = $from.blockRange(
      $to,
      node => node.childCount > 0 && node.firstChild?.type === itemType,
    );

    if (!range || !dispatch) return false;

    const tr = state.tr;

    const listItem = range.parent.child(range.startIndex);
    const parentList = range.parent;
    const grandParent = $from.node(range.depth - 1);

    const { bulletList, orderedList } = state.schema.nodes;

    // Case 1: Parent list is nested inside another list
    if (grandParent && (grandParent.type === bulletList || grandParent.type === orderedList)) {
      // Lift the list item into the grandparent list
      const target = range.depth - 1;
      tr.lift(range, target);

      // Join with the next list if possible
      const after = tr.mapping.map(range.end, -1);
      if (canJoin(tr.doc, after)) {
        tr.join(after);
      }

      dispatch(tr.scrollIntoView());
      return true;
    }

    // Case 2: Parent list is not nested (remove the wrapping list)
    else {
      // If the parent list has more than one item, move the current item out
      if (parentList.childCount > 1) {
        const target = range.depth;
        tr.lift(range, target);

        // Join with the next list if possible
        const after = tr.mapping.map(range.end, -1);
        if (canJoin(tr.doc, after)) {
          tr.join(after);
        }

        dispatch(tr.scrollIntoView());
        return true;
      } else {
        // Remove the wrapping list
        const listStart = $from.before(range.depth - 1);
        const listEnd = $from.after(range.depth - 1);

        tr.replace(
          listStart,
          listEnd,
          new Slice(
            Fragment.from(listItem),
            1, // openStart
            1, // openEnd
          ),
        );

        dispatch(tr.scrollIntoView());
        return true;
      }
    }
  };

export const sinkListItem =
  (typeOrName: string | NodeType) =>
  ({ state, dispatch }: { state: EditorState; dispatch?: (tr: Transaction) => void }): boolean => {
    const type = getNodeType(typeOrName, state.schema);

    return modifiedSinkListItem(type)(state, dispatch);
  };

export const liftListItem =
  (typeOrName: string | NodeType) =>
  ({ state, dispatch }: { state: EditorState; dispatch?: (tr: Transaction) => void }): boolean => {
    const type = getNodeType(typeOrName, state.schema);

    return modifiedLiftListItem(type)(state, dispatch);
  };
