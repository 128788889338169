import { MantineNumberSize, MantineSize } from '@mantine/styles';
import first from 'lodash/first';
import { isValidElement } from 'react';
import { Avatar, Horizontal, Text, Vertical } from '../../design-system/v2';

interface UserDetailProps {
  avatar?: string | React.ReactNode;
  name: string;
  email: string;
  avatarSize?: MantineNumberSize;
  hideAvatar?: boolean;
  spacing?: number | MantineSize;
}

export const UserDetail = ({
  avatar = '',
  name,
  email,
  avatarSize = 'md',
  hideAvatar = false,
  spacing = 'md',
}: UserDetailProps): JSX.Element => (
  <Horizontal noWrap aria-label={`User detail for ${name}`} spacing={spacing}>
    {!hideAvatar && (
      <Avatar
        src={typeof avatar === 'string' ? avatar : undefined}
        size={avatarSize}
        alt={first(name) || first(email)}
      >
        {isValidElement(avatar) ? avatar : first(name) || first(email)}
      </Avatar>
    )}
    <Vertical align="flex-start" spacing={0}>
      <Text variant="subTitle03">{name}</Text>
      <Text variant="small01" color="gray.6">
        {email}
      </Text>
    </Vertical>
  </Horizontal>
);
