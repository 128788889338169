import { MantineTheme } from '@mantine/styles';
import { useState } from 'react';
import { getCitationStyleDetails } from '../../../../../components/app-store/document-ai/commons/citations/util';
import { ReferenceComponent } from '../../../../../components/app-store/document-ai/copy-edit/details/suggestions-panel/panels/ask-markov/CopyEditReferenceSection';
import { DIFF_TYPE } from '../../../../../components/common/constants';
import { Box, CSSObject, Popover, Text } from '../../../../../design-system/v2';
import { CitationStyleGuideType, CopyEditCitation } from '../../../../../generated/api';

export const ReferenceText = ({ value }: { value: string }) => (
  <span style={{ color: 'blue' }}>{value}</span>
);

const getBoxStyles = (highlightType: number, theme: MantineTheme): CSSObject => {
  const highlightBorderMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[3],
    [DIFF_TYPE.EQUAL]: theme.colors.gray[3],
    [DIFF_TYPE.DELETE]: theme.colors.red[3],
  };
  const highlightBackgroundMap: Record<number, string> = {
    [DIFF_TYPE.ADD]: theme.colors.green[0],
    [DIFF_TYPE.EQUAL]: '',
    [DIFF_TYPE.DELETE]: theme.colors.red[0],
  };
  return {
    position: 'relative',
    borderRadius: '4px',
    display: 'inline-block',
    borderStyle: 'dashed',
    borderColor: highlightBorderMap[highlightType],
    backgroundColor: highlightBackgroundMap[highlightType],
  };
};

interface MarkovReferenceItemProps {
  citation: CopyEditCitation;
  citationStyles: CitationStyleGuideType;
  highlightType: number;
}

export const MarkovReferenceItem = ({
  citation,
  citationStyles,
  highlightType,
}: MarkovReferenceItemProps): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { getInlineCitationText } = getCitationStyleDetails(citationStyles);

  const handleOnClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <Box component="span" sx={theme => getBoxStyles(highlightType, theme)}>
      <Popover
        width={200}
        position="bottom"
        withArrow
        shadow="md"
        withinPortal
        offset={4}
        styles={{
          dropdown: {
            maxWidth: '100%', // Ensure the dropdown doesn't exceed the parent width
            wordWrap: 'break-word', // Wrap words within the dropdown
            whiteSpace: 'normal', // Allow text to wrap to the next line
          },
        }}
      >
        <Popover.Target>
          <Text
            variant="bodyShort03"
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={handleOnClick}
          >
            {getInlineCitationText(citation.citation)}
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <ReferenceComponent referenceObj={citation} referenceStyle={citationStyles} />
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};
