import { MantineThemeColorsOverride } from '@mantine/core';
import {
  blues,
  darks,
  grays,
  greens,
  magenta,
  orange,
  pinks,
  purples,
  reds,
  seaGreens,
  skyBlue,
  whites,
  yellows,
} from './colorList';

export const colors: MantineThemeColorsOverride = {
  // Names as derived from Design system Figma link
  // https://www.figma.com/file/u94DkwlsqOBFfLjmbUF7tC/Style-Oct_2022?node-id=321%3A64&t=jI5EfJoiefaqzSir-0

  dark: [...Object.values(darks)],
  gray: [...Object.values(grays)],
  white: [...Object.values(whites)],
  blue: [...Object.values(blues)],
  pink: [...Object.values(pinks)],
  green: [...Object.values(greens)],
  red: [...Object.values(reds)],
  yellow: [...Object.values(yellows)],
  purple: [...Object.values(purples)],
  seaGreen: [...Object.values(seaGreens)],
  magenta: [...Object.values(magenta)],
  orange: [...Object.values(orange)],
  skyBlue: [...Object.values(skyBlue)],
};
