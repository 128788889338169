import { createContext, MutableRefObject, PropsWithChildren, useContext } from 'react';
import { HighchartsReact } from '../providers/highcharts';

interface ChartContext {
  chartRef: MutableRefObject<HighchartsReact.RefObject | null>;
}

const ChartsContext = createContext<ChartContext | null>({
  chartRef: {
    current: null,
  },
});

export const useChartsContext = () => useContext(ChartsContext);

interface ChartsProviderProps {
  hcRef: MutableRefObject<HighchartsReact.RefObject | null>;
}

export const ChartsProvider = ({ children, hcRef }: PropsWithChildren<ChartsProviderProps>) => (
  <ChartsContext.Provider
    value={{
      chartRef: hcRef,
    }}
  >
    {children}
  </ChartsContext.Provider>
);

ChartsProvider.displayName = 'ChartsProvider';
