import { ControlProps, RankedTester, isBooleanControl, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { ChangeEvent } from 'react';
import { Checkbox, Text } from '../../../../../design-system/v2';

const CheckboxControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  enabled,
  errors,
  config,
}: ControlProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(path, e.target.checked);
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <Checkbox
      aria-label={label}
      checked={data}
      onChange={onChange}
      label={
        <Text span variant="subTitle04">
          {label}
        </Text>
      }
      description={
        <Text span variant="small02" color="gray.7">
          {description}
        </Text>
      }
      required={required}
      placeholder={uischema.options?.placeholder}
      sx={{ cursor: 'pointer' }}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      error={config.isFormDirty ? errors : undefined}
    />
  );
};

export const checkboxControlTester: RankedTester = rankWith(1, isBooleanControl);

export const CheckboxControl = withJsonFormsControlProps(CheckboxControlComponent);
