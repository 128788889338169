import {
  AirbyteStorageResourceModel,
  AirbyteStorageType,
  AzureBlobStorageResourceModel,
  GoogleDriveResourceModel,
  GoogleSheetResourceModel,
  MKVLibraryStorageResourceModel,
  PostgresStorageResourceModel,
  S3StorageResourceModel,
  SnowflakeStorageResourceModel,
  StorageType,
} from '../../../generated/api';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../../connectors/util';

export type StorageMetadataModel =
  | S3StorageResourceModel
  | SnowflakeStorageResourceModel
  | PostgresStorageResourceModel
  | AzureBlobStorageResourceModel
  | GoogleSheetResourceModel
  | GoogleDriveResourceModel
  | AirbyteStorageResourceModel;

export interface StorageConfig {
  connectorName: string;
  connectorIcon: JSX.Element;
  getResourceName: (metadata: StorageMetadataModel) => string;
  getResourceLocation: (metadata: StorageMetadataModel) => string;
}

export const generateAirbyteStorageConfig = (): Partial<Record<StorageType, StorageConfig>> =>
  Object.values(AirbyteStorageType).reduce((airbyteStorageConfigMap, storageType) => {
    airbyteStorageConfigMap[storageType as unknown as StorageType] = {
      connectorName: getConnectorTypeDisplayName(storageType as unknown as StorageType),
      connectorIcon: getConnectorTypeIcon(storageType as unknown as StorageType),
      getResourceName: (metadata: StorageMetadataModel) => {
        const { streamName } = metadata as AirbyteStorageResourceModel;
        return streamName ?? '';
      },
      getResourceLocation: () => storageType,
    };
    return airbyteStorageConfigMap;
  }, {} as Partial<Record<StorageType, StorageConfig>>);

export const storageConfigMap: Partial<Record<StorageType, StorageConfig>> = {
  [StorageType.S3]: {
    connectorName: getConnectorTypeDisplayName(StorageType.S3),
    connectorIcon: getConnectorTypeIcon(StorageType.S3),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { filePath } = metadata as S3StorageResourceModel;
      const isFolder = filePath.endsWith('/');
      const displayName = filePath.replace(/\/$/, '').split('/').pop() ?? '';
      return isFolder ? `${displayName}/` : displayName;
    },
    getResourceLocation: (metadata: StorageMetadataModel) => {
      const { filePath } = metadata as S3StorageResourceModel;
      const location = filePath.split('/').slice(0, -1).join('/');
      return `${location}/`;
    },
  },
  [StorageType.Snowflake]: {
    connectorName: getConnectorTypeDisplayName(StorageType.Snowflake),
    connectorIcon: getConnectorTypeIcon(StorageType.Snowflake),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { tableName = '' } = metadata as SnowflakeStorageResourceModel;
      const { dbName } = metadata as SnowflakeStorageResourceModel;
      if (!tableName) {
        return `DB ${dbName}`;
      } else {
        return `Table ${tableName}`;
      }
    },
    getResourceLocation: (metadata: StorageMetadataModel) => {
      const { dbName } = metadata as SnowflakeStorageResourceModel;
      return `DB ${dbName}`;
    },
  },
  [StorageType.Postgresql]: {
    connectorName: getConnectorTypeDisplayName(StorageType.Postgresql),
    connectorIcon: getConnectorTypeIcon(StorageType.Postgresql),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { tableName = '' } = metadata as PostgresStorageResourceModel;
      const { dbName } = metadata as PostgresStorageResourceModel;
      if (!tableName) {
        return `DB ${dbName}`;
      } else {
        return `Table ${tableName}`;
      }
    },
    getResourceLocation: (metadata: StorageMetadataModel) => {
      const { dbName } = metadata as PostgresStorageResourceModel;
      return `DB ${dbName}`;
    },
  },
  [StorageType.AzureBlob]: {
    connectorName: getConnectorTypeDisplayName(StorageType.AzureBlob),
    connectorIcon: getConnectorTypeIcon(StorageType.AzureBlob),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { blobName = '' } = metadata as AzureBlobStorageResourceModel;
      return `Blob ${blobName}`;
    },
    getResourceLocation: (metadata: StorageMetadataModel) => {
      const { containerName } = metadata as AzureBlobStorageResourceModel;
      return `Container ${containerName}`;
    },
  },
  [StorageType.MarkovLibrary]: {
    connectorName: getConnectorTypeDisplayName(StorageType.MarkovLibrary),
    connectorIcon: getConnectorTypeIcon(StorageType.MarkovLibrary),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { filePath, originalName } = metadata as MKVLibraryStorageResourceModel;
      const filename = originalName ?? filePath.split('/').pop();
      return filename ?? '';
    },
    getResourceLocation: (metadata: StorageMetadataModel) => 'Markov Library',
  },
  [StorageType.GoogleSheet]: {
    connectorName: getConnectorTypeDisplayName(StorageType.GoogleSheet),
    connectorIcon: getConnectorTypeIcon(StorageType.GoogleSheet),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { worksheetTitle } = metadata as GoogleSheetResourceModel;
      return worksheetTitle;
    },
    getResourceLocation: (metadata: StorageMetadataModel) => 'Google Sheet',
  },
  [StorageType.GoogleDrive]: {
    connectorName: getConnectorTypeDisplayName(StorageType.GoogleDrive),
    connectorIcon: getConnectorTypeIcon(StorageType.GoogleDrive),
    getResourceName: (metadata: StorageMetadataModel) => {
      const { fileName } = metadata as GoogleDriveResourceModel;

      return fileName;
    },
    getResourceLocation: (metadata: StorageMetadataModel) => 'Google Drive',
  },
  ...generateAirbyteStorageConfig(),
};
