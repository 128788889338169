import { useQuery } from '@tanstack/react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { apiTokensRoutesApi } from '../../lib/api';
import { TOKEN } from '../queryConstants';

export const useUserWorkspaceTokenQuery = () => {
  const { workspaceId, userId } = useAppMetadata();

  return useQuery(
    [TOKEN.GET_TOKEN_FOR_WORKSPACE, workspaceId, userId],
    () =>
      apiTokensRoutesApi.getAPITokenForWorkspaceV1({
        userId: userId,
        workspaceId: workspaceId,
        teamId: '',
      }),
    {
      select: data => data.data,
    },
  );
};
