import { useEffect, useRef, useState } from 'react';

export const useAudio = (srcUrl: string) => {
  const audioRef = useRef<HTMLAudioElement>(new Audio(srcUrl));
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    audioRef.current.src = srcUrl;

    audioRef.current.onplay = () => {
      setIsPlaying(true);
    };

    audioRef.current.onpause = () => {
      setIsPlaying(false);
    };

    return () => {
      audioRef.current.pause();
      // Mark as ready for gc
      audioRef.current.removeAttribute('src');
      audioRef.current.load();
    };
  }, [srcUrl]);

  const handlePlay = () => {
    audioRef.current.play();
  };

  const handlePause = () => {
    audioRef.current.pause();
  };

  const handleStop = () => {
    // Stop the player (pause + reset)
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  return { isPlaying, play: handlePlay, pause: handlePause, stop: handleStop };
};
