import { ReactNode } from 'react';
import { MultiSelectGrid, Text } from '../../../design-system/v2';
import { AsyncDropDownResources, AsyncResourcePair } from '../../../generated/api';

interface FeatureColumnSelectionDropdownProps {
  value: string[];
  multiSelectOnChange: (value: string[]) => void;
  options: AsyncResourcePair[];
  resourceKey: AsyncDropDownResources;
  disabled?: boolean;
  isLoading?: boolean;
  readOnly?: boolean;
  label?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  error?: ReactNode;
}

export const FeatureColumnSelectionDropdown = ({
  value,
  multiSelectOnChange,
  options,
  disabled,
  isLoading,
  label,
  description,
  placeholder,
  resourceKey,
  readOnly,
  required,
  error,
}: FeatureColumnSelectionDropdownProps) => {
  const allOptions = options.map(item => ({
    value: item.value,
    label: item.label,
    group: '',
  }));

  const handleSelectionChange = (selectedValues: string[]) => {
    const filteredSelectedItems = selectedValues?.filter(val =>
      allOptions.some(option => option.value === val),
    );
    multiSelectOnChange(filteredSelectedItems);
  };

  return (
    <MultiSelectGrid
      aria-label={label ?? resourceKey}
      alignment="vertical"
      options={allOptions}
      onChange={handleSelectionChange}
      value={value}
      label={
        <Text span variant="subTitle02">
          {label}
        </Text>
      }
      description={
        <Text variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      placeholder={placeholder}
      disabled={disabled || isLoading}
      readOnly={readOnly}
      multiple
      searchable
      maxDropdownHeight={320}
      withinPortal
      nothingFound="Nothing Found"
      required={required}
      error={error}
    />
  );
};
