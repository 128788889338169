import { ICellRendererParams } from '@ag-grid-community/core';
import { IconEye } from '@tabler/icons-react';
import {
  Button,
  Horizontal,
  MODAL_LEVEL_1_Z_INDEX,
  Modal,
  Text,
  useDisclosure,
} from '../../../design-system/v2';
import { DataResourceType, ResourceModel } from '../../../generated/api';
import { DataSourcePreviewContainer } from '../modals/DataSourcePreview.container';
import { FolderPreview } from '../modals/FolderPreview';
import { ResourceUrlPreview } from '../modals/ResourceUrlPreview';

export const PreviewRenderer = ({
  value: resourceId,
  data: resource,
}: ICellRendererParams<ResourceModel, string>) => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();

  const isFolderResource = resource?.resourceType === DataResourceType.Folder;

  if (isFolderResource) {
    return (
      <>
        <Horizontal h="100%" align="center">
          <Button leftIcon={<IconEye />} onClick={openModal}>
            View files
          </Button>
        </Horizontal>
        <Modal
          centered
          opened={modalOpened}
          onClose={closeModal}
          size="90vw"
          title={<Text variant="subTitle01">Resource details</Text>}
          zIndex={MODAL_LEVEL_1_Z_INDEX}
        >
          <FolderPreview resourceId={resourceId} />
        </Modal>
      </>
    );
  }

  const isDataSink = resource?.isDataSink ?? true;

  if (isDataSink) {
    return null;
  }

  const isPDFResource = resource?.resourceType === DataResourceType.Pdf;

  return (
    <>
      <Modal
        centered
        opened={modalOpened}
        onClose={closeModal}
        size="90vw"
        title={<Text variant="subTitle01">Resource details</Text>}
        zIndex={MODAL_LEVEL_1_Z_INDEX}
      >
        {isPDFResource ? (
          <ResourceUrlPreview resourceId={resourceId} />
        ) : (
          <DataSourcePreviewContainer resourceId={resourceId} height="60vh" />
        )}
      </Modal>
      <Horizontal h="100%" align="center">
        <Button leftIcon={<IconEye />} onClick={openModal}>
          Preview
        </Button>
      </Horizontal>
    </>
  );
};
