import {
  and,
  ControlProps,
  hasType,
  optionIs,
  RankedTester,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useState } from 'react';
import { Flex, Slider, Text, useMarkovTheme, Vertical } from '../../../../../design-system/v2';

const SliderControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  schema,
  uischema,
  data,
  visible,
  enabled,
  config,
  errors,
}: ControlProps) => {
  const theme = useMarkovTheme();
  const [value, setValue] = useState(data);

  if (!visible) {
    return null;
  }

  const onChange = (val: number) => {
    setValue(val);
    handleChange(path, val);
  };

  const uiSchemaOptions = uischema.options;
  const isViewOnlyForm = config.viewOnly;

  return (
    <Vertical spacing="xs">
      <Flex>
        <Text variant="subTitle03">{label}</Text>
        {required && <Text color="red">&nbsp;*</Text>}
      </Flex>
      <Slider
        defaultValue={schema.default}
        value={value}
        onChange={onChange}
        label={null}
        min={uiSchemaOptions?.minValue}
        max={uiSchemaOptions?.maxValue}
        step={uiSchemaOptions?.step ?? 0.1}
        styles={{
          markLabel: {
            display: 'none',
          },
          thumb: {
            backgroundColor: theme.colors.blue[6],
            width: 'auto',
            height: 'auto',
            minWidth: '24px',
            minHeight: '24px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        disabled={!isViewOnlyForm && !enabled}
        thumbChildren={
          <Text variant="overline" color="white.0">
            {value ?? schema.default}
          </Text>
        }
      />
    </Vertical>
  );
};

export const sliderControlTester: RankedTester = rankWith(
  2,
  and(
    uiTypeIs('Control'),
    optionIs('variant', 'slider'),
    schemaMatches(schema => hasType(schema, 'number')),
  ),
);

export const SliderControl = withJsonFormsControlProps(SliderControlComponent);
