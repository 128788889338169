export enum MarkovResource {
  DATASETS = 'DATASETS',
  ANALYZERS = 'ANALYZERS',
  EVALUATIONS = 'EVALUATIONS',
  EXPERIMENTS = 'EXPERIMENTS',
  PROJECTS = 'PROJECTS',
  NOTEBOOKS = 'NOTEBOOKS',
  OTHERS = 'OTHERS',
}

export const markovResourceColorMapping = {
  [MarkovResource.DATASETS]: 'yellow.7',
  [MarkovResource.ANALYZERS]: 'seaGreen.7',
  [MarkovResource.EVALUATIONS]: 'purple.7',
  [MarkovResource.EXPERIMENTS]: 'green.7',
  [MarkovResource.NOTEBOOKS]: 'pink.7',
  [MarkovResource.PROJECTS]: 'magenta.7',
  [MarkovResource.OTHERS]: 'blue.7',
};

export const S3_ILLUSTRATIONS_MAP = {
  HOMEPAGE_BANNER: 'illustrations/homepage-banner',
  EMPTY_ERROR_STATES: 'illustrations/empty-error-states',
  FEATURE_VISUALS: 'illustrations/feature-visuals',
};
