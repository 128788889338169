import { Button, Horizontal, Text, Vertical } from '../../../../design-system/v2';

interface FolderExistsModalProps {
  folderName: string;
  onRename: () => void;
  onProceed: () => void;
}

export const FolderExistsPrompt = ({ folderName, onRename, onProceed }: FolderExistsModalProps) => {
  const text = `A folder with the name "${folderName}" already exists. Please change the name if you want to upload it as a new folder.`;
  return (
    <Vertical>
      <Text>{text}</Text>
      <Horizontal position="right" spacing="md">
        <Button variant="outline" onClick={onProceed}>
          Upload to existing folder
        </Button>
        <Button variant="primary" onClick={onRename}>
          Rename the folder
        </Button>
      </Horizontal>
    </Vertical>
  );
};
