import { useRef, useState } from 'react';
import { AnalyzingSpinner, Sparkles } from '../../../../design-system';
import { Button, Center, Loader } from '../../../../design-system/v2';
import { AgGridReact } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { AnalysisStateNames, ClassOverlapInfo } from '../../../../generated/api';
import { getIsTaskPending } from '../../../../lib/analysis';
import { useRerunAnalysisMutation } from '../../../../queries/datasets/analysis/tasks';
import { useOverlappingClassMetricsQuery } from '../../../../queries/datasets/statistics/data-quality';
import { EmptyState } from '../../../common/EmptyState';
import { ErrorState } from '../../../common/ErrorState';
import { OverlappingClassMetrics } from './OverlappingClassMetrics';
import { handleReRunDataQualityScore } from './RerunDataQualityScore';

interface OverlappingClassMetricsContainerProps {
  datasetId: string;
}

export const OverlappingClassMetricsContainer = ({
  datasetId,
}: OverlappingClassMetricsContainerProps): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(0);
  const gridRef = useRef<AgGridReact<ClassOverlapInfo>>(null);

  const { isLoading, isError, data } = useOverlappingClassMetricsQuery(datasetId, pageNumber);
  const { mutate: retriggerDataQualityAnalysis, isLoading: isRetriggering } =
    useRerunAnalysisMutation(datasetId);

  const handleRetrigger = () => {
    handleReRunDataQualityScore(retriggerDataQualityAnalysis);
  };

  const handlePageNumberChange = (currentPage: number) => {
    gridRef.current?.api.showLoadingOverlay();
    setPageNumber(currentPage - 1);
  };

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" size="lg" text="Loading overlapping class metrics" />
      </Center>
    );
  }

  if (isError || !data?.response) {
    const errorMsg = 'There was an error retrieving overlapping class metrics';
    return (
      <ErrorState
        compact
        title=""
        subtitle={errorMsg}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  const analysisState = data.analysisState;

  if (analysisState === AnalysisStateNames.Error) {
    return (
      <ErrorState
        compact
        title=""
        subtitle="There was an error while computing overlapping class metrics results"
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  if (analysisState === AnalysisStateNames.Outdated) {
    return (
      <EmptyState
        compact
        title=""
        subtitle="Re-run Markov Quality Score analysis to view overlapping class metrics"
        icon={<Sparkles />}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  // Handle case when analyzer results aren't available yet
  if (getIsTaskPending(analysisState)) {
    return (
      <EmptyState
        compact
        title="Overlapping class metrics analysis in progress"
        subtitle="Results will become available once the analysis is complete"
        icon={<AnalyzingSpinner />}
      />
    );
  }

  const overlappingClassMetrics = data.response;

  return (
    <OverlappingClassMetrics
      overlappingClassMetrics={overlappingClassMetrics}
      gridRef={gridRef}
      pageNumber={pageNumber}
      handlePageNumberChange={handlePageNumberChange}
      totalRows={data.count}
    />
  );
};
