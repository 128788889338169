import { IconArrowNarrowRight } from '@tabler/icons-react';
import {
  Alert,
  Badge,
  Center,
  Horizontal,
  Loader,
  Text,
  Vertical,
  closeAllModals,
  useMarkovTheme,
} from '../../../design-system/v2';
import { formatDateTime, formatNumber } from '../../../lib/ui';
import { useDatasetDetailsQuery } from '../../../queries/datasets/list';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { TextLink } from '../../common/Link';
import { IconChartGreen } from '../../dataset-details/header/icons';

enum DatasetRelation {
  ORIGINAL = 'ORIGINAL',
  MODIFIED = 'MODIFIED',
}

const datasetRelationDetailsMap = {
  [DatasetRelation.ORIGINAL]: {
    label: 'Original',
    badgeColor: 'blue',
  },
  [DatasetRelation.MODIFIED]: {
    label: 'Modified Version',
    badgeColor: 'orange',
  },
};

interface RelatedDatasetInfoProps {
  isActive: boolean;
  name: string;
  datasetId: string;
  relation: DatasetRelation;
  createDate: string;
  qualityScore: number;
}

const RelatedDatasetInfo = ({
  isActive,
  name,
  datasetId,
  relation,
  createDate,
  qualityScore,
}: RelatedDatasetInfoProps) => {
  const { getDatasetDetailsRoute } = useAbsoluteRoutes();
  const { label, badgeColor } = datasetRelationDetailsMap[relation];

  // Data quality score will be -1 if not available
  const showQualityScore = qualityScore !== -1;

  const onClickName = () => {
    closeAllModals();
  };

  const dsDetailsRoute = getDatasetDetailsRoute(datasetId);

  return (
    <Vertical spacing="sm" sx={{ flex: '0 0 250px' }}>
      <Badge size="sm" color={badgeColor} sx={{ alignSelf: 'start' }}>
        {label}
      </Badge>
      <Vertical
        spacing="xs"
        p="lg"
        bg={isActive ? 'gray.2' : 'white.0'}
        sx={theme => ({
          border: '1px solid',
          borderColor: theme.colors.gray[2],
          boxShadow: theme.shadows.sm,
        })}
      >
        {/* onClick handler needed on TextLink to close modal */}
        <TextLink
          to={dsDetailsRoute}
          onClick={onClickName}
          variant="subTitle04"
          color="gray.8"
          lineClamp={1}
          title={name}
        >
          {name}
        </TextLink>
        <Horizontal position="apart" h={24}>
          <Text variant="small03" color="gray.5">
            {formatDateTime(createDate)}
          </Text>
          <Horizontal spacing="sm">
            <IconChartGreen width={16} />
            <Text variant="bodyShort03" color="gray.7">
              {showQualityScore ? `${formatNumber(100 * qualityScore, 2)}%` : 'N/A'}
            </Text>
          </Horizontal>
        </Horizontal>
      </Vertical>
    </Vertical>
  );
};

interface DatasetRelationContainerProps {
  datasetId: string;
  parentDatasetId: string;
  childDatasetId: string;
}

export const DatasetRelationContainer = ({
  datasetId,
  parentDatasetId,
  childDatasetId,
}: DatasetRelationContainerProps): JSX.Element => {
  const theme = useMarkovTheme();
  // Note: Have asked design to consider the case where a dataset is both a parent and
  // a child. For now, if a dataset has a child dataset, it will be shown as the parent.
  const isParent = Boolean(childDatasetId);

  const originalId = isParent ? datasetId : parentDatasetId;
  const modifiedId = isParent ? childDatasetId : datasetId;

  const parentDatasetQuery = useDatasetDetailsQuery(originalId);
  const childDatasetQuery = useDatasetDetailsQuery(modifiedId);

  const isLoading = parentDatasetQuery.isLoading || childDatasetQuery.isLoading;
  const isError = parentDatasetQuery.isError || childDatasetQuery.isError;

  if (isLoading) {
    return (
      <Center h={108}>
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return <Alert color="red">Error encountered while fetching dataset details</Alert>;
  }

  const {
    name: parentName = '',
    createDate: parentCreateDate = '',
    dataQualityScore: parentQualityScore = -1,
  } = parentDatasetQuery.data ?? {};
  const {
    name: childName = '',
    createDate: childCreateDate = '',
    dataQualityScore: childQualityScore = -1,
  } = childDatasetQuery.data ?? {};

  return (
    <Horizontal spacing={0} position="apart">
      <RelatedDatasetInfo
        relation={DatasetRelation.ORIGINAL}
        datasetId={originalId}
        isActive={isParent}
        name={parentName}
        createDate={parentCreateDate}
        qualityScore={parentQualityScore}
      />
      <IconArrowNarrowRight color={theme.colors.gray[4]} size={50} />
      <RelatedDatasetInfo
        relation={DatasetRelation.MODIFIED}
        datasetId={modifiedId}
        isActive={!isParent}
        name={childName}
        createDate={childCreateDate}
        qualityScore={childQualityScore}
      />
    </Horizontal>
  );
};
