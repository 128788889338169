import { useQueries, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AreEvaluationsComparableResponse,
  EvaluationComparisonResultType,
  GetEvaluationWithModelAndDatasetResponse,
} from '../../generated/api';
import { evaluationsApi, mumbaiApi } from '../../lib/api';
import { EVALUATION } from '../queryConstants';

export const useCanCompareEvaluationsQuery = (evaluationIds: string[], enabled = false) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<AreEvaluationsComparableResponse>,
    AxiosError,
    EvaluationComparisonResultType
  >(
    [EVALUATION.GET_CAN_COMPARE_EVALS, workspaceId, { evaluationIds }],
    () => evaluationsApi.canCompareEvaluationsV1(workspaceId, evaluationIds),
    {
      select: res => res.data.response,
      enabled: Boolean(enabled && workspaceId && evaluationIds.length),
    },
  );
};

export const useEvaluationsFullDetailsQueries = (evaluationIds: string[]) => {
  const { workspaceId } = useAppMetadata();

  return useQueries({
    queries: evaluationIds.map(evaluationId => ({
      queryKey: [EVALUATION.GET_EVAL_FULL_DETAILS, workspaceId, evaluationId],
      queryFn: () =>
        mumbaiApi.getEvaluationMetadataWithModelAndDatasetInfoV1(workspaceId, evaluationId),
      select: (res: AxiosResponse<GetEvaluationWithModelAndDatasetResponse>) => res.data.response,
      enabled: Boolean(workspaceId && evaluationId),
    })),
  });
};
