import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';
import isPlainObject from 'lodash/isPlainObject';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import transform from 'lodash/transform';
import SparkMD5 from 'spark-md5';
import { AppIdType, DocumentStatusType, DocumentType } from '../../../../generated/api';

export const getColorFromStatus = (status: DocumentStatusType) => {
  switch (status) {
    case DocumentStatusType.InProgress:
      return 'yellow.6';
    case DocumentStatusType.Failed:
      return 'red.6';
    case DocumentStatusType.Finished:
    default:
      return 'blue.6';
  }
};

export const getButtonTextFromAppId = (appId: AppIdType) => {
  switch (appId) {
    case AppIdType.Summarization:
      return 'Summarize';
    case AppIdType.CopyEdit:
    default:
      return "Let's begin";
  }
};

export const permittedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
];

export const getFileTypeFromMimeType = (fileType: string) => {
  switch (fileType) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return DocumentType.Docx;
    case 'application/pdf':
      return DocumentType.Pdf;
    default:
      throw 'Unhandled document type encountered';
  }
};

export const getLLMDisplayName = (llmType: string) => llmType.replaceAll('_', ' ');

// We need to use this if there is exact match of types while pasting the content from paste-content extension. If a user copies and tries to paste a normal JSON object, it should return false.
export function tryParseJSONObject(
  jsonString?: string,
  validateFn?: (obj: any) => boolean,
): boolean {
  try {
    if (jsonString) {
      const o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        // If a validation function is provided, use it
        if (validateFn) {
          return validateFn(o);
        }
        return true; // No validation function, just return true
      }
    }
  } catch (e) {}

  return false;
}

export const toCamelCaseKeys = (obj: { [key: string]: any }): { [key: string]: any } =>
  transform(
    obj,
    (result: Record<string, any>, value: any, key: string) => {
      const camelCaseKey = camelCase(key);

      result[camelCaseKey] = isPlainObject(value)
        ? toCamelCaseKeys(value)
        : Array.isArray(value)
        ? value.map((item: any) => (isPlainObject(item) ? toCamelCaseKeys(item) : item))
        : value;

      return result;
    },
    {},
  );

function deepSortObject(obj: any): any {
  if (Array.isArray(obj)) {
    return sortBy(obj.map(deepSortObject));
  } else if (isPlainObject(obj)) {
    return fromPairs(sortBy(toPairs(obj), 0).map(([key, value]) => [key, deepSortObject(value)]));
  }
  return obj;
}

export const hashObject = (obj: any) => {
  const sortedObj = deepSortObject(obj);
  const jsonString = JSON.stringify(sortedObj);
  return SparkMD5.hash(jsonString);
};

// TODO: See if we can remove variants and use appId from URL
export const getAppVariantFromAppId = (appId: string): AppIdType | undefined => {
  switch (appId) {
    case 'copy-edit':
      return AppIdType.CopyEdit;
    case 'summarization':
      return AppIdType.Summarization;
    case 'doc-qna':
      return AppIdType.QnaWithDoc;
    default:
      return undefined;
  }
};
