import { ControlProps, RankedTester, isNumberControl, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { NumberInput, Text } from '../../../../../design-system/v2';

const NumberControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  config,
  errors,
}: ControlProps) => {
  if (!visible) {
    return null;
  }

  const onChange = (val: number) => {
    handleChange(path, val);
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <NumberInput
      ariaLabel={label ?? ' '}
      value={data}
      onChange={onChange}
      label={<Text variant="subTitle02">{label}</Text>}
      description={
        <Text variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      required={required}
      placeholder={uischema.options?.placeholder}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      error={config.isFormDirty ? errors : undefined}
    />
  );
};

export const numberControlTester: RankedTester = rankWith(1, isNumberControl);

export const NumberControl = withJsonFormsControlProps(NumberControlComponent);
