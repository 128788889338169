import { useMemo } from 'react';
import { useMarkovTheme } from '../../../design-system/v2';

const INPUT_HEIGHT = 92;

export const useMentionInputStyles = (focused: boolean) => {
  const theme = useMarkovTheme();

  const promptInputStyles = useMemo(
    () => ({
      control: {
        height: INPUT_HEIGHT,
        backgroundColor: '#fff',

        overflow: 'auto',
        lineHeight: 1.6,
        padding: theme.spacing.sm,
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
        borderRadius: theme.radius.sm,
        fontSize: '14px',
        fontFamily: theme.fontFamily,
        border: `1px solid`,
        borderColor: focused ? theme.colors.blue[6] : theme.colors.gray[4],
        outline: 'none',
        color: theme.colors.gray[9],
      },
      input: {
        height: '100%',
        overflow: 'auto',
        lineHeight: 1.6,
        padding: theme.spacing.sm,
        paddingLeft: theme.spacing.lg,
        paddingRight: theme.spacing.lg,
        borderRadius: theme.radius.sm,
        fontSize: '14px',
        fontFamily: theme.fontFamily,
        border: `1px solid`,
        borderColor: focused ? theme.colors.blue[6] : theme.colors.gray[4],
        outline: 'none',
        color: theme.colors.gray[9],
      },
      highlighter: {
        height: '100%',
      },
      suggestions: {
        maxHeight: '50vh',
        overflow: 'auto',
        boxShadow: `
          0px 1px 3px 0px rgba(0, 0, 0, 0.05),
          0px 10px 15px -5px rgba(0, 0, 0, 0.05),
          0px 7px 7px -5px rgba(0, 0, 0, 0.04)
        `,
        zIndex: 3,
      },
    }),
    [focused],
  );

  const mentionStyles = useMemo(
    () => ({
      backgroundColor: theme.colors.blue[3],
      opacity: 0.3,
      borderRadius: theme.radius.sm,
    }),
    [],
  );

  return {
    mention: mentionStyles,
    input: promptInputStyles,
  };
};
