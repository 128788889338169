import { ReactNode } from 'react';
import { Alert, Box, MODAL_LEVEL_1_Z_INDEX, Modal, Text } from '../../../design-system/v2';
import { AsyncDropDownResources, StorageType } from '../../../generated/api';
import { LeafResourceActions } from '../../connectors/connector-details/actions/LeafResourceActions';
import { ResourceActionsParams } from '../../connectors/connector-details/actions/ResourceActions';
import { isFileSupported } from '../../connectors/connector-resources/util';
import { ConnectorResourceSelection } from '../../connectors/modals/add-resource/ConnectorResourceSelection';
import { DataSourcesFolderModal } from '../../data-sources/modals/AddDataSourceFolderModal';

export const getDataSourceActions = ({
  connectorId,
  connectorType,
  resource,
  resourcesHierarchy,
  onResourceAdd,
}: ResourceActionsParams): JSX.Element | null => {
  const { key, value, isFinal, isAdded = false } = resource;

  const isUnsupportedFileType =
    isFinal && connectorType === StorageType.S3 && !isFileSupported(value);

  if (isUnsupportedFileType) {
    return <Alert>Filetype not supported</Alert>;
  }

  const resourcePath = [...resourcesHierarchy, { key, value }];
  if (isFinal) {
    return (
      <LeafResourceActions
        connectorId={connectorId}
        connectorType={connectorType}
        resourcePath={resourcePath}
        isAdded={isAdded}
        onResourceAdd={onResourceAdd}
      />
    );
  }

  return null;
};

interface AddResourceModalContentProps {
  resourceType: AsyncDropDownResources;
  onSelectResource: (resourceId: string) => void;
  connectorType?: StorageType;
  getResourceActions?: (params: ResourceActionsParams) => ReactNode;
  leafSelectable?: boolean;
  closeModal?: () => void;
}

const AddResourceModalContent = ({
  resourceType,
  onSelectResource,
  connectorType,
  getResourceActions = getDataSourceActions,
  leafSelectable,
  closeModal,
}: AddResourceModalContentProps) => {
  if (resourceType === AsyncDropDownResources.DataResources) {
    const isMarkovLibraryResource = connectorType === StorageType.MarkovLibrary;

    if (isMarkovLibraryResource) {
      return (
        <Box maw="75vw">
          <DataSourcesFolderModal onFileUploadComplete={closeModal} />
        </Box>
      );
    }

    return (
      <Box w="75vw" h="66vh">
        <ConnectorResourceSelection
          onSelectResource={onSelectResource}
          connectorType={connectorType}
          getResourceActions={getResourceActions}
          leafSelectable={leafSelectable}
        />
      </Box>
    );
  }

  return <>Supported resource not found</>;
};

interface AddResourceModalProps {
  opened: boolean;
  onSelectResource: (resourceId: string) => void;
  onClose: () => void;
  resourceType: AsyncDropDownResources;
  connectorType?: StorageType;
  getResourceActions?: (params: ResourceActionsParams) => ReactNode;
  leafSelectable?: boolean;
}

export const AddResourceModal = ({
  opened,
  onSelectResource,
  onClose,
  resourceType,
  connectorType,
  getResourceActions,
  leafSelectable,
}: AddResourceModalProps) => {
  const handleResourceSelection = (resourceId: string) => {
    onSelectResource(resourceId);
  };

  return (
    <Modal
      opened={opened}
      title={<Text variant="subTitle01">Add data source</Text>}
      size="auto"
      centered
      // keepMounted needed to support setting state from nested modals
      keepMounted
      // zIndex needed to show AddResourceModal above FormModal and below notification toasts
      zIndex={MODAL_LEVEL_1_Z_INDEX}
      onClose={onClose}
    >
      <AddResourceModalContent
        resourceType={resourceType}
        onSelectResource={handleResourceSelection}
        connectorType={connectorType}
        getResourceActions={getResourceActions}
        leafSelectable={leafSelectable}
        closeModal={onClose}
      />
    </Modal>
  );
};
